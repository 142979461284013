import React from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, TextField, Typography, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { GetTextByRegion } from '../../hooks/Translation';

const getTrackingUrl = (shippingCompany, trackingNumber) => {
  const trackingUrls = {
    'UPS': `https://www.ups.com/track?tracknum=${trackingNumber}`,
    'FedEx': `https://www.fedex.com/apps/fedextrack/?tracknumbers=${trackingNumber}`,
    'USPS': `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${trackingNumber}`,
    'DHL': `https://www.dhl.com/us-en/home/tracking.html?tracking-id=${trackingNumber}`
  };

  return trackingUrls[shippingCompany] || `https://www.google.com/search?q=${trackingNumber}`;
};

const ShippingFormFields = ({
  numTrackingNumbers,
  formData,
  handleShippingCompanyChange,
  handleTrackingNumberChange,
  handleTrackingNotesChange,
  isShippingNotes,
  isEditMode,
  selectedRegion,
  isChina
}) => {
  if (!isChina && !isShippingNotes) return null;

  return (
    <Grid item xs={12}>
      {isEditMode ? (
        <Grid container spacing={2}>
          {Array.from({ length: numTrackingNumbers }).map((_, index) => {
            const shippingCompany = formData.shippingCompany[index] || 'OTHER';
            const trackingNumber = formData.trackingNumber[index] || '';
            return (
              <React.Fragment key={`trackingNumber-${index}`}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required disabled={isShippingNotes}>
                    <InputLabel>{GetTextByRegion(`Shipping Company`, selectedRegion) + `${index + 1}`}</InputLabel>
                    <Select
                      label={GetTextByRegion(`Shipping Company`, selectedRegion) + ` ${index + 1}`}
                      name={`shippingCompany-${index}`}
                      value={shippingCompany}
                      onChange={(event) => handleShippingCompanyChange(index, event)}
                    >
                      <MenuItem value="UPS">UPS</MenuItem>
                      <MenuItem value="FedEx">FedEx</MenuItem>
                      <MenuItem value="USPS">USPS</MenuItem>
                      <MenuItem value="DHL">DHL</MenuItem>
                      <MenuItem value="OTHER">OTHER</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={GetTextByRegion("Tracking Number", selectedRegion) + `${index + 1}`}
                    name={`trackingNumber-${index}`}
                    value={formData.trackingNumber[index] || ''}
                    onChange={(event) => handleTrackingNumberChange(index, event)}
                    fullWidth
                    required
                    disabled={isShippingNotes}
                  />
                </Grid>
                <Grid item xs={12} mb={3}>
                  <TextField
                    label={GetTextByRegion(`Tracking Notes`, selectedRegion) + `${index + 1}`}
                    name={`trackingNotes-${index}`}
                    value={formData.trackingNotes[index] || ''}
                    onChange={(event) => handleTrackingNotesChange(index, event)}
                    fullWidth
                    required
                    disabled={isShippingNotes}
                  />
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{GetTextByRegion("Shipping Company", selectedRegion)}</TableCell>
                <TableCell>{GetTextByRegion("Tracking Number", selectedRegion)}</TableCell>
                <TableCell>{GetTextByRegion("Tracking Notes", selectedRegion)}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from({ length: numTrackingNumbers }).map((_, index) => {
                const shippingCompany = formData.shippingCompany[index] || 'OTHER';
                const trackingNumber = formData.trackingNumber[index] || '';
                const trackingNotes = formData.trackingNotes[index] || 'N/A';
                const trackingUrl = getTrackingUrl(shippingCompany, trackingNumber);

                return (
                  <TableRow key={`trackingNumber-row-${index}`}>
                    <TableCell>{shippingCompany}</TableCell>
                    <TableCell>
                      {trackingNumber ? (
                        <Link href={trackingUrl} target="_blank" rel="noopener noreferrer">
                          {trackingNumber}
                        </Link>
                      ) : (
                        'N/A'
                      )}
                    </TableCell>
                    <TableCell>{trackingNotes}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Grid>
  );
};

export default ShippingFormFields;
