import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import '../styles/Machine.css'

const CustomDataGrid = ({ rows, columns, width }) => {
  // Determine minWidth based on the presence of `width`
  const minWidth = width ? width : '1000px';

  // Function to determine the row class name based on urgency
  const getRowClassName = (params) => {
    const urgency = params.row.urgent;

    if(params.row.status === 'On Floor: Scienscope Demo'){
      return 'demo'
    }
    if(params.row.status === 'Broken'){
      return 'broken'
    }
    if (urgency > 0){
      return 'urgent'
    }
    
  };

  return (
    <div className="no-hover" style={{ height: '100%', width: '100%', overflowX: 'auto' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row._id} // Use the 'id' field set from rowsWithIds
        rowHeight={65}
        style={{ minWidth: minWidth}}
        getRowClassName={getRowClassName} // Apply custom row class names
      />
    </div>
  );
};

export default CustomDataGrid;
