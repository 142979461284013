import React, { useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { Button, Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddPartView from './AddPartView';
import EditPartView from './EditPartView';
import SearchBar from '../../SearchBar';
import { useUser } from '../../../hooks/UserContext';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const PartItemView = ({ parts, setParts, totalPartCost }) => {
    const { selectedRegion } = useUser();
    const [editPartView, setEditPartView] = useState(false);
    const [addPartView, setAddPartView] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [requestedPart, setRequestedPart] = useState({
        partnumber: '',
        quantity: '',
        price: '',
        description: '',
        total: 0
    });
    const tableContainerRef = useRef(null);

    const fetchItems = useCallback(async () => {
        if (searchQuery === "") {
            openAddPartView();
            return;
        }
        try {
            const response = await axios.get(`${backendString}/items/${searchQuery}?selectedRegion=${selectedRegion}`);
            setRequestedPart(
                {
                    partnumber: response.data.partnumber,
                    quantity: '',
                    price: response.data.price,
                    description: response.data.name,
                    total: 0,
                }
            );

        } catch (error) {
            // setError(error.response?.data?.error || 'Error fetching items'); // Set error state        
        }
        openAddPartView();
    }, [searchQuery, selectedRegion]);
    const openAddPartView = () => {
        setAddPartView(true);
    };
    const closeAddPartView = () => {
        setAddPartView(false);
    }
    const closeEditPartView = () => {
        setEditPartView(false);
    }
    const handleEditPart = (partData) => {
        setRequestedPart(partData);
        setEditPartView(true);
    };
    const handleDeletePart = (partData) => {
        setParts((prevParts) => prevParts.filter(part => part !== partData));
    };
    const PartCostSummary = () => {
        return (
            <Box style={{ marginTop: 16, textAlign: 'right' }}>
                <strong>Sub Total: ${totalPartCost.toFixed(2)}</strong>
            </Box>
        );
    }
    const onPartEditSubmit = (updatedPart) => {

        setParts((prevParts) =>
            prevParts.map(part =>
                part.partnumber === updatedPart.partnumber
                    ? { ...part, ...updatedPart } // Corrected line
                    : part
            )
        );
    }

    return (
        <>
            <Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                    Service Part Costs
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                    <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} placeholder="Search Part..." />
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ marginLeft: 2, flexGrow: 0 }} // Aligns button to the right and keeps original size
                        onClick={fetchItems}
                    >
                        Add Parts
                    </Button>
                </Box>
                <TableContainer
                    component={Paper}
                    ref={tableContainerRef}
                    sx={{
                        mt: 2,
                        maxHeight: 300,
                        overflow: 'auto'
                    }}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Part Number</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Unit Price</TableCell>
                                <TableCell>Line Total</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {parts.map((part, index) => (
                                <TableRow key={index}>
                                    <TableCell>{part.partnumber}</TableCell>
                                    <TableCell>{part.quantity}</TableCell>
                                    <TableCell>{part.description}</TableCell>
                                    <TableCell>${parseFloat(part.price).toFixed(2)}</TableCell>
                                    <TableCell>${parseFloat(part.total).toFixed(2)}</TableCell>
                                    <TableCell>
                                        <IconButton color='primary' onClick={() => { handleEditPart(part) }} >
                                            <EditIcon />
                                        </IconButton>
                                        <Button>
                                            <DeleteIcon sx={{ color: 'purple' }} onClick={() => handleDeletePart(part)} />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PartCostSummary />


            </Box>
            {addPartView &&
                <AddPartView open={addPartView} onClose={closeAddPartView} requestedPart={requestedPart} setParts={setParts} setRequestedPart={setRequestedPart} partlist={parts} />
            }

            {editPartView && <EditPartView open={editPartView} onClose={closeEditPartView} requestedPart={requestedPart} setRequestedPart={setRequestedPart} onEditSubmit={onPartEditSubmit} partlist={parts} />
            }
        </>
    );


}


export default PartItemView;