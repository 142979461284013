import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import * as XLSX from 'xlsx';

// Full-screen styles for the container and table
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh', // Full viewport height
    width: '100vw',  // Full viewport width
    padding: '20px',
    boxSizing: 'border-box',
    overflow: 'auto', // Enable scrolling if needed
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left',
    fontWeight: 'bold', // Make column headers bold
  },
  td: {
    border: '1px solid #ddd',
    padding: '8px',
  },
  thead: {
    backgroundColor: '#f2f2f2',
  }
};

const FilePreview = ({ fileUrl }) => {
  const [sheetData, setSheetData] = useState(null);

  useEffect(() => {
    if (fileUrl) {
      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const firstSheet = workbook.SheetNames[0];
            const sheet = workbook.Sheets[firstSheet];
            const sheetJson = XLSX.utils.sheet_to_json(sheet);
            setSheetData(sheetJson);
          };
          reader.readAsArrayBuffer(blob);
        });
    }
  }, [fileUrl]);

  return (
    <div style={styles.container}>
      {sheetData ? (
        <div>
          <Typography variant="h6">Preview:</Typography>
          <table style={styles.table}>
            <thead style={styles.thead}>
              <tr>
                {Object.keys(sheetData[0] || {}).map((key) => (
                  <th key={key} style={styles.th}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sheetData.map((row, index) => (
                <tr key={index}>
                  {Object.values(row).map((value, idx) => (
                    <td key={idx} style={styles.td}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <Typography>Loading...</Typography>
      )}
    </div>
  );
};

export default FilePreview;
