import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { IconButton, Button, Box, Tab, Tabs, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import FlowchartImage from '../images/flowchartDiagram.png';
import FlowchartImageEUTAIWAN from '../images/flowchartDiagramEUTAIWAN.png';
import ConfirmationDialog from '../components/ItemRequest/ConfirmationDialog';
import ErrorDialog from '../components/ErrorDialog';
import ItemRequestForm from '../components/ItemRequest/ItemRequestForm';
import roles from '../hooks/Roles';
import CustomDataGrid from '../components/CustomDataGrid';
// import ItemRequestDetail from '../components/ItemRequest/ItemRequestDetail';
import useWindowSize from '../hooks/useWindowSize';
import MobileViewComponent from '../components/MobileView';
import SearchBar from '../components/SearchBar';
import LoadingSpinner from '../components/LoadingSpinner';
import { GetTextByRegion } from '../hooks/Translation';
import DeleteConfirmationDialog from '../components/DeleteConfirmationDialog';
import SendApprovalConfirmationDialog from '../components/ItemRequest/SendApprovalConfirmationDialog';
import ItemRequestConfirmationDialog from '../components/ItemRequest/ItemRequestConfirmationDialog';
import SendChinaConfirmationDialog from '../components/ItemRequest/SendChinaConfirmationDialog';
import StatusProgressBar from '../components/StatusProgressBar';
import CompletedConfirmationDialog from '../components/ItemRequest/CompletedConfirmationDialog'
import { useUser, useValidateRegions } from '../hooks/UserContext';
import isEqual from 'lodash/isEqual';
import { generateItemRequestPDF } from '../components/ItemRequest/GenerateItemRequestPDF';

import HelpIcon from '@mui/icons-material/Help';
import CheckIcon from '@mui/icons-material/Check';
import IconDeleteButton from '../components/IconButtons/IconDeleteButton';
import IconDetailButton from '../components/IconButtons/IconDetailButton';
import IconCompleteButton from '../components/IconButtons/IconCompleteButton';
import IconAcceptButton from '../components/IconButtons/IconAcceptButton';
import IconRejectButton from '../components/IconButtons/IconRejectButton';
import IconDownloadPDFButton from '../components/IconButtons/IconDownloadPDFButton';


const backendString = process.env.REACT_APP_BACKEND_STRING;

const ItemRequestPage = () => {
  //main states for datagrid
  const [itemRequests, setItemRequests] = useState([]);
  const [approvedRequests, setApprovedRequests] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [inprocessRequests, setInprocessRequests] = useState([]);
  const [declinedRequests, setDeclinedRequests] = useState([]);
  const [completedRequests, setCompletedRequests] = useState([]);

  //filtered states for datagrid
  const [filteredItemRequests, setFilteredItemRequests] = useState([]);
  const [filteredPendingRequests, setFilteredPendingRequests] = useState([]);
  const [filteredApprovedRequests, setFilteredApprovedRequests] = useState([]);
  const [filteredInprocessRequests, setFilteredInprocessRequests] = useState([]);
  const [filteredDeclinedRequests, setFilteredDeclinedRequests] = useState([]);
  const [filteredCompletedRequests, setFilteredCompletedRequests] = useState([]);

  //dialog states
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [sendApprovalDialogOpen, setSendApprovalDialogOpen] = useState(false);
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
  const [completedConfirmationDialogOpen, setCompletedConfirmationDialogOpen] = useState(false);
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);
  const [inventoryDialogOpen, setInventoryDialogOpen] = useState(false);
  const [sendChinaDialogOpen, setSendChinaDialogOpen] = useState(false);


  const [redText, setRedText] = useState([]);
  const currentRequestRef = useRef(null);
  const actionTypeRef = useRef('');
  const [error, setError] = useState(null);
  const [isShippingNotes, setIsShippingNotes] = useState(false);
  const [deletingItem, setDeletingItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [tabValue, setTabValue] = useState(0); // State for tab selection
  const { windowSize, smallWidth } = useWindowSize();
  const [searchQuery, setSearchQuery] = useState(''); // State for search query
  const [loading, setLoading] = useState(false);
  const validateSelectedRegion = ["USA", "MEXICO", "TAIWAN", "EU"];
  const { selectedRegion, user: { userRights, username }, refreshUser } = useUser();
  useValidateRegions(selectedRegion, validateSelectedRegion);

  const prevSelectedRegionRef = useRef();
  useEffect(() => {
    refreshUser().then(() => {
      const currentRegion = selectedRegion;
      if (prevSelectedRegionRef.current && !isEqual(prevSelectedRegionRef.current, currentRegion)) {
        handleCloseAll();
      }
      prevSelectedRegionRef.current = currentRegion;
    });
  }, [refreshUser, selectedRegion, dialogOpen, formDialogOpen, sendApprovalDialogOpen, deleteConfirmationDialogOpen, completedConfirmationDialogOpen, detailDialogOpen, inventoryDialogOpen, tabValue]);

  const handleCloseAll = () => {
    setDialogOpen(false);
    setFormDialogOpen(false);
    setSendApprovalDialogOpen(false);
    setDeleteConfirmationDialogOpen(false);
    setCompletedConfirmationDialogOpen(false);
    setDetailDialogOpen(false);
    setInventoryDialogOpen(false);
    setTabValue(0);
  };

  const fetchItemRequests = useCallback(async () => {
    try {
      const connectionString = backendString + `/item-requests?selectedRegion=${selectedRegion}`;

      const response = await axios.get(connectionString);
      setItemRequests(response.data.filter(request => request.status === 'submitted'));
      setPendingRequests(response.data.filter(request => request.status === 'pending'));
      setApprovedRequests(response.data.filter(request => request.status === 'approved'));
      setInprocessRequests(response.data.filter(request => request.status === 'in process' || request.status === 'finished'));
      setDeclinedRequests(response.data.filter(request => request.status === 'declined'));
      setCompletedRequests(response.data.filter(request => request.status === 'completed'));
    } catch (error) {
      setError(error.response?.data?.error);
    }
  }, [selectedRegion]);

  useEffect(() => {
    fetchItemRequests();
  }, [fetchItemRequests, selectedRegion]);

  useEffect(() => {
    // Filter the item requests and approved requests based on the search query
    setFilteredItemRequests(
      itemRequests.filter(request =>
        request.partNumber.some(part => part.toLowerCase().includes(searchQuery.toLowerCase())) ||
        request.customerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        request.model.toLowerCase().includes(searchQuery.toLowerCase()) ||
        request.serial.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredApprovedRequests(
      approvedRequests.filter(request =>
        request.partNumber.some(part => part.toLowerCase().includes(searchQuery.toLowerCase())) ||
        request.customerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        request.model.toLowerCase().includes(searchQuery.toLowerCase()) ||
        request.serial.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredInprocessRequests(
      inprocessRequests.filter(request =>
        request.partNumber.some(part => part.toLowerCase().includes(searchQuery.toLowerCase())) ||
        request.customerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        request.model.toLowerCase().includes(searchQuery.toLowerCase()) ||
        request.serial.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredDeclinedRequests(
      declinedRequests.filter(request =>
        request.partNumber.some(part => part.toLowerCase().includes(searchQuery.toLowerCase())) ||
        request.customerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        request.model.toLowerCase().includes(searchQuery.toLowerCase()) ||
        request.serial.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredPendingRequests(
      pendingRequests.filter(request =>
        request.partNumber.some(part => part.toLowerCase().includes(searchQuery.toLowerCase())) ||
        request.customerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        request.model.toLowerCase().includes(searchQuery.toLowerCase()) ||
        request.serial.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredCompletedRequests(
      completedRequests.filter(request =>
        request.partNumber.some(part => part.toLowerCase().includes(searchQuery.toLowerCase())) ||
        request.customerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        request.model.toLowerCase().includes(searchQuery.toLowerCase()) ||
        request.serial.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, itemRequests, approvedRequests, declinedRequests, pendingRequests, inprocessRequests, completedRequests]);


  const handleOpenDialog = (request, type) => {
    currentRequestRef.current = request;
    actionTypeRef.current = type;
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenHelpDialog = () => {
    setHelpDialogOpen(true);
  };

  const handleCloseHelpDialog = () => {
    setHelpDialogOpen(false);
  };

  const handleConfirm = async (approvalNotes) => {
    setLoading(true);
    const currentRequest = currentRequestRef.current;
    const actionType = actionTypeRef.current;
    if (actionType === 'accept' && currentRequest) { //ACCEPT FORM
      if (selectedRegion === "USA" || selectedRegion === "MEXICO") {
        try {
          // Create FormData for updating the item request
          const updateData = {
            status: 'approved', // Set the status to approved
            approvalNotes: approvalNotes // Include the approval notes
          };

          let connectionString = backendString + `/item-requests/${currentRequest._id}?selectedRegion=${selectedRegion}`;
          // Update item request status
          // const response = await axios.put(connectionString, updateData, {
          await axios.put(connectionString, updateData, {
            headers: {
              'Authorization': sessionStorage.getItem('token')
            }
          });

          connectionString = backendString + `/send-email/approved?selectedRegion=${selectedRegion}`;

          // Send the PDF to the backend server
          await axios.post(connectionString, {
            headers: {
              'Authorization': sessionStorage.getItem('token'),
              'Content-Type': 'multipart/form-data'
            }
          });
          setPendingRequests(prevRequests => prevRequests.filter(r => r._id !== currentRequest._id));
          setApprovedRequests(prevRequests => [...prevRequests, { ...currentRequest, status: 'approved' }]);
        } catch (error) {
          console.error('Error processing item request:', error);
          setError(error.response?.data?.error);
          //case when email fails and put passes
          try {
            const updateData = {
              status: '',
              approvalNotes: ''
            };

            let connectionString = backendString + `/item-requests/${currentRequest._id}?selectedRegion=${selectedRegion}`;
            // Update item request status
            await axios.put(connectionString, updateData, {
              headers: {
                'Authorization': sessionStorage.getItem('token')
              }
            });
          }
          catch (error) {
            setError(error.response?.data?.error);
          }
        }
      }
      else { //region is EU or Taiwan send request straight to in process 

        try {
          // Create FormData for updating the item request
          const updateData = {
            status: 'in process', // Set the status to approved
            approvalNotes: approvalNotes // Include the approval notes
          };

          let connectionString = backendString + `/item-requests/${currentRequest._id}?selectedRegion=${selectedRegion}`;
          // Update item request status
          // const response = await axios.put(connectionString, updateData, {
          await axios.put(connectionString, updateData, {
            headers: {
              'Authorization': sessionStorage.getItem('token')
            }
          });

          connectionString = backendString + `/send-email/inprocess?selectedRegion=${selectedRegion}`;

          // Send the PDF to the backend server
          await axios.post(connectionString, {
            headers: {
              'Authorization': sessionStorage.getItem('token'),
              'Content-Type': 'multipart/form-data'
            }
          });
          setPendingRequests(prevRequests => prevRequests.filter(r => r._id !== currentRequest._id));
          setInprocessRequests(prevRequests => [...prevRequests, { ...currentRequest, status: 'in process' }]);
        } catch (error) {
          console.error('Error processing item request:', error);
          setError(error.response?.data?.error);
          //case when email fails and put passes
          try {
            const updateData = {
              status: '',
              approvalNotes: ''
            };

            let connectionString = backendString + `/item-requests/${currentRequest._id}?selectedRegion=${selectedRegion}`;
            // Update item request status
            await axios.put(connectionString, updateData, {
              headers: {
                'Authorization': sessionStorage.getItem('token')
              }
            });
          }
          catch (error) {
            setError(error.response?.data?.error);
          }
        }
      }
    }
    else { //REJECT FORM
      try {
        let connectionString = backendString + `/item-requests/${currentRequest._id}?selectedRegion=${selectedRegion}`;

        const updateData = {
          status: 'declined',
          approvalNotes: approvalNotes
        };

        // Update item request status
        await axios.put(connectionString, updateData, {
          headers: {
            'Authorization': sessionStorage.getItem('token')
          }
        });

        //get submittedby's users email
        const response = await axios.get(backendString + `/users/email/${currentRequest.submittedBy}`);

        //send email saying declined
        if (response.data.email) {
          const emailData = {
            email: response.data.email,
            serial: currentRequest.serial,
            partNumber: currentRequest.partNumber,
            rejectNotes: approvalNotes
          };
          connectionString = backendString + `/send-email/notapproved?selectedRegion=${selectedRegion}`;
          await axios.post(connectionString, emailData);
        } else {
          setError(error.response?.data?.error);
        }
        setPendingRequests(prevRequests => prevRequests.filter(request => request._id !== currentRequest._id));
        setDeclinedRequests(prevRequests => [...prevRequests, { ...currentRequest, status: 'declined' }]);
      } catch (error) {
        setError(error.response?.data?.error);
      }
    }
    setLoading(false);
    handleCloseDialog();
    handleCloseFormDialog();
    handleCloseDetailDialog();
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  const handleOpenFormDialog = () => {
    setFormDialogOpen(true);
  };

  const handleCloseFormDialog = () => {
    setFormDialogOpen(false);
    setSelectedItem(null);
    setIsShippingNotes(false);
  };

  const handleCloseDetailDialog = () => {
    setSelectedItem(null);
    setDetailDialogOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleEdit = (item) => {
    setSelectedItem(item.row);
    setFormDialogOpen(true);
  };

  const handleDelete = (item) => {
    setDeletingItem(item.row);
    setDeleteConfirmationDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeletingItem(null);
    setDeleteConfirmationDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (deletingItem) {
      try {
        const connectionString = `${backendString}/item-requests/${deletingItem._id}?selectedRegion=${selectedRegion}`;
        await axios.delete(connectionString, {
          headers: { 'Authorization': sessionStorage.getItem('token') }
        });
        setItemRequests((prevRequests) => prevRequests.filter((request) => request._id !== deletingItem._id));
        setPendingRequests((prevRequests) => prevRequests.filter((request) => request._id !== deletingItem._id));
        setApprovedRequests((prevRequests) => prevRequests.filter((request) => request._id !== deletingItem._id));
        setInprocessRequests((prevRequests) => prevRequests.filter((request) => request._id !== deletingItem._id));
        setDeclinedRequests((prevRequests) => prevRequests.filter((request) => request._id !== deletingItem._id));
        setCompletedRequests((prevRequests) => prevRequests.filter((request) => request._id !== deletingItem._id));
        setDeleteConfirmationDialogOpen(false);
      } catch (error) {
        setError(error.response?.data?.error);
      }
    }
  };

  const handleUpdateInventory = (item, errors) => {
    setSelectedItem(item);
    setInventoryDialogOpen(true);
    setRedText(errors);
  };

  const handleUpdateInventoryConfirm = async () => {
    try {
      let connectionString = backendString + `/item-requests/${selectedItem._id}?selectedRegion=${selectedRegion}`;

      const updateData = {
        status: 'completed',
      };

      // Update item request status to 'completed'
      await axios.put(connectionString, updateData, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });

      // All part numbers from request (Exist/DNE included)
      const partNumberData = {
        partNumbers: selectedItem.partNumber,
        quantities: selectedItem.quantity
      };

      connectionString = backendString + '/items';
      // Update inventory table for all part numbers in part request
      const response = await axios.put(`${connectionString}/item-request/updateQuantities?selectedRegion=${selectedRegion}`, partNumberData, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });

      // List to update item history log
      const updatedItems = response.data.updatesHistories;

      // Loop through partNumbers and quantities to create a history entry for each
      const historyEntries = updatedItems.map((item, index) => ({
        partnumber: item.partnumber,
        action: GetTextByRegion("PART REQUEST", selectedRegion),
        username: username,
        quantity: item.quantity, // Get corresponding quantity
        shipto: selectedItem.customerName,
        purpose: selectedItem.purpose,
        timestamp: new Date().toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }).replace(',', '')
      }));

      // Send each history entry to the backend
      await Promise.all(historyEntries.map(entry =>
        axios.post(`${backendString}/itemhistories?selectedRegion=${selectedRegion}`, entry, {
          headers: {
            'Authorization': sessionStorage.getItem('token')
          }
        })
      ));

      // Remove the item from setItemRequests
      setItemRequests(prevRequests =>
        prevRequests.filter(request => request._id !== selectedItem._id)
      );

      // Add the item to setPendingRequests
      setCompletedRequests(prevRequests => [
        ...prevRequests,
        { ...selectedItem, status: 'completed' } // Ensure status is updated
      ]);

    } catch (error) {
      setError(error.response?.data?.error);

      //case when first axios runs but second fails
      let connectionString = backendString + `/item-requests/${selectedItem._id}?selectedRegion=${selectedRegion}`;

      const updateData = {
        status: 'submitted',
      };

      // Update item request status
      await axios.put(connectionString, updateData, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });
    }
    setInventoryDialogOpen(false);
    handleCloseFormDialog();
  };


  const handleUpdateStatusPending = (item) => {
    currentRequestRef.current = item;
    setSendApprovalDialogOpen(true);
  };

  //not in production inventory so requesting for approval or go straight to china
  const handleUpdateStatusPendingConfirm = async () => {
    const currentRequest = currentRequestRef.current;
    if (currentRequest) {
      try {
        let connectionString = backendString + `/item-requests/${currentRequest._id}?selectedRegion=${selectedRegion}`;

        if (selectedRegion === 'USA' || selectedRegion === 'MEXICO') {
          //highvalue 
          if (currentRequest.isHighValue) {
            const updateData = {
              status: 'pending',
            };

            // Update item request status
            await axios.put(connectionString, updateData, {
              headers: {
                'Authorization': sessionStorage.getItem('token')
              }
            });

            await axios.post(backendString + `/send-email/posted?selectedRegion=${selectedRegion}`);
            // Remove the item from setItemRequests
            setItemRequests(prevRequests =>
              prevRequests.filter(request => request._id !== currentRequest._id)
            );

            // Add the item to setPendingRequests
            setPendingRequests(prevRequests => [
              ...prevRequests,
              { ...currentRequest, status: 'pending' } // Ensure status is updated
            ]);
          } else { //not highvalue go straight to china
            const updateData = {
              status: 'in process',
            };

            // Update item request status
            await axios.put(connectionString, updateData, {
              headers: {
                'Authorization': sessionStorage.getItem('token')
              }
            });

            await axios.post(backendString + `/send-email/inprocess?selectedRegion=${selectedRegion}`);
            // Remove the item from setItemRequests
            setItemRequests(prevRequests =>
              prevRequests.filter(request => request._id !== currentRequest._id)
            );

            // Add the item to setApprovedRequests
            setInprocessRequests(prevRequests => [
              ...prevRequests,
              { ...currentRequest, status: 'in process' } // Ensure status is updated
            ]);
          }
        } else { //for EU and Taiwan region everything requires ceo approval
          const updateData = {
            status: 'pending',
          };

          // Update item request status
          await axios.put(connectionString, updateData, {
            headers: {
              'Authorization': sessionStorage.getItem('token')
            }
          });

          await axios.post(backendString + `/send-email/posted?selectedRegion=${selectedRegion}`);
          // Remove the item from setItemRequests
          setItemRequests(prevRequests =>
            prevRequests.filter(request => request._id !== currentRequest._id)
          );

          // Add the item to setPendingRequests
          setPendingRequests(prevRequests => [
            ...prevRequests,
            { ...currentRequest, status: 'pending' } // Ensure status is updated
          ]);
        }

      } catch (error) {
        setError(error.response?.data?.error);
      }
    }
    setSendApprovalDialogOpen(false);
    handleCloseFormDialog();
  };

  const handleCloseCompletedDialog = (item) => {
    setSelectedItem(null);
    setCompletedConfirmationDialogOpen(false);
  };

  const handleCompletedDialog = (item) => {
    setSelectedItem(item.row);
    currentRequestRef.current = item;
    setCompletedConfirmationDialogOpen(true);
  };

  const handleConfirmCompleted = async () => {

    if (selectedItem) {
      let connectionString = backendString + `/item-requests/${selectedItem._id}?selectedRegion=${selectedRegion}`;
      try {
        const updateData = {
          status: 'completed',
        };

        // Update item request status
        await axios.put(connectionString, updateData, {
          headers: {
            'Authorization': sessionStorage.getItem('token')
          }
        });
        // Remove the item from setItemRequests
        setApprovedRequests(prevRequests =>
          prevRequests.filter(request => request._id !== selectedItem._id)
        );

        // Remove the item from setItemRequests
        setInprocessRequests(prevRequests =>
          prevRequests.filter(request => request._id !== selectedItem._id)
        );

        // Add the item to setPendingRequests
        setCompletedRequests(prevRequests => [
          ...prevRequests,
          { ...selectedItem, status: 'completed' } // Ensure status is updated
        ]);

      } catch (error) {
        setError(error.response?.data?.error);
      }
    } else { //when selected item is null
      const currentRequest = currentRequestRef.current;
      let connectionString = backendString + `/item-requests/${currentRequest._id}?selectedRegion=${selectedRegion}`;

      try {
        const updateData = {
          status: 'completed',
        };

        // Update item request status
        await axios.put(connectionString, updateData, {
          headers: {
            'Authorization': sessionStorage.getItem('token')
          }
        });
        // Remove the item from setItemRequests
        setApprovedRequests(prevRequests =>
          prevRequests.filter(request => request._id !== currentRequest._id)
        );

        // Remove the item from setItemRequests
        setInprocessRequests(prevRequests =>
          prevRequests.filter(request => request._id !== currentRequest._id)
        );

        // Add the item to setPendingRequests
        setCompletedRequests(prevRequests => [
          ...prevRequests,
          { ...currentRequest, status: 'completed' } // Ensure status is updated
        ]);

      } catch (error) {
        setError(error.response?.data?.error);
      }
    }
    setCompletedConfirmationDialogOpen(false);
    setFormDialogOpen(false);
  }

  const handleSendChinaDialog = (item) => {
    currentRequestRef.current = item;
    setSendChinaDialogOpen(true);
  };

  const handleSendChina = async () => {
    const currentRequest = currentRequestRef.current;
    if (currentRequest) {
      let connectionString = backendString + `/item-requests/${currentRequest._id}?selectedRegion=${selectedRegion}`;

      try {
        const updateData = {
          status: 'in process',
        };

        // Update item request status
        await axios.put(connectionString, updateData, {
          headers: {
            'Authorization': sessionStorage.getItem('token')
          }
        });

        await axios.post(backendString + `/send-email/inprocess?selectedRegion=${selectedRegion}`);

        // Remove the item from setItemRequests
        setApprovedRequests(prevRequests =>
          prevRequests.filter(request => request._id !== currentRequest._id)
        );

        // Add the item to setPendingRequests
        setInprocessRequests(prevRequests => [
          ...prevRequests,
          { ...currentRequest, status: 'in process' } // Ensure status is updated
        ]);

      } catch (error) {
        setError(error.response?.data?.error);
      }
      setSendChinaDialogOpen(false);
      setFormDialogOpen(false);
    }
  }



  const handleGeneratePDF = (report) => {
    generateItemRequestPDF(report.row, (doc) => {

      const now = new Date();
      const pad = (num) => num.toString().padStart(2, '0');
      const month = pad(now.getMonth() + 1); // Months are 0-based
      const day = pad(now.getDate());
      const year = now.getFullYear();
      const currentTime = `${month}${day}${year}`;

      doc.save(`XR-SPRF-${currentTime}-${report.row.customerName}.pdf`);
    }, (error) => {
      console.error('Failed to generate PDF:', error);
    }, selectedRegion);
  };

  const columns = [
    {
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Submitted Date', selectedRegion)}<br /></Typography>),
      field: 'submittedDate', flex: 1
    },
    {
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Customer Name', selectedRegion)}<br /></Typography>)
      , field: 'customerName', flex: 1
    },
    {
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Part Number', selectedRegion)}<br /></Typography>)
      , field: 'partNumber', flex: 2
    },
    {
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Quantity', selectedRegion)}<br /></Typography>),
      field: 'quantity', flex: 1
    },
    {
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Model', selectedRegion)}<br /></Typography>),
      field: 'model', flex: 1
    },
    {
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Serial Number', selectedRegion)}<br /></Typography>),
      field: 'serial', flex: 1
    },
    {
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Notes', selectedRegion)}<br /></Typography>),
      field: 'notes', flex: 2
    },
    {
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Submitted By', selectedRegion)}<br /></Typography>),
      field: 'submittedBy', flex: 1
    },
    {
      field: 'progress',
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Progress', selectedRegion)}<br /></Typography>),
      flex: 1,
      renderCell: (params) => <StatusProgressBar status={params.row.status} component={'itemrequest'} selectedRegion={selectedRegion} />,
    },
    {
      field: 'actions',
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Actions', selectedRegion)}<br /></Typography>),
      width: 170,
      renderCell: (params) => (
        <div>
          <IconDetailButton onClickEvent={handleEdit} params={params} />
          {tabValue === 3 && userRights >= roles['MODERATOR'] ? (
            params.row.status === 'finished' ? (
              <>
                <IconCompleteButton onClickEvent={handleCompletedDialog} params={params} />
              </>
            ) : (
              <>
                {/*Invisible placeholder*/}
                <IconButton disabled sx={{ opacity: 0 }} aria-label="edit-placeholder"><CheckIcon /></IconButton>
              </>
            )
          ) : null}
          {userRights >= roles['ADMIN'] && (tabValue === 1) && (
            <>
              <IconAcceptButton onClickEvent={() => handleOpenDialog(params.row, 'accept')} />
              <IconRejectButton onClickEvent={() => handleOpenDialog(params.row, 'delete')} />
            </>
          )}
          {tabValue === 5 && (
            <>
              <IconDownloadPDFButton onClickEvent={handleGeneratePDF} params={params} />
            </>
          )}
          {userRights >= roles['MANAGER'] && (
            <IconDeleteButton onClickEvent={handleDelete} params={params} />
          )}
        </div>
      ),
    },
  ];

  const MobileColumns = [
    { label: 'Submitted Date', name: 'submittedDate', type: 'date', flex: 1 },
    { label: 'Customer Name', name: 'customerName', type: 'text', flex: 1 },
    { label: 'Part Number', name: 'partNumber', type: 'text', flex: 2 },
    { label: 'Quantity', name: 'quantity', type: "number", flex: 1 },
    { label: 'Model', name: 'model', type: 'text', flex: 1 },
    { label: 'Serial Number', name: 'serial', type: 'text', flex: 1 },
    { label: 'Notes', name: 'notes', type: 'text', flex: 2 },
    { label: 'Submitted By', name: 'submittedBy', type: 'text', flex: 1 },
    { label: 'Progress', name: 'status', type: 'progress', flex: 1 },
  ];

  return (
    <div style={{ height: windowSize.height * 0.70, width: windowSize.width }}>
      {loading && <LoadingSpinner />}
      <Button variant="contained" color="primary" onClick={handleOpenFormDialog}>
        {GetTextByRegion("Request Spare Parts", selectedRegion)}
      </Button>

      <Button
        onClick={handleOpenHelpDialog}
        sx={{
          minWidth: 'auto',
          padding: 0,
          borderRadius: '50%',
          ml: 1,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <HelpIcon color="primary" />
      </Button>

      <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} placeholder={GetTextByRegion("Search Requests...", selectedRegion)} />

      <Box sx={{ width: '100%' }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile>
          <Tab label={GetTextByRegion("Production", selectedRegion)} value={0} />
          <Tab label={GetTextByRegion("Pending (CEO)", selectedRegion)} value={1} />
          {selectedRegion === 'USA' && <Tab label={GetTextByRegion("Approved", selectedRegion)} value={2} />}
          <Tab label={GetTextByRegion("In Process", selectedRegion)} value={3} />
          <Tab label={GetTextByRegion("Declined", selectedRegion)} value={4} />
          <Tab label={GetTextByRegion("Completed", selectedRegion)} value={5} />
        </Tabs>

        <div style={{ height: windowSize.height * 0.70, width: windowSize.width * 1 }}>
          {windowSize.width > smallWidth ? (
            tabValue === 0 ? (
              <CustomDataGrid rows={filteredItemRequests} columns={columns} />
            ) : tabValue === 1 ? (
              <CustomDataGrid rows={filteredPendingRequests} columns={columns} />
            ) : tabValue === 2 ? (
              <CustomDataGrid rows={filteredApprovedRequests} columns={columns} />
            ) : tabValue === 3 ? (
              <CustomDataGrid rows={filteredInprocessRequests} columns={columns} />
            ) : tabValue === 4 ? (
              <CustomDataGrid rows={filteredDeclinedRequests} columns={columns} />
            ) : (
              <CustomDataGrid rows={filteredCompletedRequests} columns={columns} />
            )
          ) : (
            <MobileViewComponent
              items={
                tabValue === 0 ? filteredItemRequests :
                  tabValue === 1 ? filteredPendingRequests :
                    tabValue === 2 ? filteredApprovedRequests :
                      tabValue === 3 ? filteredInprocessRequests :
                        tabValue === 4 ? filteredDeclinedRequests :
                          filteredCompletedRequests
              }
              columnData={MobileColumns}
              component={'itemrequest'}
              // handleEditClick={(tabValue === 0 || tabValue === 3) ? handleEdit : null}
              // handleShippingEdit={tabValue === 2 || tabValue === 3 ?handleShippingEdit : null}
              handleDelete={handleDelete}
              handleView={handleEdit}
              handleGeneratePDF={tabValue === 5 && handleGeneratePDF}
              handleConfirmCompleted={tabValue === 3 && handleCompletedDialog}
              selectedRegion={selectedRegion}
              isItemRequest={true}
            />
          )}
        </div>
      </Box>
      {formDialogOpen && (
        <ItemRequestForm
          open={formDialogOpen}
          onClose={handleCloseFormDialog}
          onRequestSubmit={fetchItemRequests}
          editItem={selectedItem}
          isShippingNotes={isShippingNotes}
          tabValue={tabValue}
          onAccept={() => handleOpenDialog(selectedItem, 'accept')}
          onReject={() => handleOpenDialog(selectedItem, 'delete')}
          onUpdateStatus={() => handleUpdateStatusPending(selectedItem)}
          onInventoryUpdate={(errors) => handleUpdateInventory(selectedItem, errors)}
          onMarkComplete={() => handleCompletedDialog(selectedItem)}
          onSendToChina={() => handleSendChinaDialog(selectedItem)}
        />
      )}

      {/* Help Dialog */}
      <Dialog open={helpDialogOpen} onClose={handleCloseHelpDialog} maxWidth="xl" fullWidth>
        <DialogContent>
          {selectedRegion === "USA" || selectedRegion === "MEXICO" ?
            <img src={FlowchartImage} alt="Help" style={{ maxWidth: '100%', height: 'auto' }} />
            :
            <img src={FlowchartImageEUTAIWAN} alt="Help" style={{ maxWidth: '100%', height: 'auto' }} />
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHelpDialog} color="primary">
            {GetTextByRegion("Close", selectedRegion)}
          </Button>
        </DialogActions>
      </Dialog>
      <SendApprovalConfirmationDialog
        open={sendApprovalDialogOpen}
        onClose={() => setSendApprovalDialogOpen(false)}
        onConfirm={handleUpdateStatusPendingConfirm}
        selectedRegion={selectedRegion}
      />
      <SendChinaConfirmationDialog
        open={sendChinaDialogOpen}
        onClose={() => setSendChinaDialogOpen(false)}
        onConfirm={handleSendChina}
        selectedRegion={selectedRegion}
      />
      {inventoryDialogOpen && (
        <ItemRequestConfirmationDialog
          open={inventoryDialogOpen}
          onClose={() => setInventoryDialogOpen(false)}
          item={selectedItem || {}} // Provide a default value if needed
          onConfirm={() => {
            handleUpdateInventoryConfirm();
            setDetailDialogOpen(false);
            setSelectedItem(null); // Ensure this doesn't cause issues
          }}
          errors={redText}
        />
      )}
      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirm}
        title={actionTypeRef.current === 'delete' ? GetTextByRegion("Confirm Rejection", selectedRegion) : GetTextByRegion("Confirm Acceptance", selectedRegion)}
        message={actionTypeRef.current === 'delete' ? GetTextByRegion("Are you sure you want to reject this item request?", selectedRegion) : GetTextByRegion("Are you sure you want to approve this item request?", selectedRegion)}
        selectedRegion={selectedRegion}
      />
      <CompletedConfirmationDialog
        open={completedConfirmationDialogOpen}
        onClose={handleCloseCompletedDialog}
        onConfirm={handleConfirmCompleted}
        selectedRegion={selectedRegion}
      />
      <DeleteConfirmationDialog
        open={deleteConfirmationDialogOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDelete}
        selectedRegion={selectedRegion}
      />
      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
        selectedRegion={selectedRegion}
      />
    </div>
  );
};

export default ItemRequestPage;
