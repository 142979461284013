import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorDialog from '../ErrorDialog';
import useWindowSize from '../../hooks/useWindowSize';
import CustomDataGrid from '../CustomDataGrid';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import SearchBar from '../SearchBar';
import ServiceForm from './ServiceOrderForm';
import MobileViewComponent from '../MobileView';
import { useUser } from '../../hooks/UserContext';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const connectionString = `${backendString}/services`

const ServiceTable = ({ services, setServices }) => {
  const [selectedService, setSelectedService] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredServices, setFilteredServices] = useState(services);
  const { windowSize, smallWidth } = useWindowSize();
  const {selectedRegion} = useUser();

  useEffect(() => {
    const searchQueryLower = searchQuery.toLowerCase();
    const isPaidQuery = searchQueryLower === "yes" ? true : searchQueryLower === "no" ? false : null;
  
    const sortedServices = services
      .filter(service => {
        const isPaidValue = service.isPaid === "yes" ? true : service.isPaid === "no" ? false : service.isPaid;
  
        return (
          (service.customer && service.customer.toLowerCase().includes(searchQueryLower)) ||
          (service.serviceType && service.serviceType.toLowerCase().includes(searchQueryLower)) ||
          (service.location && service.location.toLowerCase().includes(searchQueryLower)) ||
          (service.payDate && service.payDate.toLowerCase().includes(searchQueryLower)) ||
          (service.installDate && service.installDate.toLowerCase().includes(searchQueryLower)) ||
          (isPaidQuery !== null && isPaidValue === isPaidQuery) // Check if the isPaid matches the query
        );
      })
      .sort((a, b) => {
        const aIsPaid = a.isPaid === "yes" ? true : a.isPaid === "no" ? false : a.isPaid;
        const bIsPaid = b.isPaid === "yes" ? true : b.isPaid === "no" ? false : b.isPaid;
  
        if (aIsPaid === bIsPaid) {
          // Sort by payDate if both are either paid or unpaid
          return new Date(b.payDate) - new Date(a.payDate);
        }
        // Show paid services (isPaid === true) first
        return aIsPaid ? -1 : 1;
      });
  
    setFilteredServices(sortedServices);
  }, [searchQuery, services]);
  
  

  const handleEdit = (params) => {
    setSelectedService(params.row);
    setOpenEditDialog(true);
  };

  const handleDelete = (params) => {
    setSelectedService(params.row);
    setOpenDeleteDialog(true);
  };

  const handleEditSubmit = async (newData) => {
    setOpenEditDialog(false);
    setServices(prevData => 
      prevData.map(item => 
        item._id === newData._id ? newData : item
      )
    );
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(backendString + `/services/${selectedService._id}`, {headers: {
        'Authorization': sessionStorage.getItem('token')
      }
    });
      setOpenDeleteDialog(false);
      setServices(prevData => 
        prevData.filter(item => item._id !== selectedService._id)
      );
    } catch (error) {
      console.error('Error deleting service:', error);
      setError(error.response?.data?.error);
    }
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedService(null);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedService(null);
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  const columns = [
    { field: 'payDate', headerName: 'Payment Date', flex: 1 },
    { field: 'customer', headerName: 'Customer', flex: 1 },
    { field: 'poNumber', headerName: 'PO Number', flex: 1 },
    { field: 'poDate', headerName: 'PO Date', flex: 1 },
    { field: 'serviceType', headerName: 'Type of Service', flex: 1 },
    { field: 'location', headerName: 'Location', width: 150 },
    { field: 'installDate', headerName: 'Installation Date', flex: 1 },
    {
      field: 'isPaid',
      headerName: 'Paid',
      flex: 1,
      renderCell: (params) => (params.row.isPaid ? 'Yes' : 'No')
    },
    { field: 'notes', headerName: 'Notes', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <div>
          <IconButton aria-label="edit" color="primary" onClick={() => handleEdit(params)}>
            <EditIcon />
          </IconButton>
          <IconButton aria-label="delete" color="secondary" onClick={() => handleDelete(params)}>
            <DeleteIcon />
          </IconButton>
        </div>
      )
    }
  ];

  const mobileColumns = [
    { label: "Payment Date", name: "payDate", type: "text", value: "payDate", required: false, InputLabelProps: {}, position: 'left' },
    { label: "Customer", name: "customer", type: "text", value: "customer", required: false, InputLabelProps: {}, position: 'left' },
    { label: "PO Number", name: "poNumber", type: "text", value: "poNumber", required: true, InputLabelProps: {}, position: 'left' },
    { label: "PO Date", name: "poDate", type: "text", value: "poDate", required: true, InputLabelProps: {}, position: 'left' },
    { label: "Type of Service", name: "serviceType", type: "text", value: "serviceType", required: true, InputLabelProps: {}, position: 'left' },
    { label: "Location", name: "location", type: "text", value: "location", required: false, InputLabelProps: {}, position: 'left' },
    { label: "Installation Date", name: "installDate", type: "text", value: "installDate", required: false, InputLabelProps: {}, position: 'right' },
    { label: "Paid", name: "isPaid", type: "boolean", value: "isPaid", required: false, InputLabelProps: {}, position: 'right' },
    { label: "Notes", name: "notes", type: "text", value: "notes", required: false, InputLabelProps: {}, position: 'right' },
  ];

  return (
    <div style={{ height: windowSize.height * 0.7, width: windowSize.width * 1 }}>
       <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} placeholder={"Search Orders..."}/>
      {windowSize.width > smallWidth ? (
        <CustomDataGrid rows={filteredServices} columns={columns} />
      ) : (
        <MobileViewComponent items={filteredServices} columnData={mobileColumns} handleEditClick={handleEdit} handleDelete={handleDelete} connectionString={connectionString} selectedRegion={selectedRegion}/>
      )}
      {openEditDialog &&
        <ServiceForm
          mode="edit"
          open={openEditDialog}
          onClose={handleCloseEditDialog}
          service={selectedService}
          onSubmit={handleEditSubmit}
        />
      }
      {openDeleteDialog &&
        <DeleteConfirmationDialog
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          onConfirm={handleDeleteConfirm}
        />
      }

      {/* Error Dialog */}
      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </div>
  );
};

export default ServiceTable;
