import React, { useState, useEffect } from 'react';
import { Container, Grid, Button, Typography, Card, CardContent, List, ListItem, ListItemText, Divider, Dialog, DialogTitle, DialogContent, IconButton, DialogActions} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoteServiceForm from '../components/RemoteService/RemoteServiceForm';
import SavedServicesList from '../components/RemoteService/SavedServicesList';
import axios from 'axios';
import ErrorDialog from '../components/ErrorDialog';
import { useUser, useValidateRegions } from '../hooks/UserContext';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const RemoteServicePage = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isSavedServicesOpen, setIsSavedServicesOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false); // Add state for edit form visibility
  const [completedServices, setCompletedServices] = useState([]); // State for completed services
  const [serviceToDelete, setServiceToDelete] = useState(null);
  const [error, setError] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [services, setServices] = useState([]);
  const validateSelectedRegion = ["USA"];
  const {selectedRegion, refreshUser } = useUser();
  useValidateRegions(selectedRegion, validateSelectedRegion);

  useEffect(() => {
    refreshUser();
  }, [refreshUser, isSavedServicesOpen, isFormOpen, isEditFormOpen, confirmDialogOpen]);

  useEffect(() => {
    fetchCompletedServices();
  }, []);

  const fetchCompletedServices = async () => {
    try {
      const response = await axios.get(`${backendString}/remoteservices/submitted`, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        },
      });
      setCompletedServices(response.data);
    } catch (error) {
      console.error('There was an error fetching the completed services!', error);
      setError(error.response?.data?.error);

    }
  };

  const handleOpenPopup = () => {
    setIsFormOpen(true);
  };

  const handleClosePopup = () => {
    setIsFormOpen(false);
  };

  const handleOpenSavedServices = () => {
    setIsSavedServicesOpen(true);
  };

  const handleCloseSavedServices = () => {
    setIsSavedServicesOpen(false);
  };

  const handleSelectService = (service) => {
    setSelectedService(service);
    setIsSavedServicesOpen(false);
    setIsEditFormOpen(true); // Open the edit form
  };

  const handleCloseEditForm = () => {
    setIsEditFormOpen(false);
    setSelectedService(null); // Optionally reset selected service
  };

  const handleOpenConfirmDialog = (service) => {
    setServiceToDelete(service);
    setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setServiceToDelete(null);
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  const handleDelete = async () => {
    if (serviceToDelete) {
      try {
        await axios.delete(`${backendString}/remoteservices/${serviceToDelete._id}`, {
          headers: {
            'Authorization': sessionStorage.getItem('token')
          },
        });
        setServices(services.filter(service => service._id !== serviceToDelete._id));
        setServiceToDelete(null);
        setConfirmDialogOpen(false);
        fetchCompletedServices();
      } catch (error) {
        console.error('Error deleting remote service:', error);
        setError(error.response?.data?.error);

      }
    }
  };

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        Remote Service
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Start New Remote Service
              </Typography>
              <Typography paragraph>
                Begin a new remote service request by filling out the necessary details.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenPopup}
              >
                Start New Service
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Resume Saved Remote Service
              </Typography>
              <Typography paragraph>
                Continue with a previously saved remote service request.
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleOpenSavedServices}
              >
                Resume Saved Service
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {isFormOpen && (
      <RemoteServiceForm open={isFormOpen} type="new" onClose={handleClosePopup} onFormSubmit={fetchCompletedServices}/>
      )}
      {isSavedServicesOpen && (
      <SavedServicesList open={isSavedServicesOpen} onClose={handleCloseSavedServices} onSelect={handleSelectService}/>
      )}
      {selectedService && isEditFormOpen && (
        <RemoteServiceForm 
          open={isEditFormOpen} 
          type = "edit"
          onClose={handleCloseEditForm} 
          service={selectedService} 
          onFormSubmit={fetchCompletedServices}
        />
      )}

      <Typography variant="h5" gutterBottom style={{ marginTop: '20px' }}>
        Completed Services
      </Typography>
      <List>
        {completedServices.map(service => (
          <React.Fragment key={service._id}>
            <ListItem>
            <ListItemText
              primary={service.issueType}
              secondary={
                <>
                  <Typography component="span" variant="body2" color="textPrimary">
                    Customer: {service.customer}
                  </Typography>
                  <br />
                  Model: {service.model}
                  <br />
                  Notes: {service.notes}
                  <br />
                  Start Time: {new Date(service.startTime).toLocaleString()} {/* Format the start time */}
                  <br />
                  End Time: {new Date(service.endTime).toLocaleString()} {/* Format the end time */}
                </>
              }
            />
            <IconButton edge="end" color='secondary' onClick={() => handleOpenConfirmDialog(service)}>
              <DeleteIcon />
            </IconButton>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
      <Dialog
        open={confirmDialogOpen}
        onClose={handleCloseConfirmDialog}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this service?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Error Dialog */}
      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </Container>
  );
};

export default RemoteServicePage;
