import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  Box,
  Grid
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import generatePackingListPDF from '../PackingList/GeneratePackingListPDF';

const PackingListFormDialog = ({ open, handleClose, machine }) => {
  const [isDemo, setIsDemo] = useState(false);
  const [date, setDate] = useState('');
  const [notes, setNotes] = useState(machine.terms + '\n' + machine.notes);
  const [machineParts, setMachineParts] = useState({
    pc: machine?.pc || '',
    tube: machine?.tube || '',
    detector: machine?.detector || '',
    monitor: machine?.monitor || '',
    printer: machine?.printer || '',
    camera: machine?.camera || '',
    mvpShell: machine?.mvpShell || '',
    mvpSoftware: machine?.mvpSoftware || '',
    loader: machine?.loader || '',
    unloader: machine?.unloader || '',
    dlc: machine?.dlc || '',
  });

  useEffect(() => {
    // Update the parts if machine data changes
    setMachineParts({
      pc: machine?.pc || '',
      tube: machine?.tube || '',
      detector: machine?.detector || '',
      monitor: machine?.monitor || '',
      printer: machine?.printer || '',
      camera: machine?.camera || '',
      mvpShell: machine?.mvpShell || '',
      mvpSoftware: machine?.mvpSoftware || '',
      loader: machine?.loader || '',
      unloader: machine?.unloader || '',
      dlc: machine?.dlc || '',
    });
  }, [machine]);

  useEffect(() => {
    const today = new Date();
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1);
    const formattedDate = lastMonth.toISOString().slice(0, 7); // Format to 'yyyy-MM'
    setDate(formattedDate);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare the packing serial data in the order of displayed rows
    const packingSerialData = rows.map((row) => row.packing_serials || 'N/A');

    // Prepare the machine object with the updated part data
    const updatedMachine = {
      ...machine,
      packing_serials: packingSerialData, // Include the packing serial array
      manufacturedDate: date,
      packing_notes: notes,
      isDemo,
    };

    generatePackingListPDF(updatedMachine);
    handleClose(); // Close the dialog after submission
  };

  const handlePartChange = (e) => {
    const { name, value, type } = e.target;

    // If the field is the 'date' field, handle it separately
    if (type === 'month') {
      setDate(value); // Specifically update the date state for the month field
    } else {
      // Update the correct part in machineParts state
      setMachineParts((prevParts) => ({
        ...prevParts,
        [name.toLowerCase()]: value, // Ensure lowercase comparison to match dynamic field
      }));
    }
  };


  // DataGrid column definitions
  const columns = [
    { field: 'partNumber', headerName: 'Part Number', flex: 1 },
    { field: 'description', headerName: 'Description', flex: 1.5 },
    { field: 'quantity', headerName: 'Quantity', flex: 0.5 },
    {
      field: 'packing_serials',
      headerName: 'Serial Number',
      flex: 1,
      renderCell: (params) =>
        <TextField
          name={params.row.description}
          variant="standard"
          value={machineParts[params.row.description.toLowerCase()] || params.value || ''}
          onChange={(e) => handlePartChange(e)}
          fullWidth
        />
    },
  ];

  // Create rows by mapping through packing arrays
  const rows = machine?.packing_descriptions?.map((description, index) => {
    const serial = machineParts[description.toLowerCase()] // Use the user-typed serial if available
      || (machine.packing_partnumbers[index] === machine.model
        ? machine.serial || 'N/A'
        : description.toLowerCase().includes(machine.model.toLowerCase())
          ? machine.serial || 'N/A'
          : description.toLowerCase().includes('pc')
            || description.toLowerCase().includes('win 11')
            || description.toLowerCase().includes('16g ram')
            ? machine.pc || 'N/A'
            : description.toLowerCase().includes('tube')
              ? machine.tube || 'N/A'
              : description.toLowerCase().includes('detector')
                ? machine.detector || 'N/A'
                : description.toLowerCase().includes('monitor')
                  ? machine.monitor || 'N/A'
                  : description.toLowerCase().includes('printer')
                    ? machine.printer || 'N/A'
                    : description.toLowerCase().includes('barcode scanner')
                      ? machine.barcodeScanner || 'N/A'
                      : description.toLowerCase().includes('unloader')
                        ? machine.unloader || 'N/A'
                        : description.toLowerCase().includes('loader')
                          ? machine.loader || 'N/A'
                          : description.toLowerCase().includes('camera')
                            ? machine.camera || 'N/A'
                            : description.toLowerCase().includes('dlc')
                              ? machine.dlc || 'N/A'
                              : description.toLowerCase().includes('power supply')
                                ? machine.tubePS || 'N/A'
                                : description.toLowerCase().includes('10.0 software')
                                  ? machine.fix || 'N/A'
                                  : description.toLowerCase().includes('counting software')
                                    ? machine.fix || 'N/A'
                                    : description.toLowerCase().includes('mvp license') || description.toLowerCase().includes('mvp software')
                                      ? machine.mvpSoftware || 'N/A'
                                      : description.toLowerCase().includes('mvp dongle') || description.toLowerCase().includes('mvp shell')
                                        ? machine.mvpShell || 'N/A'
                                        : 'N/A'); // If no matching part found, return 'N/A'

    return {
      id: index + 1,
      partNumber: machine.packing_partnumbers[index],
      description,
      quantity: machine.packing_quantities[index],
      packing_serials: serial, // Prefilled or user-defined serial
    };
  });

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>Packing List Form</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} alignItems="center" mb={2} mt={1}>
          {/* First row: Demo checkbox and Date Manufactured */}
          <Grid item xs={12} sm={6} alignItems="center" justifyContent="center" >
            <FormControlLabel
              control={
                <Checkbox
                  checked={isDemo}
                  onChange={(e) => setIsDemo(e.target.checked)}
                />
              }
              label="Demo ?"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name="date"
              type="month"
              onChange={(e) => setDate(e.target.value)}  // Update state on change
              value={date}  // Set the prefilled date value
              label="Date Manufactured"
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>

          {/* Second row: Notes */}
          <Grid item xs={12}>
            <TextField
              multiline
              onChange={(e) => setNotes(e.target.value)}  // Update state on change
              value={notes}  // Prefill the value
              label="Notes"
              fullWidth
            />
          </Grid>
        </Grid>


        {/* DataGrid for displaying packing information */}
        <div style={{ width: '100%' }}>
          <DataGrid rows={rows} columns={columns} pageSize={5} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PackingListFormDialog;
