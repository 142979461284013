import React, { useState, useEffect } from 'react';
import { Button, Box, Tab, Tabs, Dialog, DialogTitle, Select, MenuItem, DialogActions, InputAdornment, FormControl, InputLabel, Grid, TextField, DialogContent, Autocomplete, FormHelperText } from '@mui/material';
import generateQuotationPDF from './GenerateQuotationPDF';
import ModelDropdown from '../ModelDropdown';
import { useUser } from '../../hooks/UserContext';
import { useNavigate } from 'react-router-dom';
import ServiceItemView from './ServiceItem/ServiceItemView';
import PartItemView from './PartItem/PartItemView';
import ShippingItemView from './ShippingItem/ShippingItemView';
import ErrorDialog from '../ErrorDialog';

const QuotationView = ({ open, onClose }) => {

    const today = new Date().toISOString().split('T')[0];
    const navigate = useNavigate();
    const { selectedRegion, user: { username } } = useUser();

    const [value, setValue] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');
    const [warning, setWarning] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [basicInfo, setBasicInfor] = useState({
        attn: "",
        quoteDate: today,
        customer: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        email: "",
        qouteValid: "30 days from date above",
        incoterm: "",
        paymentTerms: "",
    }, []);
    //service information
    const [services, setServices] = useState([]);
    const [totalServiceCost, setTotalServiceCost] = useState(0);
    //travel information
    const [totalTravelCost, setTotalTravelCost] = useState(0);
    const [travelCosts, setTravelCosts] = useState({
        travelerNumber: "",
        airlineCost: "",
        perDiemDays: "",
        diemPricePerDay: "",
        hotelStayDays: "",
        hotelPricePerNight: "",
        rentalCarDays: "",
        rentalCarPricePerDay: "",
        mileage: "",
        pricePerMile: "0.68",
        travelHours: "",
        travelPricePerHour: "75",
        Miscellaneous: "",
        MiscellaneousPricePerPerson: "100",
    }, []);
    //part information
    const [totalPartCost, setTotalPartCost] = useState(0);
    const [parts, setParts] = useState([]);
    //machine information
    const [numMachineNumbers, setNumMachineNumbers] = useState(1);
    const [machineModels, setMachineModels] = useState([]);
    const [serials, setSerials] = useState([]);
    //shipping information
    const [shippings, setShipping] = useState([]);
    const [totalShippingCost, setTotalShippingCost] = useState(0);
    //total cost
    const [totalCost, setTotalCost] = useState(0);
    //discount
    const [discount, setDiscount] = useState(
        {
            cashDiscount: 0,
            percentageDiscount: 0,
            totalPercentageDiscount: 0
        }
    );

    const [note, setNote] = useState(
        "Please note that all costs provided are estimates. The final invoice will reflect actual costs incurred.\n\n" +
        "Quoted prices may be subject to applicable sales tax. " +
        "Maintenance and service appointments will be scheduled based on availability within two weeks after payment is received."
    );

    const handleMachineNumbersChange = (event) => {
        const value = Math.max(1, parseInt(event.target.value, 10) || 1);
        setNumMachineNumbers(value);
        setMachineModels(prevModels => prevModels.filter((_, i) => i < value));
        setSerials(prevSerials => prevSerials.filter((_, i) => i < value));
    };

    const [senderInformation, setSenderImformation] = useState({
        senderName: username,
        senderContact: "",
        senderEmail: "",
        orderToEmail: "Orders@Sciencope.com",
        version: ""
    }, []);

    useEffect(() => {
        const calculateTravelTotalSum = () => {
            return (
                ((travelCosts.travelerNumber || 0) * (travelCosts.airlineCost || 0)) +
                ((travelCosts.perDiemDays || 0) * (travelCosts.diemPricePerDay || 0)) +
                ((travelCosts.rentalCarDays || 0) * (travelCosts.rentalCarPricePerDay || 0)) +
                ((travelCosts.hotelStayDays || 0) * (travelCosts.hotelPricePerNight || 0)) +
                ((travelCosts.mileage || 0) * (travelCosts.pricePerMile || 0)) +
                ((travelCosts.travelHours || 0) * (travelCosts.travelPricePerHour || 0)) +
                ((travelCosts.Miscellaneous || 0) * (travelCosts.MiscellaneousPricePerPerson || 0))
            );
        };
        const travelTotal = calculateTravelTotalSum();
        setTotalTravelCost(travelTotal);
        onDiscountChanged(discount.percentageDiscount);
    }, [travelCosts]);

    useEffect(() => {
        const calculateServiceTotalSum = () => {
            return Object.values(services).reduce((total, item) => total + item.total, 0);
        };
        const serviceTotal = calculateServiceTotalSum();
        setTotalServiceCost(serviceTotal);
        onDiscountChanged(discount.percentageDiscount);
    }, [services]);

    useEffect(() => {
        const calculatePartTotalSum = () => {
            return Object.values(parts).reduce((total, part) => {
                const partTotal = parseFloat(part.total);
                return total + partTotal
            }, 0);
        };
        const partTotal = calculatePartTotalSum();
        setTotalPartCost(partTotal);
        onDiscountChanged(discount.percentageDiscount);
    }, [parts]);

    useEffect(() => {
        const calculateShippingTotalSum = () => {
            return Object.values(shippings).reduce((total, part) => {
                const partTotal = parseFloat(part.total) || 0; // Log the current part
                return total + partTotal;
            }, 0);
        };
        const shippingTotal = calculateShippingTotalSum();
        setTotalShippingCost(shippingTotal);
        onDiscountChanged(discount.percentageDiscount);
    }, [shippings]);

    useEffect(() => {
        const total = (totalServiceCost || 0) + (totalPartCost || 0) + (totalShippingCost || 0) + (totalTravelCost || 0);
        setTotalCost(total);
    }, [totalServiceCost, totalPartCost, totalShippingCost, totalTravelCost]);

    const handleChange = (event, newValue) => {
        setServices([]);
        setShipping([]);
        setParts([]);
        setTravelCosts({
            travelerNumber: "1",
            airlineCost: "",
            perDiemDays: "",
            diemPricePerDay: "",
            hotelStayDays: "",
            hotelPricePerNight: "",
            rentalCarDays: "",
            rentalCarPricePerDay: "",
            mileage: "",
            pricePerMile: "0.68",
            travelHours: "",
            travelPricePerHour: "75",
            Miscellaneous: "",
            MiscellaneousPricePerPerson: "100"
        }
        )
        setDiscount({
            cashDiscount: 0,
            percentageDiscount: 0,
            totalPercentageDiscount: 0
        });

        setValue(newValue);
    };
    const handleZipCodeChange = (zipcode) => {
        setBasicInfor({ ...basicInfo, zip: zipcode });
        if (zipcode.length === 5) {
            fetchPerdiem(zipcode);
        }
    };
    const handleOptionChange = (event, newValue) => {
        setSelectedOption(newValue);
        setBasicInfor({ ...basicInfo, paymentTerms: event.target.value });
    };
    useEffect(() => {
        const newTotalPercentageDiscount = parseFloat(discount.percentageDiscount * totalCost / 100.00);
        setDiscount((prevDiscount) => ({
            ...prevDiscount,
            totalPercentageDiscount: newTotalPercentageDiscount,
        }));
    }, [totalCost, discount.percentageDiscount]);
    const onDiscountChanged = (value) => {
        let newValue = value;
        if (value < 0) { newValue = 0; }
        if (value > 100) { newValue = 100; }
        setDiscount((prevDiscount) => ({
            ...prevDiscount,
            percentageDiscount: newValue,
        }));
    };
    const TravelCostSummary = () => {
        return (
            <Box style={{ marginTop: 16, textAlign: 'right' }}>
                <strong>Sub Total: ${totalTravelCost.toFixed(2)}</strong>
            </Box>
        );
    };
    const TotalCostSummary = () => {
        return (
            <Box style={{ marginTop: 16, textAlign: 'right' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <strong>Base Amount:</strong>
                    <strong>${totalCost.toFixed(2)}</strong>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <strong style={{ color: "red" }}>Cash discount:</strong>
                    <strong style={{ color: "red" }}>-${parseFloat(discount.cashDiscount ? discount.cashDiscount : 0).toFixed(2)}</strong>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <strong style={{ color: "red" }}>{discount.percentageDiscount}% off Discount:</strong>
                    <strong style={{ color: "red" }}>-${parseFloat(discount.totalPercentageDiscount).toFixed(2)}</strong>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 18, fontWeight: "bold" }}>
                    <strong>Grand Total:</strong>
                    <strong>${(totalCost - discount.totalPercentageDiscount - discount.cashDiscount).toFixed(2)}</strong>
                </div>
            </Box>
        );
    }
    const fetchPerdiem = async (zip) => {
        try {
            const currentYear = new Date().getFullYear();
            const response = await fetch('https://api.gsa.gov/travel/perdiem/v2/rates/zip/' + zip + '/year/' + currentYear + '?api_key=bHkhk8UPom39IvoDRmrKnECJU0u1cdx89ZNd4H8W');
            if (!response.ok) {
                //setError('Failed to fetch per diem data');
                return;
            }
            const data = await response.json();
            const mealValue = data.rates[0].rate[0].meals;
            setTravelCosts((prev) => ({ ...prev, diemPricePerDay: mealValue }));
        } catch (error) {
            //setError(error);
        } finally {
            //setLoading(false);
        }
    };
    const onSubmit = () => {
        let data = basicInfo;

        if (value === 1) {
            data = { ...data, serviceType: 'Service' }
            data = { ...data, parts };
            for (let item in parts) {
                if (parts[item] === "") {
                    parts[item] = 0;
                }
            }
            for (let item in travelCosts) {
                if (travelCosts[item] === "") {
                    travelCosts[item] = 0;
                }
            }
            data = { ...data, travelCosts };
            data = { ...data, totalTravelCost };
            data = { ...data, totalPartCost };
            data = { ...data, shippings };
            data = { ...data, totalShippingCost };
        } else {
            data = { ...data, serviceType: 'PM' }
            data = { ...data, services };
            data = { ...data, totalServiceCost };
        }
        data = { ...data, senderInformation };
        data = { ...data, machineModels };
        data = { ...data, serials };
        data = { ...data, note };
        data = { ...data, totalCost };
        data = { ...data, discount };
        generateQuotationPDF(data);
    };
    const handleWarningClose = () => {
        setWarning(false); // Close the dialog
    };
    //temporary close this window 
    const handleDialogClose = () => {
        navigate('/'); // Redirect to the main page
    };
    const predefinedOptions = ['Pre-pay', 'Paid', 'Free', 'Net 30', 'Net 60', 'Net 180'];
    return (
        <>
            <Dialog open={open} maxWidth="md">
                <DialogTitle>Quotation</DialogTitle>
                <DialogContent>
                    <Box sx={{ width: '100%' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="quotation tabs">
                            <Tab label="Preventive Maintenance" />
                            <Tab label="Service" />
                        </Tabs>
                        <Box>
                            <br />
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                                Basic Information
                            </Box>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Attn:"
                                        variant="standard"
                                        margin="normal"
                                        value={basicInfo.attn}
                                        onChange={(e) => setBasicInfor({ ...basicInfo, attn: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        label="Quote Date:"
                                        type="date"
                                        margin="normal"
                                        value={basicInfo.quoteDate}
                                        onChange={(e) => setBasicInfor({ ...basicInfo, quoteDate: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth
                                        variant="standard"
                                        label="Customer:"
                                        margin="normal"
                                        value={basicInfo.customer}
                                        onChange={(e) => setBasicInfor({ ...basicInfo, customer: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth
                                        variant="standard"
                                        label="Quote Valid:"
                                        margin="normal"
                                        value={basicInfo.qouteValid}
                                        onChange={(e) => setBasicInfor({ ...basicInfo, qouteValid: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth
                                        variant="standard"
                                        label="Street:"
                                        margin="normal"
                                        value={basicInfo.street}
                                        onChange={(e) => setBasicInfor({ ...basicInfo, street: e.target.value })}
                                    />
                                </Grid>

                                <Grid item xs={4} sm={2}>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        label="City:"
                                        margin="normal"
                                        value={basicInfo.city}
                                        onChange={(e) => setBasicInfor({ ...basicInfo, city: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        margin="normal"
                                        label="State:"
                                        value={basicInfo.state}
                                        onChange={(e) => setBasicInfor({ ...basicInfo, state: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        label="Zip:"
                                        margin="normal"
                                        value={basicInfo.zip}
                                        onChange={(e) => handleZipCodeChange(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        margin="normal"
                                        label="Incoterm:"
                                        value={basicInfo.incoterm}
                                        onChange={(e) => setBasicInfor({ ...basicInfo, incoterm: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        margin="normal"
                                        label="Phone:"
                                        value={basicInfo.phone}
                                        onChange={(e) => setBasicInfor({ ...basicInfo, phone: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Autocomplete
                                        freeSolo
                                        options={predefinedOptions}
                                        value={selectedOption}
                                        onChange={handleOptionChange}
                                        onBlur={(e) => setBasicInfor({ ...basicInfo, paymentTerms: e.target.value })}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Payment Term" variant="standard" margin="normal" />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Email"
                                        variant="standard"
                                        margin="normal"
                                        onChange={(e) => setBasicInfor({ ...basicInfo, email: e.target.value })}
                                        value={basicInfo.email} />
                                </Grid>
                            </Grid>
                        </Box>

                        {value === 0 && (
                            <ServiceItemView setServices={setServices} services={services} totalServiceCost={totalServiceCost} />
                        )}

                        {value === 1 && (

                            <Box>
                                <Box>
                                    <br />
                                    <PartItemView parts={parts} setParts={setParts} totalPartCost={totalPartCost} />
                                    <br />
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                                        Estimated Travel Costs
                                    </Box>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label="# of Traveler"
                                                variant="outlined"
                                                margin="normal"
                                                value={travelCosts.travelerNumber}
                                                onChange={(e) => setTravelCosts({ ...travelCosts, travelerNumber: e.target.value })}
                                                onKeyPress={(e) => {
                                                    const allowedKeys = /^[0-9]+$/;
                                                    if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                                        e.preventDefault();
                                                        setErrorMessage("Please enter a number.");
                                                        setWarning(true);
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label="Airline Ticket Price"
                                                variant="outlined"
                                                margin="normal"
                                                value={travelCosts.airlineCost ? `$${travelCosts.airlineCost}` : ''}
                                                onChange={(e) => setTravelCosts({ ...travelCosts, airlineCost: e.target.value.replace('$', '') })}
                                                onKeyPress={(e) => {
                                                    const allowedKeys = /^[0-9.]+$/;
                                                    if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                                        e.preventDefault();
                                                        setErrorMessage("Please enter a valid price (numbers and up to two decimal places)");
                                                        setWarning(true);
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label="Diem Days"
                                                variant="outlined"
                                                margin="normal"
                                                value={travelCosts.perDiemDays}
                                                onChange={(e) => setTravelCosts({ ...travelCosts, perDiemDays: e.target.value })}
                                                onKeyPress={(e) => {
                                                    const allowedKeys = /^[0-9]+$/;
                                                    if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                                        e.preventDefault();
                                                        setErrorMessage("Please enter a number.");
                                                        setWarning(true);
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label="Per Diem Price a Day"
                                                variant="outlined"
                                                margin="normal"
                                                value={travelCosts.diemPricePerDay ? `$${travelCosts.diemPricePerDay}` : ''}
                                                onChange={(e) => setTravelCosts({ ...travelCosts, diemPricePerDay: e.target.value.replace('$', '') })}
                                                onKeyPress={(e) => {
                                                    const allowedKeys = /^[0-9.]+$/;
                                                    if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                                        e.preventDefault();
                                                        setErrorMessage("Please enter a valid price (numbers and up to two decimal places)");
                                                        setWarning(true);
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label="Hotel Stay Days"
                                                variant="outlined"
                                                margin="normal"
                                                value={travelCosts.hotelStayDays}
                                                onChange={(e) => setTravelCosts({ ...travelCosts, hotelStayDays: e.target.value })}
                                                onKeyPress={(e) => {
                                                    const allowedKeys = /^[0-9]+$/;
                                                    if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                                        e.preventDefault();
                                                        setErrorMessage("Please enter a number.");
                                                        setWarning(true);
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label="Hotel Price Per Night"
                                                variant="outlined"
                                                margin="normal"
                                                value={travelCosts.hotelPricePerNight ? `$${travelCosts.hotelPricePerNight}` : ''}
                                                onChange={(e) => setTravelCosts({ ...travelCosts, hotelPricePerNight: e.target.value.replace('$', '') })}
                                                onKeyPress={(e) => {
                                                    const allowedKeys = /^[0-9.]+$/;
                                                    if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                                        e.preventDefault();
                                                        setErrorMessage("Please enter a valid price (numbers and up to two decimal places)");
                                                        setWarning(true);
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label="Rental Car Days"
                                                variant="outlined"
                                                margin="normal"
                                                value={travelCosts.rentalCarDays}
                                                onChange={(e) => setTravelCosts({ ...travelCosts, rentalCarDays: e.target.value })}
                                                onKeyPress={(e) => {
                                                    const allowedKeys = /^[0-9]+$/;
                                                    if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                                        e.preventDefault();
                                                        setErrorMessage("Please enter a number.");
                                                        setWarning(true);
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label="Rental Car Price Per Day"
                                                variant="outlined"
                                                margin="normal"
                                                value={travelCosts.rentalCarPricePerDay ? `$${travelCosts.rentalCarPricePerDay}` : ''}
                                                onChange={(e) => setTravelCosts({ ...travelCosts, rentalCarPricePerDay: e.target.value.replace('$', '') })}
                                                onKeyPress={(e) => {
                                                    const allowedKeys = /^[0-9.]+$/;
                                                    if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                                        e.preventDefault();
                                                        setErrorMessage("Please enter a valid price (numbers and up to two decimal places)");
                                                        setWarning(true);
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label="Mileage"
                                                variant="outlined"
                                                margin="normal"
                                                value={travelCosts.mileage}
                                                onChange={(e) => setTravelCosts({ ...travelCosts, mileage: e.target.value })}
                                                onKeyPress={(e) => {
                                                    const allowedKeys = /^[0-9]+$/;
                                                    if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                                        e.preventDefault();
                                                        setErrorMessage("Please enter a number.");
                                                        setWarning(true);
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label="Price Per Mileage"
                                                variant="outlined"
                                                margin="normal"
                                                value={travelCosts.pricePerMile ? `$${travelCosts.pricePerMile}` : ''}
                                                onChange={(e) => setTravelCosts({ ...travelCosts, pricePerMile: e.target.value.replace('$', '') })}
                                                onKeyPress={(e) => {
                                                    const allowedKeys = /^[0-9.]+$/;
                                                    if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                                        e.preventDefault();
                                                        setErrorMessage("Please enter a valid price (numbers and up to two decimal places)");
                                                        setWarning(true);
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label="Travel Hours"
                                                variant="outlined"
                                                margin="normal"
                                                value={travelCosts.travelHours}
                                                onChange={(e) => setTravelCosts({ ...travelCosts, travelHours: e.target.value })}
                                                onKeyPress={(e) => {
                                                    const allowedKeys = /^[0-9]+$/;
                                                    if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                                        e.preventDefault();
                                                        setErrorMessage("Please enter a number.");
                                                        setWarning(true);
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label="Travel Price Per Hour"
                                                variant="outlined"
                                                margin="normal"
                                                value={travelCosts.travelPricePerHour ? `$${travelCosts.travelPricePerHour}` : ''}
                                                onChange={(e) => setTravelCosts({ ...travelCosts, travelPricePerHour: e.target.value.replace('$', '') })}
                                                onKeyPress={(e) => {
                                                    const allowedKeys = /^[0-9.]+$/;
                                                    if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                                        e.preventDefault();
                                                        setErrorMessage("Please enter a valid price (numbers and up to two decimal places)");
                                                        setWarning(true);
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label="Miscellaneous"
                                                variant="outlined"
                                                margin="normal"
                                                value={travelCosts.Miscellaneous}
                                                onChange={(e) => setTravelCosts({ ...travelCosts, Miscellaneous: e.target.value })}
                                                onKeyPress={(e) => {
                                                    const allowedKeys = /^[0-9]+$/;
                                                    if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                                        e.preventDefault();
                                                        setErrorMessage("Please enter a number.");
                                                        setWarning(true);
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label="Miscellaneous Price Per Person"
                                                variant="outlined"
                                                margin="normal"
                                                value={travelCosts.MiscellaneousPricePerPerson ? `$${travelCosts.MiscellaneousPricePerPerson}` : ''}
                                                onChange={(e) => setTravelCosts({ ...travelCosts, MiscellaneousPricePerPerson: e.target.value.replace('$', '') })}
                                                onKeyPress={(e) => {
                                                    const allowedKeys = /^[0-9.]+$/;
                                                    if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                                        e.preventDefault();
                                                        setErrorMessage("Please enter a valid price (numbers and up to two decimal places)");
                                                        setWarning(true);
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                {/* Display the sum of Travel cost Total */}
                                <Box style={{ marginTop: 16 }}>
                                    <TravelCostSummary />
                                </Box>
                                <br />
                                <ShippingItemView shippings={shippings} setShipping={setShipping} totalShippingCost={totalShippingCost} />

                            </Box>
                        )}
                        <Box>
                            <br />
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                                        Discount
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <TextField
                                        fullWidth
                                        variant="standard"

                                        label="Cash Discount"
                                        value={discount.cashDiscount ? `-$${discount.cashDiscount}` : ''}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end"> OFF</InputAdornment>,
                                        }}
                                        inputProps={{
                                            style: { textAlign: 'center', color: 'red' },
                                        }}
                                        onChange={(e) => setDiscount({ ...discount, cashDiscount: e.target.value.replace('-$', '') })}
                                        onKeyPress={(e) => {
                                            const allowedKeys = /^[0-9.]+$/;
                                            if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                                e.preventDefault();
                                                setErrorMessage("Please enter a valid discount price (numbers and up to two decimal places)");
                                                setWarning(true);
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Discount"
                                        type="number"
                                        value={discount.percentageDiscount}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">% OFF</InputAdornment>,
                                        }}
                                        inputProps={{
                                            min: 0,
                                            max: 100,
                                            style: { textAlign: 'center', color: 'red' },
                                        }}
                                        onChange={(e) => onDiscountChanged(e.target.value)}
                                        variant="standard"
                                        fullWidth

                                        onKeyPress={(e) => {
                                            const allowedKeys = /^[0-9]+$/;
                                            if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                                e.preventDefault();
                                                setErrorMessage("Please enter a number.");
                                                setWarning(true);
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <br />
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                                Cost Summary:
                            </Box>
                            <Box>
                                <TotalCostSummary />
                            </Box>
                        </Box>
                        <Box>
                            <br />
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                                Machine Information:
                            </Box>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <Box>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth required>
                                                <InputLabel shrink>{"Number of Machines"}</InputLabel>
                                                <Select
                                                    label={"Number of Machines"}
                                                    value={numMachineNumbers}
                                                    onChange={handleMachineNumbersChange}
                                                    labelId="num-part-numbers-select-label"
                                                >
                                                    {Array.from({ length: 10 }, (_, i) => i + 1).map((num) => (
                                                        <MenuItem key={num} value={num}>
                                                            {num}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Box>
                                </Grid>
                                {Array.from({ length: numMachineNumbers }).map((_, index) => (
                                    <React.Fragment key={index}>
                                        <Grid item xs={12} sm={6} sx={{ marginTop: "16px" }}>
                                            <ModelDropdown
                                                name={`Machine Model-${index}`}
                                                required
                                                selectedRegion={selectedRegion}
                                                value={machineModels[index]?.machineModelValue || ''}
                                                onChange={(e) => {
                                                    const newMachineModels = [...machineModels];
                                                    newMachineModels[index] = {
                                                        machineID: `Machine Model-${index}`,
                                                        machineModelValue: e.target.value
                                                    };
                                                    setMachineModels(newMachineModels);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label="Machine Serial"
                                                variant="outlined"
                                                margin="normal"
                                                name={`Serial-${index}`}
                                                value={serials[index]?.serialValue || ''}
                                                onChange={(e) => {
                                                    const newSerials = [...serials];
                                                    newSerials[index] = {
                                                        serialID: `Serial-${index}`,
                                                        serialValue: e.target.value
                                                    }
                                                    setSerials(newSerials); // Update the state
                                                }}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                ))}
                            </Grid>
                        </Box>
                        <br />
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                            Sender Information
                        </Box>
                        <Box>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Quoted By"
                                        variant="outlined"
                                        margin="normal"
                                        value={senderInformation.senderName}
                                        onChange={(e) => setSenderImformation({ ...senderInformation, senderName: e.target.value })}
                                        onKeyPress={(e) => {
                                            const allowedKeys = /^[A-Za-z ]+$/;
                                            if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Contact Number"
                                        variant="outlined"
                                        margin="normal"
                                        value={senderInformation.senderContact}
                                        onChange={(e) => setSenderImformation({ ...senderInformation, senderContact: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Send PO To"
                                        variant="outlined"
                                        margin="normal"
                                        value={senderInformation.orderToEmail}
                                        onChange={(e) => setSenderImformation({ ...senderInformation, orderToEmail: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Quotation Version"
                                        variant="outlined"
                                        margin="normal"
                                        value={senderInformation.version}
                                        onChange={(e) => setSenderImformation({ ...senderInformation, version: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <br />
                                    <TextField
                                        label="Note"
                                        multiline
                                        value={note}
                                        rows={4}
                                        variant="outlined"
                                        onChange={(e) => setNote(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={onSubmit} variant="contained" color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            <ErrorDialog
                open={!!warning}
                onClose={handleWarningClose}
                errorMessage={errorMessage}
            />
        </>

    );
}

export default QuotationView;