import React from 'react';
import { LinearProgress, Box, Typography } from '@mui/material';
import { GetTextByRegion } from '../hooks/Translation';

const StatusProgressBar = ({ status, component, selectedRegion, }) => {
  // Map status to progress value
  const getProgressInfo = (status, component) => {
    if (component === 'machine') {
      if (status.startsWith('On Floor') || status.startsWith('Packing')) {
        return { value: 50, color: 'orange' };
      }
      if (status === 'Packed') {
        return { value: 75, color: 'green' };
      }
      if (status === 'Shipped' || status.startsWith('Installed')) {
        return { value: 75, color: 'green' };
      }
      if (status === 'Disposed'){
        return { value: 100, color: 'red' };
      }
      switch (status) {
        case 'On Order':
          return { value: 0, color: 'grey' };
        case 'In Crate':
          return { value: 25, color: 'blue' };
        case 'Completed':
          return { value: 100, color: 'darkgreen' };
        default:
          return { value: 0, color: 'lightgrey' };
      }
    } else if (component === 'itemrequest') {
      switch (status) {
        case 'completed':
          return { value: 100, color: 'green' };
        case 'finished':
          return { value: 80, color: 'purple' };
        case 'approved':
          return { value: 60, color: 'blue' };
        case 'in process':
          return { value: 60, color: 'blue' };
        case 'pending':
          return { value: 40, color: 'orange' };
        case 'submitted':
          return { value: 20, color: 'yellow' };
        case '':
          return { value: 0, color: 'lightgrey' };
        default:
          return { value: 0, color: 'lightgrey' };
      }
    } else if (component === 'chinaproduction') {
      switch (status) {
        case 'QC Release':
          return { value: 100, color: 'green' };
        case 'Re-work':
          return { value: 90, color: 'purple' };
        case 'QA/QC':
          return { value: 75, color: 'blue' };
        case 'Test':
          return { value: 60, color: 'red' };
        case 'Electrical assembly and installation':
          return { value: 45, color: 'pink' };
        case 'Hardware assembly and installation':
          return { value: 30, color: 'orange' };
        case 'Incoming materials pending':
          return { value: 15, color: 'yellow' };
        case '':
          return { value: 0, color: 'lightgrey' };
        default:
          return { value: 0, color: 'lightgrey' };
      }
    } else { // For reports
      switch (status) {
        case 'Completed':
          return { value: 100, color: 'green' };
        case 'Case Closed':
          return { value: 67, color: 'orange' };
        case 'In Process':
          return { value: 33, color: 'blue' };
        case '':
          return { value: 0, color: 'lightgrey' };
        default:
          return { value: 0, color: 'lightgrey' };
      }
    }
  };

  const { value: progress, color: progressColor } = getProgressInfo(status, component);

  // Define the step markers and their positions (in percentage)
  const steps = component === 'machine'
    ? [
      { label: 'On Order', value: 0 },
      { label: 'In Crate', value: 25 },
      { label: 'On Floor', value: 50 },
      { label: 'Packed', value: 75 },
      { label: 'Completed', value: 100 },
      { label: 'Disposed', value: 100 },
    ]
    : component === 'itemrequest'
      ? [
        { label: 'Submitted', value: 20 },
        { label: 'Pending', value: 40 },
        { label: 'Approved', value: 60 },
        { label: 'In process', value: 60 },
        { label: 'Finished', value: 80 },
        { label: 'Completed', value: 100 },
      ]
      : component === 'chinaproduction'
        ? [
          { label: 'Incoming materials pending', value: 15 },
          { label: 'Hardware assembly and installation', value: 30 },
          { label: 'Electrical assembly and installation', value: 45 },
          { label: 'Test', value: 60 },
          { label: 'QA/QC', value: 75 },
          { label: 'Re-work', value: 90 },
          { label: 'QC Release', value: 100 },
        ] : [
          { label: 'In Process', value: 33 },
          { label: 'Case Closed', value: 67 },
          { label: 'Completed', value: 100 },
        ];

  return (
    <Box sx={{ width: '100%', textAlign: 'center', position: 'relative', marginBottom: 2, marginTop: 2.5 }}>
      {/* The progress bar */}
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          height: 10,
          borderRadius: 5,
          backgroundColor: 'lightgrey',
          '& .MuiLinearProgress-bar': {
            backgroundColor: progressColor, // Dynamic color
          },
        }}
      />

      {/* Step lines */}
      {steps.map((step, index) => (
        <Box
          key={index}
          sx={{
            position: 'absolute',
            top: 0,
            left: `${step.value}%`,
            height: '10px',
            width: '2px',
            backgroundColor: step.value <= progress ? 'white' : 'white', // Highlight the line if it's reached
            transform: 'translateX(-50%)',
          }}
        />
      ))}

      {/* Status label */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 1 }}>
        <Typography variant="caption" component="div">
          {GetTextByRegion(`${status}`, selectedRegion)} {component === 'machine' ? '' : `${progress}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default StatusProgressBar;
