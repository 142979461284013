import React, { useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { Button, Box,  IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import SearchBar from '../../SearchBar';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddShippingPartView from './AddShippingPartView';
import EditShippingPartView from './EditShippingPartView';
import { useUser } from '../../../hooks/UserContext';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const ShippingItemView = ({ shippings,setShipping,totalShippingCost }) => {

    const { selectedRegion, user: { username } } = useUser();
    const [searchShippingQuery, setSearchShippingQuery] = useState('');
    const [editShippingPartView, setEditShippingPartView] = useState(false);
    const [addShippingPartView, setAddShippingPartView] = useState(false);
    const [requestedShippingPart, setRequestedShippingPart] = useState({
        partnumber: '',
        quantity: '',
        sizeWeight: '',
        via: '',
        description: '',
        total: 0
    });
    const tableContainerRef = useRef(null);
    const fetchItemsforShipping = useCallback(async () => {

        if (searchShippingQuery === "") {
            openAddShippingPartView();
            return;
        }
        try {
            const response = await axios.get(`${backendString}/items/${searchShippingQuery}?selectedRegion=${selectedRegion}`);
            setRequestedShippingPart(
                {
                    partnumber: response.data.partnumber,
                    quantity: '',
                    description: response.data.name,
                    total: '',
                    sizeWeight: '',
                    via: '',
                }
            );

        } catch (error) {

        }
        openAddShippingPartView();
    }, [searchShippingQuery, selectedRegion]);
    const handleEditShippingPart = (partData) => {
        setRequestedShippingPart(partData);
        setEditShippingPartView(true);
    };

    const handleDeleteShippingPart = (partData) => {
        setShipping((prevParts) => prevParts.filter(part => part !== partData));
    };


    const openAddShippingPartView = () => {
        setAddShippingPartView(true);
    }

    const closeAddShippingPartView = () => {
        setAddShippingPartView(false);
    }
    const closeEditShippingPartView = () => {
        setEditShippingPartView(false);
    }
    const ShippingCostSummary = () => {
        return (
            <Box style={{ marginTop: 16, textAlign: 'right' }}>
                <strong>Sub Total: ${totalShippingCost.toFixed(2)}</strong>
            </Box>
        );
    }
    const onShippingPartEditSubmit = (updatedPart) => {

        setShipping((prevParts) =>
            prevParts.map(part =>
                part.partnumber === updatedPart.partnumber
                    ? { ...part, ...updatedPart } // Corrected line
                    : part
            )
        );
    }
    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                Estimated Shipping Costs
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <SearchBar searchQuery={searchShippingQuery} setSearchQuery={setSearchShippingQuery} placeholder="Search Part..." />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ marginLeft: 2, flexGrow: 0 }} // Aligns button to the right and keeps original size
                        onClick={fetchItemsforShipping}
                    >
                        Add Parts
                    </Button>
                </Box>
            </Box>
            <TableContainer
                component={Paper}
                ref={tableContainerRef}
                sx={{
                    mt: 2,
                    maxHeight: 300,
                    overflow: 'auto'
                }}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Part Number</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Size & Weight</TableCell>
                            <TableCell>Ship Via</TableCell>
                            <TableCell>Shipping Charge</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {shippings.map((part, index) => (
                            <TableRow key={index}>
                                <TableCell>{part.partnumber}</TableCell>
                                <TableCell>{part.quantity}</TableCell>
                                <TableCell>{part.description}</TableCell>
                                <TableCell>{part.sizeWeight}</TableCell>
                                <TableCell>{part.via}</TableCell>
                                <TableCell>${part.total}</TableCell>
                                <TableCell>
                                    <IconButton color='primary' onClick={() => { handleEditShippingPart(part) }} >
                                        <EditIcon />
                                    </IconButton>
                                    <Button onClick={() => { handleDeleteShippingPart(part) }}>
                                        <DeleteIcon sx={{ color: 'purple' }} />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box>
                <ShippingCostSummary />
            </Box>
            {addShippingPartView &&
                <AddShippingPartView open={addShippingPartView} onClose={closeAddShippingPartView} requestedPart={requestedShippingPart} setParts={setShipping} setRequestedPart={setRequestedShippingPart} partlist={shippings} />
            }
            {editShippingPartView &&
                <EditShippingPartView open={editShippingPartView} onClose={closeEditShippingPartView} requestedPart={requestedShippingPart} setRequestedPart={setRequestedShippingPart} onEditSubmit={onShippingPartEditSubmit} partlist={shippings} />
            }
        </>
    );
}

export default ShippingItemView;