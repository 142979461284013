import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from '../hooks/UserContext';

const AdminProtectedRoute = () => {
  const {user} = useUser();

  if (!user) {
    return <div>Loading...</div>; // Display a loading indicator while the role is being fetched
  }

  return user.role === 'ADMIN' ? <Outlet /> : <Navigate to="/" />;
};

export default AdminProtectedRoute;
