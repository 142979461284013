import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, IconButton, Grid, Link, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import PreviewIcon from '@mui/icons-material/Visibility';
import DeleteConfirmationDialog from '../components/DeleteConfirmationDialog';
import SearchBar from '../components/SearchBar';
import CustomDataGrid from '../components/CustomDataGrid';
import useWindowSize from '../hooks/useWindowSize';
import FilePreview from '../components/FilePreview';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const BOMView = () => {
  const [open, setOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [boms, setBOMs] = useState([]);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [bomToDelete, setBomToDelete] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [previewFile, setPreviewFile] = useState(null);
  const [previewFileUrl, setPreviewFileUrl] = useState('');
  const { windowSize, smallWidth } = useWindowSize();

  const fetchBOMs = async () => {
    try {
      const response = await axios.get(`${backendString}/BOM/excel`, {
        headers: {
          'Authorization': sessionStorage.getItem('token'),
        },
      });
      setBOMs(response.data || []);
    } catch (error) {
      console.error('Error fetching BOMs:', error);
      setBOMs([]);
    }
  };

  useEffect(() => {
    fetchBOMs();
  }, [boms]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFiles([]);
    setPreviewFile(null); // Clear preview on close
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);

    // Automatically set preview for the first selected file
    if (files.length > 0) {
      setPreviewFile(files[0]);
    }
  };

  const handleUpload = async () => {
    if (selectedFiles.length > 0) {
      const formData = new FormData();
      selectedFiles.forEach(file => formData.append('excel', file));

      try {
        await axios.post(`${backendString}/BOM/excel`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': sessionStorage.getItem('token'),
          },
        });

        await fetchBOMs();
        handleClose();
      } catch (error) {
        console.error('Error uploading files:', error);
      }
    }
  };

  const handleDelete = async () => {
    handleCloseConfirmDeleteDialog();

    if (!bomToDelete) return;

    try {
      await axios.delete(`${backendString}/BOM/excel/${bomToDelete._id}`, {
        headers: {
          'Authorization': sessionStorage.getItem('token'),
        },
      });

      fetchBOMs();
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const handleOpenConfirmDeleteDialog = (bom) => {
    setBomToDelete(bom);
    setConfirmDeleteOpen(true);
  };

  const handleCloseConfirmDeleteDialog = () => {
    setBomToDelete(null);
    setConfirmDeleteOpen(false);
  };

  const handleOpenPreview = async (bom) => {
    try {
      const response = await axios.get(`${backendString}/BOM/excel/${bom._id}`, {
        responseType: 'blob',
        headers: {
          'Authorization': sessionStorage.getItem('token'),
        },
      });

      const url = URL.createObjectURL(response.data);
      setPreviewFileUrl(url);
    } catch (error) {
      console.error('Error fetching file preview:', error);
    }
  };

  // Ensure boms is always an array
  const filteredBOMs = (Array.isArray(boms) ? boms : []).filter((bom) =>
    bom.filename.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const columns = [
    {
      field: 'filename',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => (
        <Link href={`${backendString}/BOM/excel/${params.row._id}`} target="_blank" rel="noopener noreferrer">
          {params.value}
        </Link>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: (params) => (
        <Box display="flex" justifyContent="center">
          {/* <IconButton color="primary" onClick={() => handleOpenPreview(params.row)}>
            <PreviewIcon />
          </IconButton> */}
          <IconButton color="secondary" onClick={() => handleOpenConfirmDeleteDialog(params.row)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box p={3}>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleClickOpen}>
          Add BOM (Excel)
        </Button>
        <div style={{ height: windowSize.height * 0.7, width: windowSize.width * 1 }}>
          <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} placeholder={"Search BOMs..."} />

          {windowSize.width > smallWidth ? (
            <CustomDataGrid
              rows={filteredBOMs}
              columns={columns}
            />
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center">
              {filteredBOMs.map((bom) => (
                <Paper
                  key={bom._id}
                  style={{ width: '90%', margin: '10px', padding: '10px' }}
                >
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body1">
                      <strong>Name:</strong>
                    </Typography>
                    <Link
                      href={`${backendString}/BOM/excel/${bom._id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography variant="body1">
                        {bom.filename}
                      </Typography>
                    </Link>
                    <Box display="flex" justifyContent="flex-end" marginTop="10px">
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenPreview(bom)}
                      >
                        <PreviewIcon />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => handleOpenConfirmDeleteDialog(bom)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Paper>
              ))}
            </Box>
          )}
        </div>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Add BOM</DialogTitle>
          <DialogContent>
            <Grid item xs={12}>
              <Typography variant="body1">Upload Files:</Typography>
              <Button
                variant="contained"
                component="label"
                color="primary"
              >
                Upload Files
                <input
                  type="file"
                  hidden
                  name="excel"
                  onChange={handleFileChange}
                  accept=".xlsx, .xls"
                  multiple
                />
              </Button>
              {selectedFiles.length > 0 && (
                <Typography variant="body2" sx={{ mt: 2 }}>
                  {selectedFiles.map((file, index) => (
                    <div key={index}>
                      Selected File: {file.name}
                    </div>
                  ))}
                </Typography>
              )}

            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleUpload} color="primary" variant="contained">
              Upload
            </Button>
          </DialogActions>
        </Dialog>

        {previewFile && <FilePreview file={previewFile} />}

        <Dialog open={!!previewFileUrl} onClose={() => setPreviewFileUrl('')}>
          <DialogTitle>File Preview</DialogTitle>
          <DialogContent>
            <FilePreview fileUrl={previewFileUrl} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPreviewFileUrl('')} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        
      </Box>
      <DeleteConfirmationDialog 
        open={confirmDeleteOpen} 
        onClose={handleCloseConfirmDeleteDialog} 
        onConfirm={handleDelete} 
      />
    </>
  );
};

export default BOMView;
