import React, { useState, useCallback, useEffect, useRef } from 'react';
import axios from 'axios';
import { Button } from '@mui/material';
import MachineTable from '../components/Machines/MachineTable';
import MachineForm from '../components/Machines/MachineForm';
import roles from '../hooks/Roles';
import ErrorDialog from '../components/ErrorDialog';
import { GetTextByRegion } from '../hooks/Translation';
import { useUser, useValidateRegions } from '../hooks/UserContext';
import isEqual from 'lodash/isEqual';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const Machine = () => {
  const [isMachineFormOpen, setIsMachineFormOpen] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isMachinePopupOpen, setIsMachinePopupOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0); // State for tab selection
  const [allMachines, setAllMachines] = useState([]);
  const [error, setError] = useState(null);
  const [formType, setFormType] = useState("");
  const validateSelectedRegion = ["USA", "MEXICO", "EU", "TAIWAN"];
  const { selectedRegion, user: { userRights }, refreshUser } = useUser();
  useValidateRegions(selectedRegion, validateSelectedRegion);
  const connectionString = backendString + '/machines';
  const prevSelectedRegionRef = useRef();
  useEffect(() => {
    refreshUser().then(() => {
      const currentRegion = selectedRegion;
      if (prevSelectedRegionRef.current && !isEqual(prevSelectedRegionRef.current, currentRegion)) {
        setIsMachineFormOpen(false);
        setOpenEditDialog(false);
        setOpenDeleteDialog(false);
        setIsMachinePopupOpen(false);
        setTabValue(0);
      }
      prevSelectedRegionRef.current = currentRegion;
    });
  }, [refreshUser, selectedRegion, isMachineFormOpen, openEditDialog, openDeleteDialog, isMachinePopupOpen, tabValue]);

  const fetchMachines = useCallback(async () => {
    try {

      const response = await axios.get(`${connectionString}?selectedRegion=${selectedRegion}`);

      setAllMachines(
        response.data
      );

    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.response?.data?.error || 'Error fetching machine data');
    }
  }, [selectedRegion, connectionString]);

  useEffect(() => {
    fetchMachines();
  }, [fetchMachines]);


  const handleOpenPopup = (type) => {
    setFormType(type);
    setIsMachineFormOpen(true);
  };

  const handleClosePopup = () => {
    setIsMachineFormOpen(false);
    setFormType("");
  };

  const handleAddMachine = (newMachine) => {
    setAllMachines([...allMachines, newMachine]);
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  return (
    <div>
      {userRights >= roles['PRODUCTION'] && (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpenPopup("machine")}
            sx={{ mr: 2 }}  // Adds right margin to the first button
          >
            {GetTextByRegion("Add New Machine", selectedRegion)}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpenPopup("rack")}
          >
            {GetTextByRegion("Add New Rack", selectedRegion)}
          </Button>
        </>
      )}

      {isMachineFormOpen && userRights >= roles['PRODUCTION'] && (
        <MachineForm onAdd={handleAddMachine} onClose={handleClosePopup} type={formType} />
      )}
      <MachineTable
        allMachines={allMachines}
        setAllMachines={setAllMachines}
        openEditDialog={openEditDialog}
        setOpenEditDialog={setOpenEditDialog}
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        isMachinePopupOpen={isMachinePopupOpen}
        setIsMachinePopupOpen={setIsMachinePopupOpen}
        tabValue={tabValue}
        setTabValue={setTabValue}
        fetchMachines={fetchMachines}
      />

      {/* Error Dialog */}
      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </div>
  );
};

export default Machine;