import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { GetTextByRegion } from '../../hooks/Translation';

const  SendApprovalConfirmationDialog = ({ open, onClose, onConfirm ,selectedRegion}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{GetTextByRegion("Send Item For Approval",selectedRegion)}</DialogTitle>
      <DialogContent>{GetTextByRegion("Are you sure you want to send this item for approval?",selectedRegion)}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">{GetTextByRegion("Cancel",selectedRegion)}</Button>
        <Button onClick={onConfirm} variant="contained" color="primary">{GetTextByRegion("Confirm",selectedRegion)}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendApprovalConfirmationDialog;