import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { IconButton, Box, Tab, Tabs, Tooltip, Button, Typography } from '@mui/material';
import InventoryIcon from '@mui/icons-material/Inventory';
import ErrorDialog from '../components/ErrorDialog';
import ItemRequestForm from '../components/ItemRequest/ItemRequestForm';
import CustomDataGrid from '../components/CustomDataGrid';
// import ItemRequestDetail from '../components/ItemRequest/ItemRequestDetail';
import useWindowSize from '../hooks/useWindowSize';
import MobileViewComponent from '../components/MobileView';
import itemRequestColumns from '../components/ItemRequest/ItemRequestColumns';
import SearchBar from '../components/SearchBar';
import LoadingSpinner from '../components/LoadingSpinner';
import { GetTextByRegion } from '../hooks/Translation';
import { useUser, useValidateRegions } from '../hooks/UserContext';
import roles from '../hooks/Roles';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const ItemRequestPage = () => {
  const [approvedRequests, setApprovedRequests] = useState([]);
  const [completedRequests, setCompletedRequests] = useState([]);
  const [filteredApprovedRequests, setFilteredApprovedRequests] = useState([]);
  const [filteredCompletedRequests, setFilteredCompletedRequests] = useState([]);
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  // const [detailDialogOpen, setDetailDialogOpen] = useState(false);
  const [error, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [tabValue, setTabValue] = useState(0); // State for tab selection
  const { windowSize, smallWidth } = useWindowSize();
  const [searchQuery, setSearchQuery] = useState(''); // State for search query
  const [loading, setLoading] = useState(false);
  const validateSelectedRegion = ["CHINA"];
  const { selectedRegion, refreshUser, user: { userRights } } = useUser();
  useValidateRegions(selectedRegion, validateSelectedRegion);

  useEffect(() => {
    refreshUser();
  }, [refreshUser, formDialogOpen, tabValue]);

  const fetchItemRequests = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(backendString + `/item-requests?selectedRegion=${selectedRegion}`);
      setApprovedRequests(response.data.filter(request => request.status === 'in process'));
      setCompletedRequests(response.data.filter(request => request.status === 'finished'));
    } catch (error) {
      setError(error.response?.data?.error);
    }
    finally {
      setLoading(false);
    }
  }, [selectedRegion]);

  useEffect(() => {
    fetchItemRequests();
  }, [fetchItemRequests, selectedRegion]);

  useEffect(() => {
    setFilteredApprovedRequests(
      approvedRequests.filter(request =>
        request.partNumber.some(part => part.toLowerCase().includes(searchQuery.toLowerCase())) ||
        request.customerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        request.model.toLowerCase().includes(searchQuery.toLowerCase()) ||
        request.serial.toLowerCase().includes(searchQuery.toLowerCase())
        // request.region.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredCompletedRequests(
      completedRequests.filter(request =>
        request.partNumber.some(part => part.toLowerCase().includes(searchQuery.toLowerCase())) ||
        request.customerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        request.model.toLowerCase().includes(searchQuery.toLowerCase()) ||
        request.serial.toLowerCase().includes(searchQuery.toLowerCase())
        // request.region.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, approvedRequests, completedRequests]);


  const handleCloseErrorDialog = () => {
    setError(null);
  };

  const handleCloseFormDialog = () => {
    setFormDialogOpen(false);
    setSelectedItem(null);
  };

  // const handleCloseDetailDialog = () => {
  //   setSelectedItem(null);
  //   setDetailDialogOpen(false);
  // };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleEdit = (item) => {
    setSelectedItem(item.row);
    setFormDialogOpen(true);
  };

  const exportToExcel = () => {
    const exportRequests = tabValue === 0 ? filteredApprovedRequests : filteredCompletedRequests;
    const dataToExport = exportRequests.map(request => {
      const { _id, submittedDate, partNumber, quantity, ...rest } = request;
      const formattedPartNumber = Array.isArray(partNumber) ? partNumber.join(", ") : partNumber;
      const formattedQuantity = Array.isArray(quantity) ? quantity.join(", ") : quantity;
      return {
        submittedDate: submittedDate,
        partNumber: formattedPartNumber,
        quantity: formattedQuantity,
        ...rest,
      };
    });
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'chinaRequests.xlsx');
  };

  const exportToCSV = () => {
    const exportRequests = tabValue === 0 ? filteredApprovedRequests : filteredCompletedRequests;
    const dataToExport = exportRequests.map(request => {
      const { _id, ...rest } = request;
      return {
        ...rest,
      };
    });
    const csvData = Papa.unparse(dataToExport);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'chinaRequests.csv';
    link.click();
  };

  const tempcolumns = itemRequestColumns.map(column => ({
    field: column.name, flex: column.flex,
    headerAlign: 'center',
    align: 'center',
    renderHeader: () => (
      <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion(`${column.label}`, selectedRegion)}</Typography>
    ),
  }));

  const columns = [
    ...tempcolumns,
    {
      field: 'actions',
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Actions', selectedRegion)}</Typography>
      ),
      width: 130,
      renderCell: (params) => (
        <div>
          {/* <IconButton aria-label="inventory" onClick={() => handleOpenDetailDialog(params)}>
            <InventoryIcon />
          </IconButton> */}
          <Tooltip title={GetTextByRegion("View", selectedRegion)} arrow>
            <IconButton aria-label="edit" color="default" onClick={() => handleEdit(params)}>
              <InventoryIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div style={{ height: windowSize.height * 0.70, width: windowSize.width }}>
      {loading && <LoadingSpinner />}

      {userRights >= roles['SERVICE'] && (
        <div style={{
          display: 'flex',
          flexDirection: 'row', // Stack buttons in column for mobile
          position: windowSize.width > smallWidth ? 'absolute' : 'relative', // Absolute on larger screens
          left: windowSize.width > smallWidth ? 0 : 'auto', // Align left on larger screens
        }}>
          <Button onClick={exportToCSV} sx={{ marginRight: 1 }}>
            {GetTextByRegion("Export to CSV", selectedRegion)}
          </Button>
          <Button onClick={exportToExcel}>
            {GetTextByRegion("Export to Excel", selectedRegion)}
          </Button>
        </div>)}

      <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} placeholder={GetTextByRegion("Search Requests...", selectedRegion)} />

      <Box sx={{ width: '100%' }}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label={GetTextByRegion("Approved", selectedRegion)} />
          <Tab label={GetTextByRegion("Completed", selectedRegion)} />
        </Tabs>

        <div style={{ height: windowSize.height * 0.70, width: windowSize.width * 1 }}>
          {windowSize.width > smallWidth ? (
            tabValue === 0 ? (
              <CustomDataGrid rows={filteredApprovedRequests} columns={columns} />
            ) : (
              <CustomDataGrid rows={filteredCompletedRequests} columns={columns} />
            )
          ) : (
            <MobileViewComponent
              items={
                tabValue === 0 ? filteredApprovedRequests :
                  filteredCompletedRequests
              }
              columnData={itemRequestColumns}
              // handleEditClick={handleEdit}
              handleView={handleEdit}
              selectedRegion={selectedRegion}
            />
          )}
        </div>
      </Box>

      {formDialogOpen && (
        <ItemRequestForm
          open={formDialogOpen}
          onClose={handleCloseFormDialog}
          onRequestSubmit={fetchItemRequests}
          onRequestSave={fetchItemRequests}
          editItem={selectedItem}
          tabValue={tabValue}
          isChina={true}
        />
      )}


      {/* {selectedItem && (
        <ItemRequestDetail
          open={detailDialogOpen}
          onClose={handleCloseDetailDialog}
          item={selectedItem}
          isSubmitted={selectedItem.status === 'submitted' ? true : false}
          isPending={selectedItem.status === 'pending' ? true : false}
          isCompleted={true}
        />
      )} */}

      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </div>
  );
};

export default ItemRequestPage;
