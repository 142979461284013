import React, { useEffect, useState, useCallback } from 'react';
import ZingChart from 'zingchart-react';
import axios from 'axios';
import {GetTextByRegion} from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const BarGraph = () => {
  const [chartData, setChartData] = useState({ models: [], series: [] });
  const {selectedRegion} = useUser();

  // Function to process data
  const processChartData = useCallback((data) => {
    // Aggregate data by model and issue type
    const issueCounts = data.reduce((acc, item) => {
      const { model, issueType } = item;  // Adjust based on your data structure
      if (!acc[model]) {
        acc[model] = { Hardware: 0, Software: 0 };
      }

      if (issueType.startsWith("Software:")) {
        acc[model].Software += 1;
      } else if (issueType.startsWith("Hardware:")) {
        acc[model].Hardware += 1;
      }
      return acc;
    }, {});

    // Separate data into models and issue types
    const models = Object.keys(issueCounts);
    const series = [
      {
        text: GetTextByRegion("Hardware", selectedRegion),
        values: models.map(model => issueCounts[model].Hardware || 0),
      },
      {
        text: GetTextByRegion("Software", selectedRegion),
        values: models.map(model => issueCounts[model].Software || 0),
      },
    ];

    return { models, series };
  }, [selectedRegion]);

  useEffect(() => {
    // Fetch data from the remoteservice endpoint
    axios.get(`${backendString}/remoteservices`)
      .then(response => {
        const data = response.data;
        // Process data to get the necessary information for the bar graph
        const processedData = processChartData(data);
        setChartData(processedData);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [processChartData]);

  const config = {
    type: 'bar',
    title: {
      text: GetTextByRegion('Issue Types by Model',selectedRegion),
      fontSize: 18,
      fontFamily: 'Arial',
      color: '#333',
      backgroundColor: 'transparent',
      align: 'center',
      marginTop: '30px', // Margin between the title and the chart
    },
    scaleX: {
      label: { text: GetTextByRegion('Models',selectedRegion) },
      labels: chartData.models,  // X-axis labels
    },
    scaleY: {
      label: { text: GetTextByRegion('Number of Issues',selectedRegion) },
    },
    series: chartData.series,
    plot: {
      tooltip: {
        text: '%t (%x): %v', // Tooltip content
      },
      valueBox: {
        text: '%v', // Show values on top of bars
      },
    },
    legend: {
      // Enable and configure the legend
      visible: true,
      layout: 'horizontal',
      align: 'center',
      valign: 'bottom',
      borderWidth: 0,
      marker: {
        type: 'square',
      },
      item: {
        fontColor: '#333',
        fontSize: 14,
      },
      marginTop: '70px', // Margin between the chart and the legend
    },
  };

  return <ZingChart data={config} />;
};

export default BarGraph;
