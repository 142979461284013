import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import CancelUnfilled from '@mui/icons-material/HighlightOffSharp';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';

const IconRejectUnfilledButton = ({onUpdateStatus}) => {
    const { selectedRegion } = useUser();

    return (
        <Tooltip title={GetTextByRegion("Reject", selectedRegion)} arrow>
            <IconButton color="error" onClick={onUpdateStatus}>
                <CancelUnfilled />
            </IconButton>
        </Tooltip>
    );
}

export default IconRejectUnfilledButton;
