import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, IconButton, Grid, Link, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteConfirmationDialog from '../components/DeleteConfirmationDialog';
import SearchBar from '../components/SearchBar';
import CustomDataGrid from '../components/CustomDataGrid';
import useWindowSize from '../hooks/useWindowSize';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const ExplosionView = () => {
  const [open, setOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [explosions, setExplosions] = useState([]);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [explosionToDelete, setExplosionToDelete] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const { windowSize, smallWidth } = useWindowSize();

  const fetchExplosions = async () => {
    try {
      const response = await axios.get(`${backendString}/Explosion/pdf`, {
        headers: {
          'Authorization': sessionStorage.getItem('token'),
        },
      });
      setExplosions(response.data || []);
    } catch (error) {
      console.error('Error fetching Explosions:', error);
      setExplosions([]);
    }
  };

  useEffect(() => {
    fetchExplosions();
  }, [explosions]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFiles([]);
  };

  const handleFileChange = (event) => {
    setSelectedFiles(Array.from(event.target.files));
  };

  const handleUpload = async () => {
    if (selectedFiles.length > 0) {
      const formData = new FormData();
      selectedFiles.forEach(file => formData.append('pdf', file));

      try {
        await axios.post(`${backendString}/Explosion/pdf`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': sessionStorage.getItem('token'),
          },
        });

        await fetchExplosions();
        handleClose();
      } catch (error) {
        console.error('Error uploading files:', error);
      }
    }
  };

  const handleDelete = async () => {
    handleCloseConfirmDeleteDialog();

    if (!explosionToDelete) return;

    try {
      await axios.delete(`${backendString}/Explosion/pdf/${explosionToDelete._id}`, {
        headers: {
          'Authorization': sessionStorage.getItem('token'),
        },
      });

      await fetchExplosions();
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const handleOpenConfirmDeleteDialog = (explosion) => {
    setExplosionToDelete(explosion);
    setConfirmDeleteOpen(true);
  };

  const handleCloseConfirmDeleteDialog = () => {
    setExplosionToDelete(null);
    setConfirmDeleteOpen(false);
  };

  // Filter explosions based on searchQuery
  const filteredExplosions = (Array.isArray(explosions) ? explosions : []).filter((explosion) =>
    explosion.filename.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const columns = [
    {
      field: 'filename',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => (
        <Link href={`${backendString}/Explosion/pdf/${params.row._id}`} target="_blank" rel="noopener noreferrer">
          {params.value}
        </Link>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: (params) => (
        <IconButton color="secondary" onClick={() => handleOpenConfirmDeleteDialog(params.row)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <Box p={3}>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleClickOpen}>
          Add Explosion (PDF)
        </Button>
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} placeholder={"Search Explosions..."} />
        <div style={{ height: windowSize.height * 0.7, width: windowSize.width * 1 }}>
          {windowSize.width > smallWidth ? (
            <CustomDataGrid
              rows={filteredExplosions}
              columns={columns}
            />
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center">
                {filteredExplosions.map((explosion) => (
                  <Paper
                    key={explosion._id}
                    style={{ width: '90%', margin: '10px 0', padding: '10px' }}
                  >
                    <Box display="flex" flexDirection="column">
                    <Typography variant="body1">
                        <strong>Name:</strong>
                      </Typography>
                    <Link
                        href={`${backendString}/Explosion/pdf/${explosion._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                      <Typography variant="body1">
                        {explosion.filename}
                      </Typography>
                      </Link>
                      <Box display="flex" justifyContent="flex-end" marginTop="10px">
                        <IconButton
                          color="secondary"
                          onClick={() => handleOpenConfirmDeleteDialog(explosion)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </Paper>
                ))}
              </Box>
          )}
        </div>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Add Explosion</DialogTitle>
          <DialogContent>
            <Grid item xs={12}>
              <Typography variant="body1">Upload Files:</Typography>
              <Button
                variant="contained"
                component="label"
                color="primary"
              >
                Upload Files
                <input
                  type="file"
                  hidden
                  name="pdf"
                  onChange={handleFileChange}
                  accept=".pdf"
                  multiple
                />
              </Button>
              {selectedFiles.length > 0 && (
                <Typography variant="body2" sx={{ mt: 2 }}>
                  {selectedFiles.map((file, index) => (
                    <div key={index}>
                      Selected File: {file.name}
                    </div>
                  ))}
                </Typography>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleUpload} color="primary" variant="contained">
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <DeleteConfirmationDialog 
        open={confirmDeleteOpen} 
        onClose={handleCloseConfirmDeleteDialog} 
        onConfirm={handleDelete} 
      />
    </>
  );
};

export default ExplosionView;
