import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import scienscopelogo from '../../images/scienscopeLogo.png';

// Function to add footer to the current page
function addFooter(doc, text, versionText) {
    const footerText = text;
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;
    const marginBottom = 10; // margin from the bottom
    doc.setFontSize(9);
    doc.text(footerText, pageWidth / 2 - doc.getTextWidth(text) / 2, pageHeight - marginBottom);
    doc.text(versionText, pageWidth - marginBottom - doc.getTextWidth(versionText), pageHeight - marginBottom);
}

// function formatDate(dateString) { //THIS ONE MESSES UP FORMAT WITH DATE BECAUSE OF TIME ZONE ISSUES
//     console.log(dateString);
//     const date = new Date(dateString);
//     console.log(date);
//     const month = String(date.getMonth() + 1).padStart(2, '0');
//     const day = String(date.getDate()).padStart(2, '0');
//     const year = String(date.getFullYear()).slice(-2);
//     // Concatenate to form the desired format
//     return `${month}${day}${year}`;
// }
function formatDate(dateString) {
    const [year, month, day] = dateString.split("-");
    // Construct date with components to avoid timezone issues
    const formattedDate = new Date(Number(year), Number(month) - 1, Number(day));
    const formattedMonth = String(formattedDate.getMonth() + 1).padStart(2, '0');
    const formattedDay = String(formattedDate.getDate()).padStart(2, '0');
    const formattedYear = String(formattedDate.getFullYear()).slice(-2);
    return `${formattedMonth}${formattedDay}${formattedYear}`;
}

function sanitizeFileName(str) {
    return str.replace(/[\/\\:*?"<>|]/g, ' ');
}

const generateQuotationPDF = (data) => {

    const doc = new jsPDF();

    // page settings
    const pageMargin = 10;
    const pageWidth = doc.internal.pageSize.width;
    //let x1 = pageMargin;
    let y1 = pageMargin + 13;

    const tableWidth = pageWidth - 2 * pageMargin;

    const blackColorCode = [0, 0, 0];
    const whiteColorCode = [255, 255, 255];
    const greyColorCode = [200, 200, 200];
    const yellowColorCode = [250, 240, 140];

    //required data for this form
    const version = " " + data.senderInformation.version;
    const formVersion = "V2.0 23.03.15";
    //basic information/////
    const Attn = data.attn;
    const CustomerName = data.customer;
    const QuoteDate = data.quoteDate;
    const Street = data.street;
    const CSZip = data.city + "/" + data.state + "/" + data.zip;
    const phone = data.phone;
    const paymentTerm = data.paymentTerms;
    const email = data.email;
    const quoteValid = data.qouteValid;
    const summitedby = data.senderInformation.senderName;
    const QuotationNumber = (data.serviceType === 'Service'
        ? `XRSV-${formatDate(QuoteDate)}-${summitedby}-${CustomerName} ${version}`
        : `XRPM-${formatDate(QuoteDate)}-${summitedby}-${CustomerName} ${version}`
    ).toUpperCase();
    const sanitizedCustomerName = sanitizeFileName(QuotationNumber);
    const machineModels = data.machineModels;
    const machineSerials = data.serials;
    //service costs
    let purchaseServices = [];
    let totalServiceCost = 0;
    if (data.serviceType === "PM") {
        purchaseServices = data.services;
        totalServiceCost = data.totalServiceCost;
    }
    //part costs
    let purchaseParts = [];
    let totalPartCost = 0;
    ///Travel costs////////////////////////
    let Miscellaneous = 0;
    let MiscellaneousPricePerPerson = 0;
    let airlineCost = 0;
    let diemPricePerDay = 0;
    let hotelPricePerNight = 0;
    let hotelStayDays = 0;
    let mileage = 0;
    let perDiemDays = 0;
    let pricePerMile = 0;
    let rentalCarDays = 0;
    let rentalCarPricePerDay = 0;
    let travelHours = 0;
    let travelPricePerHour = 0;
    let travelerNumber = 0;
    let totalTravelCost = 0;
    //shipping costs
    let shippings = [];
    let totalShippingCost = 0;
    //discount
    let caseDiscount = 0;
    let percentageDiscount = 0;
    let totalPercentageDiscount = 0;
    if (data.serviceType === "Service") {
        //part costs
        purchaseParts = data.parts;
        totalPartCost = data.totalPartCost;
        ///Travel costs////////////////////////
        Miscellaneous = data.travelCosts.Miscellaneous;
        MiscellaneousPricePerPerson = data.travelCosts.MiscellaneousPricePerPerson;
        airlineCost = data.travelCosts.airlineCost;
        diemPricePerDay = data.travelCosts.diemPricePerDay;
        hotelPricePerNight = data.travelCosts.hotelPricePerNight;
        hotelStayDays = data.travelCosts.hotelStayDays;
        mileage = data.travelCosts.mileage;
        perDiemDays = data.travelCosts.perDiemDays;
        pricePerMile = data.travelCosts.pricePerMile;
        rentalCarDays = data.travelCosts.rentalCarDays;
        rentalCarPricePerDay = data.travelCosts.rentalCarPricePerDay;
        travelHours = data.travelCosts.travelHours;
        travelPricePerHour = data.travelCosts.travelPricePerHour;
        travelerNumber = data.travelCosts.travelerNumber;
        totalTravelCost = data.totalTravelCost;
        //shipping cost
        shippings = data.shippings;
        totalShippingCost = data.totalShippingCost;
    }

    if (data.discount.cashDiscount !== "") {
        caseDiscount = parseFloat(data.discount.cashDiscount).toFixed(2);
    }
    if (data.discount.percentageDiscount !== "") {
        percentageDiscount = data.discount.percentageDiscount
    }
    if (data.discount.totalPercentageDiscount !== "") {
        totalPercentageDiscount = parseFloat(data.discount.totalPercentageDiscount).toFixed(2);
    }

    //total
    const totalCost = totalServiceCost + totalPartCost + totalTravelCost + totalShippingCost;
    ///sender information
    //const summitedby = data.senderInformation.senderName;
    const cell = data.senderInformation.senderContact;
    const serviceline = "";
    const techsupportemail = "service@scienscope.com";
    const emailPOto = data.senderInformation.orderToEmail;
    const note = data.note;
    const terms = [
        ['a.', "A purchase order issued to Scienscope International is required before service can be scheduled."],
        ['b.', "The full amount of the contract must be paid before the deployment of the first service."],
        ['c.', "Prices quoted are subject to sales tax, where applicable."],
        ['d.', "Prices apply to services during non-holiday seasons. Additional charges may apply during holidays."],
        ['e.', "Service will be scheduled within 2-3 weeks of payment."],
        ['f.', "Expedited services may be scheduled based on availability. Additional charges may apply."],
        ['g.', "This quote is for preventive maintenance only."],
        ['h.', "For other field, application, or training services, please contact Scienscope for a separate quote."]
    ];

    /*//////////////////////////////////////////////////     scienscope logo image      ////////////////////////////////////////////////////*/
    const imgData = new Image();
    imgData.src = scienscopelogo;
    const imageWidth = 60;
    const imageHeight = 20;
    doc.addImage(imgData, 'PNG', pageMargin + 10, pageMargin, imageWidth, imageHeight);


    //format the service items
    const purchaseServiceList = purchaseServices.map(row => [
        { content: row.itemNumber, styles: { halign: 'center' } },
        { content: row.quantity, styles: { halign: 'center' } },
        { content: row.description, colSpan: 3 },
        { content: "$" + parseFloat(row.cost).toFixed(2), styles: { halign: 'center' } },
        { content: "$" + parseFloat(row.total).toFixed(2), styles: { halign: 'center' } },
    ]);
    //format the part items
    const purchasePartList = purchaseParts.map(row => [
        { content: row.partnumber, styles: { halign: 'center' } },
        { content: row.quantity, styles: { halign: 'center' } },
        { content: row.description, colSpan: 3 },
        { content: "$" + parseFloat(row.price).toFixed(2), styles: { halign: 'center' } },
        { content: "$" + parseFloat(row.total).toFixed(2), styles: { halign: 'center' } },
    ]);

    //format the terms
    const termsList = terms.map(row => [
        { content: row[0], styles: { halign: 'left' } },
        { content: row[1], styles: { halign: 'left' }, colSpan: 6 },
    ]);

    //format the shippings
    const shippingList = shippings.map(row => [
        { content: row.partnumber, colSpan: 1, styles: { halign: 'center' } },
        { content: row.quantity, colSpan: 1, styles: { halign: 'center' } },
        { content: row.description, colSpan: 2, styles: { halign: 'center' } },
        { content: row.sizeWeight, colSpan: 1, styles: { halign: 'center' } },
        { content: row.via, colSpan: 1, styles: { halign: 'center' } },
        { content: `$${parseFloat(row.total).toFixed(2)}`, colSpan: 1, styles: { halign: 'center' } },
    ]);


    //Empty headers
    const headers = [];
    // Create body rows 
    const body = [];

    body.push(
        [
            { content: 'Quotation #', styles: { halign: 'left', fillColor: whiteColorCode, lineWidth: 0, fontStyle: 'bold' } },
            { content: QuotationNumber, colSpan: 3, styles: { halign: 'left', fillColor: whiteColorCode, lineWidth: 0, fontStyle: 'bold' } },
            data.serviceType === "Service"
                ? { content: 'Service', colSpan: 3, styles: { halign: 'left', fillColor: whiteColorCode, lineWidth: 0, fontStyle: 'bold' } }
                : { content: 'Preventative Maintenance', colSpan: 3, styles: { halign: 'left', fillColor: whiteColorCode, lineWidth: 0, fontStyle: 'bold' } }
        ],
        ['Attn:', { content: Attn, colSpan: 3 }, 'Quote Date:', { content: QuoteDate, colSpan: 2 }],
        ['Customer:', { content: CustomerName, colSpan: 3 }, '', { content: '', colSpan: 2 }],
        ['Street:', { content: Street, colSpan: 3 }, 'Quote Valid:', { content: quoteValid, colSpan: 2 }],
        ['C/S/Zip:', { content: CSZip, colSpan: 3 }, '', { content: '', colSpan: 2 }],
        ['Phone:', { content: phone, colSpan: 3 }, 'Payment Term:', { content: paymentTerm, colSpan: 2 }],
        ['Email:', { content: email, colSpan: 3 }, '', { content: '', colSpan: 2 }]
    );

    if (data.serviceType === 'PM') {
        body.push(
            [{ content: '', colSpan: 7 }], // Empty row for spacing      
            [{ content: 'Item Number', styles: { halign: 'center', fillColor: greyColorCode } },
            { content: 'Qty', styles: { halign: 'center', fillColor: greyColorCode } },
            { content: 'Description', colSpan: 3, styles: { halign: 'center', fillColor: greyColorCode } },
            { content: 'Unit Price', styles: { halign: 'center', fillColor: greyColorCode } },
            { content: 'Unit Total', styles: { halign: 'center', fillColor: greyColorCode } }
            ]
        );
        body.push(...purchaseServiceList);
        body.push(
            [
                { content: '', colSpan: 5, styles: { halign: 'center', fillColor: greyColorCode } },
                { content: 'Sub Total:', colSpan: 1, styles: { halign: 'center', fillColor: greyColorCode } },
                { content: `$${totalServiceCost.toFixed(2)}`, colSpan: 1, styles: { halign: 'center', fontStyle: 'bold' } }
            ],
        );
    }
    if (data.serviceType === 'Service') {
        body.push(
            [{ content: '', colSpan: 7 }], // Empty row for spacing      
            [{ content: 'Service Charges', colSpan: 7, styles: { halign: 'left', fillColor: greyColorCode } }], // Empty row for spacing      
            [{ content: 'Item Number', styles: { halign: 'center', fillColor: greyColorCode } },
            { content: 'Qty', styles: { halign: 'center', fillColor: greyColorCode } },
            { content: 'Description', colSpan: 3, styles: { halign: 'center', fillColor: greyColorCode } },
            { content: 'Unit Price', styles: { halign: 'center', fillColor: greyColorCode } },
            { content: 'Unit Total', styles: { halign: 'center', fillColor: greyColorCode } }
            ]
        );
        body.push(...purchasePartList);
        body.push(
            [
                { content: '', colSpan: 5, styles: { halign: 'center', fillColor: greyColorCode } },
                { content: 'Sub Total:', colSpan: 1, styles: { halign: 'center', fillColor: greyColorCode } },
                { content: `$${totalPartCost.toFixed(2)}`, colSpan: 1, styles: { halign: 'center', fontStyle: 'bold' } }
            ],
        );
        body.push(
            [{ content: '', colSpan: 7 }], // Empty row for spacing      
            [{ content: "Estimated Travel Costs:", colSpan: 7, styles: { halign: 'left', fillColor: greyColorCode } }],
            [
                { content: "Travel:", colSpan: 1, styles: { halign: 'left', fillColor: whiteColorCode } },
                { content: travelerNumber, colSpan: 1, styles: { halign: 'center', fillColor: whiteColorCode } },
                { content: "Airline Ticket:", colSpan: 3, styles: { halign: 'left', fillColor: whiteColorCode } },
                { content: "$" + parseFloat(airlineCost).toFixed(2), colSpan: 1, styles: { halign: 'center', fillColor: whiteColorCode } },
                { content: "$" + (airlineCost * travelerNumber).toFixed(2), colSpan: 1, styles: { halign: 'center', fillColor: whiteColorCode } },

            ],
            [
                { content: "Per Diem:", colSpan: 1, styles: { halign: 'left', fillColor: whiteColorCode } },
                { content: perDiemDays, colSpan: 1, styles: { halign: 'center', fillColor: whiteColorCode } },
                { content: "Per Day-Depending on the destination", colSpan: 3, styles: { halign: 'left', fillColor: whiteColorCode } },
                { content: "$" + parseFloat(diemPricePerDay).toFixed(2), colSpan: 1, styles: { halign: 'center', fillColor: whiteColorCode } },
                { content: "$" + (perDiemDays * diemPricePerDay).toFixed(2), colSpan: 1, styles: { halign: 'center', fillColor: whiteColorCode } },

            ],
            [
                { content: "Rental Car:", colSpan: 1, styles: { halign: 'left', fillColor: whiteColorCode } },
                { content: rentalCarDays, colSpan: 1, styles: { halign: 'center', fillColor: whiteColorCode } },
                { content: "Per Day", colSpan: 3, styles: { halign: 'left', fillColor: whiteColorCode } },
                { content: "$" + parseFloat(rentalCarPricePerDay).toFixed(2), colSpan: 1, styles: { halign: 'center', fillColor: whiteColorCode } },
                { content: "$" + (rentalCarDays * rentalCarPricePerDay).toFixed(2), colSpan: 1, styles: { halign: 'center', fillColor: whiteColorCode } },
            ],
            [
                { content: "Hotel:", colSpan: 1, styles: { halign: 'left', fillColor: whiteColorCode } },
                { content: hotelStayDays, colSpan: 1, styles: { halign: 'center', fillColor: whiteColorCode } },
                { content: "Per Night", colSpan: 3, styles: { halign: 'left', fillColor: whiteColorCode } },
                { content: "$" + parseFloat(hotelPricePerNight).toFixed(2), colSpan: 1, styles: { halign: 'center', fillColor: whiteColorCode } },
                { content: "$" + (hotelStayDays * hotelPricePerNight).toFixed(2), colSpan: 1, styles: { halign: 'center', fillColor: whiteColorCode } },
            ],
            [
                { content: "Local Trans:", colSpan: 1, styles: { halign: 'left', fillColor: whiteColorCode } },
                { content: mileage, colSpan: 1, styles: { halign: 'center', fillColor: whiteColorCode } },
                { content: "Per Mile", colSpan: 3, styles: { halign: 'left', fillColor: whiteColorCode } },
                { content: "$" + parseFloat(pricePerMile).toFixed(2), colSpan: 1, styles: { halign: 'center', fillColor: whiteColorCode } },
                { content: "$" + (mileage * pricePerMile).toFixed(2), colSpan: 1, styles: { halign: 'center', fillColor: whiteColorCode } },
            ],
            [
                { content: "Travel Time:", colSpan: 1, styles: { halign: 'left', fillColor: whiteColorCode } },
                { content: travelHours, colSpan: 1, styles: { halign: 'center', fillColor: whiteColorCode } },
                { content: "Per Hour", colSpan: 3, styles: { halign: 'left', fillColor: whiteColorCode } },
                { content: "$" + parseFloat(travelPricePerHour).toFixed(2), colSpan: 1, styles: { halign: 'center', fillColor: whiteColorCode } },
                { content: "$" + (travelHours * travelPricePerHour).toFixed(2), colSpan: 1, styles: { halign: 'center', fillColor: whiteColorCode } },
            ],
            [
                { content: "Miscellaneous:", colSpan: 1, styles: { halign: 'left', fillColor: whiteColorCode } },
                { content: Miscellaneous, colSpan: 1, styles: { halign: 'center', fillColor: whiteColorCode } },
                { content: "Gas for rental cars,tips,etc", colSpan: 3, styles: { halign: 'left', fillColor: whiteColorCode } },
                { content: "$" + parseFloat(MiscellaneousPricePerPerson).toFixed(2), colSpan: 1, styles: { halign: 'center', fillColor: whiteColorCode } },
                { content: "$" + (Miscellaneous * MiscellaneousPricePerPerson).toFixed(2), colSpan: 1, styles: { halign: 'center', fillColor: whiteColorCode } },
            ],
        );
        body.push(
            [
                { content: '', colSpan: 5, styles: { halign: 'center', fillColor: greyColorCode } },
                { content: 'Sub Total:', colSpan: 1, styles: { halign: 'center', fillColor: greyColorCode } },
                { content: `$${totalTravelCost.toFixed(2)}`, colSpan: 1, styles: { halign: 'center', fontStyle: 'bold' } }
            ],
        );

        body.push(
            [{ content: '', colSpan: 7 }], // Empty row for spacing      
            [{ content: "Estimated Shipping Costs:", colSpan: 7, styles: { halign: 'left', fillColor: greyColorCode } }],
            [
                { content: "PN:", colSpan: 1, styles: { halign: 'left', fillColor: greyColorCode } },
                { content: "QTY", colSpan: 1, styles: { halign: 'center', fillColor: greyColorCode } },
                { content: "Description", colSpan: 2, styles: { halign: 'left', fillColor: greyColorCode } },
                { content: "Size & Weight", colSpan: 1, styles: { halign: 'center', fillColor: greyColorCode } },
                { content: "Ship Via", colSpan: 1, styles: { halign: 'center', fillColor: greyColorCode } },
                { content: "Shipping Charge", colSpan: 1, styles: { halign: 'center', fillColor: greyColorCode } },
            ],
        );
        body.push(...shippingList);
        body.push(
            [
                { content: '', colSpan: 5, styles: { halign: 'center', fillColor: greyColorCode } },
                { content: 'Sub Total:', colSpan: 1, styles: { halign: 'center', fillColor: greyColorCode } },
                { content: `$${totalShippingCost.toFixed(2)}`, colSpan: 1, styles: { halign: 'center', fontStyle: 'bold' } }
            ],
        );

    }

    let machineModel = machineModels[0] ? machineModels[0].machineModelValue : "";
    for (let i = 1; i < machineModels.length; i++) {
        machineModel += "," + machineModels[i].machineModelValue;
    }
    let machineSerial = machineSerials[0] ? machineSerials[0].serialValue : "";
    for (let i = 1; i < machineSerials.length; i++) {
        machineSerial += "," + machineSerials[i].serialValue;
    }

    body.push(
        [
            { content: `Machine Info: ${machineModel}, SN: ${machineSerial}`, colSpan: 7, styles: { halign: 'left', fillColor: yellowColorCode } }
        ],
        [
            { content: '', colSpan: 5, styles: { halign: 'center', fillColor: greyColorCode } },
            { content: 'Base Amount:', colSpan: 1, styles: { halign: 'right', fillColor: whiteColorCode } },
            { content: `$${totalCost.toFixed(2)}`, colSpan: 1, styles: { halign: 'center', fontStyle: 'bold' } }
        ]);
     //add discount row to table when total Percentage Discount is > 0
    if (totalPercentageDiscount > 0) {
        body.push(
            [
                { content: '', colSpan: 5, styles: { halign: 'center', fillColor: greyColorCode } },
                { content: `${percentageDiscount}% Discount:`, colSpan: 1, styles: { halign: 'right', fillColor: whiteColorCode } },
                { content: `-$${totalPercentageDiscount}`, colSpan: 1, styles: { halign: 'center', fontStyle: 'bold' } }
            ]
        );
    }
    //add discount row to table when discount is > 0
    if (caseDiscount > 0) {
        body.push(
            [
                { content: '', colSpan: 5, styles: { halign: 'center', fillColor: greyColorCode } },
                { content: 'Discount:', colSpan: 1, styles: { halign: 'right', fillColor: whiteColorCode } },
                { content: `-$${caseDiscount}`, colSpan: 1, styles: { halign: 'center', fontStyle: 'bold' } }
            ]
        );
    }
    body.push(
        [
            { content: '', colSpan: 5, styles: { halign: 'center', fillColor: greyColorCode } },
            { content: 'Grand Total:', colSpan: 1, styles: { halign: 'right', fillColor: greyColorCode } },
            { content: `$${(totalCost - caseDiscount - totalPercentageDiscount).toFixed(2)}`, colSpan: 1, styles: { halign: 'center', fontStyle: 'bold' } }
        ],
        [
            { content: '* Price includes travel expenses', colSpan: 7, styles: { halign: 'left', fillColor: whiteColorCode } }
        ]
    );
    body.push(
        [
            { content: '', colSpan: 7, },
        ],
        [
            { content: 'Note', colSpan: 7, styles: { halign: 'left', fillColor: greyColorCode } },
        ],
        [
            { content: note, colSpan: 7, styles: { halign: 'left', fillColor: whiteColorCode } },
        ],

    );
    if (data.serviceType === 'Service') {
        body.push(
            [
                {
                    content: " 1. Domestic Service rates within the United States (excluding Alaska and Hawaii) and Canada, Mexico, & Caribbean for equipment purchased from Scienscope International", colSpan: 7, styles: { halign: 'left', lineWidth: 0 }
                }
            ],
            [
                { content: "a.", colSpan: 1, styles: { halign: 'right', lineWidth: 0 } },
                { content: "Labor is billed at $150.00 per hour (8-hour day = $1,200.00).", colSpan: 6, styles: { halign: 'left', lineWidth: 0 } }
            ],
            [
                { content: "i.", colSpan: 2, styles: { halign: 'right', lineWidth: 0 } },
                { content: " Minimum service call is 4 hours within 100-mile radius.", colSpan: 5, styles: { halign: 'left', lineWidth: 0 } }
            ],
            [
                { content: "ii.", colSpan: 1, styles: { halign: 'right', lineWidth: 0 } },
                { content: "Minimum service call is 8 hours outside of that distance.", colSpan: 6, styles: { halign: 'left', lineWidth: 0 } }
            ],
            [
                { content: "b.", colSpan: 1, styles: { halign: 'right', lineWidth: 0 } },
                { content: "Service beyond 8 hours in one day is charged at time and a half: $225.00 per hour.", colSpan: 6, styles: { halign: 'left', lineWidth: 0 } }
            ],
            [
                { content: "c.", colSpan: 1, styles: { halign: 'right', lineWidth: 0 } },
                { content: " Weekends and holidays are billed at double time: $300.00 per hour.", colSpan: 6, styles: { halign: 'left', lineWidth: 0 } }
            ],
            [
                { content: "d.", colSpan: 1, styles: { halign: 'right', lineWidth: 0 } },
                {
                    content: "Training requested beyond that of initial Scienscope system order installation is normal rates" +
                        " (listed above).  Normal installation is listed on initial quote, typically 2 full days for batch" +
                        " machine and 4 full days for inline machine.", colSpan: 6, styles: { halign: 'left', lineWidth: 0 }
                }
            ],
            [
                { content: "e.", colSpan: 1, styles: { halign: 'right', lineWidth: 0 } },
                { content: "Customer is responsible for standard travel expenses listed below as well as travel time to/from customer.", colSpan: 6, styles: { halign: 'left', lineWidth: 0 } }
            ],
            [
                { content: "i.", colSpan: 2, styles: { halign: 'right', lineWidth: 0 } },
                { content: "Travel time is defined as Flying/driving time to/from customer at $75.00 per hour.", colSpan: 5, styles: { halign: 'left', lineWidth: 0 } }
            ],
            [
                { content: "f.", colSpan: 1, styles: { halign: 'right', lineWidth: 0 } },
                { content: "Purchase order is required BEFORE service can be scheduled.", colSpan: 6, styles: { halign: 'left', lineWidth: 0 } }
            ],
            [{
                content: 'Travel expenses', colSpan: 7, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 }
            }],
            [
                { content: "a.", colSpan: 1, styles: { halign: 'right', lineWidth: 0 } },
                { content: `Local personal automobile - ${data.pricePerMile} per mile`, colSpan: 6, styles: { halign: 'left', lineWidth: 0 } }
            ],
            [
                { content: "b.", colSpan: 1, styles: { halign: 'right', lineWidth: 0 } },
                { content: "Rental car - actual charges plus gasoline purchases", colSpan: 6, styles: { halign: 'left', lineWidth: 0 } }
            ],
            [
                { content: "c.", colSpan: 1, styles: { halign: 'right', lineWidth: 0 } },
                { content: "Domestic airfare - Coach class", colSpan: 6, styles: { halign: 'left', lineWidth: 0 } }
            ],
            [
                { content: "d.", colSpan: 1, styles: { halign: 'right', lineWidth: 0 } },
                { content: "Taxi - actual charges", colSpan: 6, styles: { halign: 'left', lineWidth: 0 } }
            ],
            [{
                content: 'Other expenses', colSpan: 7, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 }
            }],
            [
                { content: "a.", colSpan: 1, styles: { halign: 'right', lineWidth: 0 } },
                { content: "Hotels - standard room accommoda ons actual charges.", colSpan: 6, styles: { halign: 'left', lineWidth: 0 } }
            ],
            [
                { content: "b.", colSpan: 1, styles: { halign: 'right', lineWidth: 0 } },
                { content: " Meals - are based on U.S. Labor department guidelines for city locations.", colSpan: 6, styles: { halign: 'left', lineWidth: 0 } }
            ],
            [
                { content: "c.", colSpan: 1, styles: { halign: 'right', lineWidth: 0 } },
                { content: "Additional expenses related to service call to be billed at actual cost.", colSpan: 6, styles: { halign: 'left', lineWidth: 0 } }
            ],
            [{
                content: 'Service reporting and Invoicing', colSpan: 7, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 }
            }],
            [
                { content: "a.", colSpan: 1, styles: { halign: 'right', lineWidth: 0 } },
                { content: "Service reports and expenses will be documented Sunday through Saturday.", colSpan: 6, styles: { halign: 'left', lineWidth: 0 } }
            ],
            [
                { content: "b.", colSpan: 1, styles: { halign: 'right', lineWidth: 0 } },
                { content: "Invoices are billed upon receipt of service reports and will be filled at completion of service call", colSpan: 6, styles: { halign: 'left', lineWidth: 0 } }
            ],
            [{
                content: 'Payment terms', colSpan: 7, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 }
            }],
            [
                { content: "a.", colSpan: 1, styles: { halign: 'right', lineWidth: 0 } },
                { content: "Purchase order must be in-hand prior to dispatch of service engineer.", colSpan: 6, styles: { halign: 'left', lineWidth: 0 } }
            ],
            [
                { content: "b.", colSpan: 1, styles: { halign: 'right', lineWidth: 0 } },
                { content: "Terms are Pre-pay", colSpan: 6, styles: { halign: 'left', lineWidth: 0 } }
            ],
            [{
                content: '', colSpan: 7, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 }
            }],
            [
                { content: "Quoted By:", colSpan: 2, styles: { halign: 'right', fontStyle: 'bold', lineWidth: 0 } },
                { content: `${summitedby}`, colSpan: 5, styles: { lineWidth: 0 } }
            ],
            [
                { content: "Contact:", colSpan: 2, styles: { halign: 'right', fontStyle: 'bold', lineWidth: 0 } },
                { content: `${cell}`, colSpan: 5, styles: { lineWidth: 0 } }
            ],
            [
                { content: "Send Your Order To:", colSpan: 2, styles: { halign: 'right', fontStyle: 'bold', lineWidth: 0 } },
                { content: `${emailPOto}`, colSpan: 5, styles: { lineWidth: 0 } }
            ],
        );
    }

    if (data.serviceType === "PM") {
        body.push(
            [{ content: '', colSpan: 7 }], // Empty row for spacing
            [{ content: 'TERMS', colSpan: 7, styles: { halign: 'center', fillColor: greyColorCode } }],
        );
        body.push(...termsList);
        body.push(
            [{ content: '', colSpan: 7 }], // Empty row for spacing
            [{ content: 'Services Performed During Preventative Maintenance', colSpan: 7, styles: { halign: 'center', fillColor: greyColorCode } }],
            ['*', { content: "Safety System Maintenance", colSpan: 6, styles: { fontStyle: 'bold' } }],
            ['', { content: "Inspect Safety Interlocks, and Adjust if needed", colSpan: 6 }],
            ['', { content: "Inspect Tower Light, and Adjust if needed", colSpan: 6 }],
            ['', { content: "Radiation Survey Done at Full Rated Output of X-Ray System", colSpan: 6 }],
            ['', { content: "Minor Adjustment of Cabinet Doors, where applicable", colSpan: 6 }],
            ['', { content: "Check all Safety Labels", colSpan: 6 }],
            ['', { content: "  ", colSpan: 6 }],
            ['*', { content: "Sealed Tube X-Ray Equipment", colSpan: 6, styles: { fontStyle: 'bold' } }],
            ['', { content: "Check kV and mA Displays, kV and mA Controls", colSpan: 6 }],
            ['', { content: "Focus X-Ray Tube Power Supply, where applicable", colSpan: 6 }],
            ['', { content: "  ", colSpan: 6 }],
            ['*', { content: "Real-Time X-Ray System", colSpan: 6, styles: { fontStyle: 'bold' } }],
            ['', { content: "Clean Camera Lens, and Adjust if needed", colSpan: 6 }],
            ['', { content: "Clean and Lubricate Sample Stage", colSpan: 6 }],
            ['', { content: "Navagation, pixel Mapping, Camera Calibration", colSpan: 6 }],
            ['', { content: "  ", colSpan: 6 }],
            ['*', { content: "Maintenance Services Provided For Electrical", colSpan: 6, styles: { fontStyle: 'bold' } }],
            ['', { content: "Check Wiring, contacts, lights, buttons, etc", colSpan: 6 }],
            ['', { content: "Check Power Receptacle and Ground Wire Connections", colSpan: 6 }],
            ['', { content: "Check Incoming Power Supply Voltage", colSpan: 6 }],
            ['', { content: "  ", colSpan: 6 }],
            ['*', { content: "Maintenance Services Provided For Mechanical", colSpan: 6, styles: { fontStyle: 'bold' } }],
            ['', { content: "Check Cable Connection, stage drive/ball screw for wear", colSpan: 6 }],
            ['', { content: "Check stage couplers, Clean Exterior & Interior", colSpan: 6 }],
            ['', { content: "Clean and lubricate drive belts, where applicable", colSpan: 6 }],
            ['', { content: "  ", colSpan: 6 }],
            [{ content: 'Scienscope Contact Information', colSpan: 7, styles: { halign: 'center', fillColor: greyColorCode } }], // Empty row for spacing
            [{ content: "Submitted By: ", colSpan: 2 }, { content: summitedby, colSpan: 4 }],
            [{ content: "Cell: ", colSpan: 2 }, { content: cell, colSpan: 4 }],
            [{ content: "Service Time: ", colSpan: 2 }, { content: serviceline, colSpan: 4 }],
            [{ content: "Tech Support Email: ", colSpan: 2 }, { content: techsupportemail, colSpan: 4 }],
            [{ content: "Email PO to: ", colSpan: 2 }, { content: emailPOto, colSpan: 4 }],

        );
    }

    // Generate the PDF with the autoTable
    doc.autoTable({
        head: headers,
        body: body,
        columnStyles: {
            0: { cellWidth: tableWidth / 7 - 2 },
            1: { cellWidth: tableWidth / 7 },
            2: { cellWidth: tableWidth / 7 },
            3: { cellWidth: tableWidth / 7 },
            4: { cellWidth: tableWidth / 7 },
            5: { cellWidth: tableWidth / 7 },
            6: { cellWidth: tableWidth / 7 }
        },
        theme: 'grid',
        margin: { top: 20 },
        startY: y1 + 10,
        styles: { fontSize: 9, textColor: blackColorCode, cellPadding: 1, lineHeight: 1.2 }, // Set global font size
        didDrawPage: () => {
            // Add footer on each page
            addFooter(doc, "5751 Schaefer Ave., Chino, CA 91765", formVersion);
        },
    });

    doc.save(sanitizedCustomerName);

}

export default generateQuotationPDF;