import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, TextField, Box, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, FormControlLabel, Autocomplete } from '@mui/material';
import ErrorDialog from '../ErrorDialog';
import { useTheme } from '@mui/material/styles';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const ServiceOrderForm = ({ mode = "add", open, onClose, service = {}, onAdd, onSubmit }) => {
  const theme = useTheme();
  const [formData, setFormData] = useState({
    payDate: '',
    customer: '',
    poNumber: '',
    poDate: '',
    serviceType: '',
    location: '',
    installDate: '',
    notes: '',
    isPaid: false,
  });
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (mode === "edit" && service) {
      setFormData({
        payDate: service.payDate || '',
        customer: service.customer || '',
        poNumber: service.poNumber || '',
        poDate: service.poDate || '',
        serviceType: service.serviceType || '',
        location: service.location || '',
        installDate: service.installDate || '',
        notes: service.notes || '',
        isPaid: service.isPaid || false,
      });
    }
  }, [mode, service]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.customer) newErrors.customer = 'Customer is required';
    if (!formData.poNumber) newErrors.poNumber = 'PO Number is required';
    if (!formData.poDate) newErrors.poDate = 'PO Date is required';
    if (!formData.serviceType) newErrors.serviceType = 'Type of Service is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validate()) {
      return;
    }
    try {
      let response;
      if (mode === "add") {
        response = await axios.post(backendString + '/services', formData, {
          headers: {
            Authorization: sessionStorage.getItem('token'),
          },
        });
        if (onAdd) onAdd(response.data);
      } else if (mode === "edit" && service) {
        try {
          response = await axios.put(backendString + `/services/${service._id}`, formData, {
            headers: {
              'Authorization': sessionStorage.getItem('token')
            }
          });
        } catch (error) {
          setError(error.response?.data?.error);
        }
        if (onSubmit) onSubmit(response.data);
      }
      else {
        setError("Something went wrong");
        return;
      }
      setFormData({
        payDate: '',
        customer: '',
        poNumber: '',
        poDate: '',
        serviceType: '',
        location: '',
        installDate: '',
        notes: '',
        isPaid: false,
      });
      onClose(); // Close the dialog after saving the item
    } catch (error) {
      console.error(error);
      setError(error.response?.data?.error);
    }
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  const handleClose = () => {
    setFormData({
      payDate: '',
      customer: '',
      poNumber: '',
      poDate: '',
      serviceType: '',
      location: '',
      installDate: '',
      notes: '',
      isPaid: false,
    });
    setErrors({});
    onClose();
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{mode === "add" ? "Add Service" : "Edit Service"}</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2} width={300} mt={1}>
            <TextField
              label="Payment Date"
              name="payDate"
              type="date"
              value={formData.payDate}
              onChange={handleInputChange}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Customer"
              name="customer"
              value={formData.customer}
              onChange={handleInputChange}
              required
              error={!!errors.customer && !formData.customer}
              helperText={formData.customer ? '' : errors.customer}
              variant="outlined"
            />
            <TextField
              label="PO Number"
              name="poNumber"
              value={formData.poNumber}
              onChange={handleInputChange}
              required
              error={!!errors.poNumber && !formData.poNumber}
              helperText={formData.poNumber ? '' : errors.poNumber}
              variant="outlined"
            />
            <TextField
              label="PO Date"
              name="poDate"
              value={formData.poDate}
              onChange={handleInputChange}
              required
              error={!!errors.poDate && !formData.poDate}
              helperText={formData.poDate ? '' : errors.poDate}
              type="date"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                style: { color: !formData.poDate && errors.poDate ? theme.palette.error.main : 'inherit' }
              }}
            />

            <Autocomplete
              freeSolo
              options={[
                '1 YR PM',
                '3 YR PM',
                'Service',
                'Radiation Survey',
                'Repacking Service',
                'Software Customization']}
              value={formData.serviceType}
              onChange={(event, newValue) => {
                setFormData({
                  ...formData,
                  serviceType: newValue,
                });
              }}
              onInputChange={(event, newValue) => {
                setFormData({
                  ...formData,
                  serviceType: newValue,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Type of Service"
                  name="serviceType"
                  required
                  error={!!errors.serviceType && !formData.serviceType}
                  helperText={formData.serviceType ? '' : errors.serviceType}
                  variant="outlined"
                />
              )}
            />

            <TextField
              label="Location"
              name="location"
              value={formData.location}
              onChange={handleInputChange}
              variant="outlined"
            />
            <TextField
              label="Installation Date"
              name="installDate"
              value={formData.installDate}
              onChange={handleInputChange}
              type="date"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Notes"
              name="notes"
              value={formData.notes}
              onChange={handleInputChange}
              variant="outlined"
              multiline
              rows={2}
              InputLabelProps={{ shrink: true }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="isPaid"
                  checked={formData.isPaid}
                  onChange={handleInputChange}
                />
              }
              label="Paid"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            {mode === "add" ? "Add Item" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
      <ErrorDialog open={!!error} onClose={handleCloseErrorDialog} errorMessage={error} />
    </>
  );
};

export default ServiceOrderForm;