import React, { useEffect, useState } from 'react';
import ZingChart from 'zingchart-react';
import axios from 'axios';
import {GetTextByRegion} from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const MachineIssuesPieChart = () => {
  const [chartData, setChartData] = useState([]);
  const { selectedRegion } = useUser();

  
  useEffect(() => {
    // Fetch data from the remoteservice endpoint
    axios.get(`${backendString}/remoteservices`)
      .then(response => {
        const data = response.data;
        // Process data to get the necessary information for the pie chart
        const processedData = processChartData(data);
        setChartData(processedData);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // Function to process data
  const processChartData = (data) => {
    // Example processing logic
    // Replace with your actual data structure and processing logic
    const modelCount = data.reduce((acc, item) => {
      const model = item.model;  // Adjust this based on your data structure
      acc[model] = (acc[model] || 0) + 1;
      return acc;
    }, {});

    return Object.keys(modelCount).map(model => ({
      text: model,  // This will be the label for each slice
      values: [modelCount[model]],
    }));
  };

  const config = {
    type: 'pie',
    title: {
      text: GetTextByRegion(`Machine Issues by Models`,selectedRegion)+` (${selectedRegion})`, // Set the title text
      fontSize: 18,
      fontFamily: 'Arial',
      color: '#333',
      backgroundColor: 'transparent', // Transparent background for the title
      align: 'center', // Center-align the title
      marginTop: '10px',
    },
    series: chartData,
    plot: {
      slice: {
        'text': '', // Only display the label, not the value
        'text-size': 10, // Font size of the text
        'text-color': '#fff', // Color of the text
        'text-offset': 0.5, // Adjust text position if needed
      },
      tooltip: {
        text: '%t: %v', // Tooltip content
      },
    },
    legend: {
      visible: false, // Disable the legend if not needed
    },
  };

  return <ZingChart data={config} />;
};

export default MachineIssuesPieChart;
