const chinaProductionColumns = [
  { label: "Machine Model", label_chinese: "机台型号", name: "machineModelAndConfig", type: "text", required: true, InputLabelProps: {}, position: "left" },
  { label: "Machine NO.", label_chinese: "机台编号", name: "machineNo", type: "text", required: true, InputLabelProps: {}, position: "left" },
  { label: "Customer", label_chinese: "客户", name: "customer", type: "text", required: true, InputLabelProps: {}, position: "left" },
  { label: "Order Received Date", label_chinese: "生产通知单接单日期", name: "orderReceivedDate", type: "date", required: true, InputLabelProps: { shrink: true }, position: "left" },
  { label: "Lead Time/Delivery Date", label_chinese: "标准交期或客户要求的出货日期", name: "leadTimeOrDeliveryDate", type: "text", required: true, InputLabelProps: {}, position: "left" },
  { label: "Ready Ship Date", label_chinese: "生产计划出货日期", name: "readyShipDate", type: "date", required: true, InputLabelProps: { shrink: true }, position: "left" },
  { label: "Actual Ship Date", label_chinese: "实际出货日期", name: "actualShipDate", type: "date", required: true, InputLabelProps: { shrink: true }, position: "left" },
  { label: "Status", label_chinese: "完成情况", name: "status", type: "progress", required: true, InputLabelProps: {}, position: "right" },
  { label: "Financial Release", label_chinese: "财务放行", name: "financialRelease", type: "text", required: true, InputLabelProps: {}, position: "right" },
  { label: "Special Configuration", label_chinese: "特殊配置", name: "specialConfiguration", type: "text", required: true, InputLabelProps: {}, position: "right" },
  { label: "Tube", label_chinese: "光管", name: "tube", type: "text", required: false, InputLabelProps: {}, position: "right" },
  { label: "Detector", label_chinese: "平板", name: "detector", type: "text", required: false, InputLabelProps: {}, position: "right" },
  { label: "PC", label_chinese: "主机PC非标配的标注到货情况", name: "pc", type: "text", required: false, InputLabelProps: {}, position: "right" },
  { label: "Monitor", label_chinese: "显示器非标配的标注到货情况", name: "monitor", type: "text", required: false, InputLabelProps: {}, position: "right" },
  { label: "Software", label_chinese: "显示器非标配的标注到货情况", name: "software", type: "text", required: false, InputLabelProps: {}, position: "right" },
  { label: "PO Number", label_chinese: "显示器非标配的标注到货情况", name: "poNumber", type: "text", required: true, InputLabelProps: {}, position: "right" },
  { label: "Options", label_chinese: "显示器非标配的标注到货情况", name: "options", type: "text", required: false, InputLabelProps: {}, position: "right" },
  { label: "Warranty", label_chinese: "显示器非标配的标注到货情况", name: "warranty", type: "text", required: false, InputLabelProps: {}, position: "right" },
];

export default chinaProductionColumns;