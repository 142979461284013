import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Button } from '@mui/material';
import UsersForm from '../components/User/UsersForm.js';
import UserTable from '../components/User/UserTable.js';
import { useUser, useValidateRegions } from '../hooks/UserContext.js';
import roles from '../hooks/Roles.js';
import ErrorDialog from '../components/ErrorDialog.js';
import { GetTextByRegion } from '../hooks/Translation.js';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const User = () => {
  const [isUserPopupOpen, setIsUserPopupOpen] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);
  const validateSelectedRegion = ["USA", "MEXICO", "CHINA", "EU", "TAIWAN"];
  const {selectedRegion, user:{userRights}, refreshUser} = useUser();
  useValidateRegions(selectedRegion,validateSelectedRegion);

  useEffect(() => {
    refreshUser();
  },[refreshUser,isUserPopupOpen,openEditDialog,openDeleteDialog]);

  const fetchUsers = useCallback(async () => {
    try {
      const response = await axios.get(backendString + '/users', {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });
      setUsers(response.data); // Assuming your API returns an array of users
    } catch (error) {
      console.error('Error fetching users:', error);
      setError(error.response?.data?.error);
    }
  }, [setUsers]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleOpenPopup = () => {
    setIsUserPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsUserPopupOpen(false);
  };

  const handleAddUser = (newUser) => {
    setUsers([...users, newUser]);
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  return (
    <>
    {userRights >= roles["ADMIN"] && (
    <div>
        <Button variant="contained" color="primary" onClick={handleOpenPopup}>
          {GetTextByRegion("Add New User",selectedRegion)}
        </Button>
        {isUserPopupOpen && (
        <UsersForm  onAdd={handleAddUser} onClose={handleClosePopup} selectedRegion={selectedRegion} />
        )}
        <UserTable 
          users = {users} 
          setUsers = {setUsers}   
          openEditDialog={openEditDialog}
          setOpenEditDialog={setOpenEditDialog}
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
          seletedRegion={selectedRegion}
        />

        <ErrorDialog
          open={!!error}
          onClose={handleCloseErrorDialog}
          errorMessage={error}
        />
    </div>
    )}
    </>
  );
};

export default User;