import React, { useEffect, useState } from 'react';
import ZingChart from 'zingchart-react';
import axios from 'axios';
import { useUser } from '../../hooks/UserContext';
import { Paper } from '@mui/material';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const MachineSoldPiechart = ({ selectedYear }) => {
    const [chartData, setChartData] = useState({ models: [], series: [] });
    const { selectedRegion } = useUser();
    const [machineModels, setMachineModels] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getMachineModels();
                await fetchChartData();
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [selectedYear, selectedRegion]);

    const fetchChartData = async () => {
        try {
            const response = await axios.get(`${backendString}/machines/sold/${selectedYear}?selectedRegion=${selectedRegion}`);
            const data = response.data;
            const processedData = processChartData(data);
            setChartData(processedData);
        } catch (error) {
            console.error('Error fetching chart data:', error);
        }
    };

    const getMachineModels = async () => {
        try {
            const response = await axios.get(`${backendString}/dropdown?selectedRegion=${selectedRegion}&type=machine`);
            const models = response.data.map((item) => item.ModelName);
            setMachineModels(models);
        } catch (error) {
            console.error('Error fetching models:', error);
        }
    };

    const processChartData = (data) => {
        const modelCount = data.reduce((acc, item) => {
            const model = item.model;
            acc[model] = (acc[model] || 0) + 1;
            return acc;
        }, {});

        const series = machineModels.map((model) => modelCount[model] || 0);

        return { models: machineModels, series };
    };

    const config = {
        type: 'bar',
        height: '90%',
        width: '100%',
        'scale-x': {
            label: { text: "Models" },
            labels: chartData.models,
            step: 1,
            'max-items': 33,
            item: {
                angle: 270,
                'font-size': 10,
                'font-weight': 'bold',
            }
        },
        'scale-y': {
            label: { text: "Units Sold" },
            step: 1,
        },
        series: [{
            values: chartData.series,
            'background-color': "#6666FF #FF0066", /* Bar fill color (gradient) */
            alpha: 0.3, /* Transparency (more transparent) */
            tooltip: {
                text: "%v units sold"
            }
        }],
        plot: {
            'border-radius': "9px", /* Rounded Corners */
            
        },
    };

    return (
        <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#fff' }}>
            <ZingChart data={config} />
        </Paper>
    );
};

export default MachineSoldPiechart;
