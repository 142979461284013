import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';

const ConfirmationDialog = ({ open, onClose, onConfirm, title, message }) => {
  const {selectedRegion} = useUser();
  const [approvalNotes, setApprovalNotes] = useState('');

  const handleConfirm = () => {
    onConfirm(approvalNotes);
    setApprovalNotes('');
  };

  const handleClose = () => {
    setApprovalNotes('');
    onClose();
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          label={title === GetTextByRegion("Confirm Rejection",selectedRegion) ? `${GetTextByRegion("Rejection Notes",selectedRegion)}`: `${GetTextByRegion("Approval Notes",selectedRegion)}`}
          fullWidth
          multiline
          rows={4}
          value={approvalNotes}
          onChange={(e) => setApprovalNotes(e.target.value)}
          margin="dense"
        />
        <Typography variant="body1" mt={2}>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {GetTextByRegion("Cancel",selectedRegion)}
        </Button>
        <Button onClick={handleConfirm} variant="contained" color="primary">
          {GetTextByRegion("Confirm",selectedRegion)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
