import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Grid, Typography } from '@mui/material';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';

const ItemRequestConfirmationDialog = ({ open, onClose, onConfirm, item, errors }) => {
  const {selectedRegion} = useUser();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{GetTextByRegion("Confirm Item Request",selectedRegion)}</DialogTitle>
      <DialogContent>
        <DialogContentText>
        {GetTextByRegion("Please confirm outbound part numbers and quantities",selectedRegion)}
        </DialogContentText>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">
            <strong>{GetTextByRegion("Part Number", selectedRegion)}:</strong>
          </Typography>
          {item?.partNumber?.length ? (
            item.partNumber.map((part, index) => {
              const error = errors?.find(error => error.partNumber === part);
              const textColor = (!error?.isExist && error) ? 'red' : 'black';
              return (
                <Typography key={index} variant="subtitle1" style={{ color: textColor }}>
                  {part}
                </Typography>
              );
            })
          ) : (
            <Typography variant="subtitle1" color="textSecondary">
               {GetTextByRegion("No part numbers available",selectedRegion)}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">
            <strong>{GetTextByRegion("Quantity", selectedRegion)}:</strong>
          </Typography>
          {item?.quantity?.length ? (
            item.quantity.map((qty, index) => {
              // Find if there's an error for this part number
              const part = item.partNumber[index];
              const error = errors?.find(error => error.partNumber === part);
              const textColor = error ? 'red' : 'black';

              // Check if quantity is specifically in error
              const isQuantityError = error && !error.hasSufficientQuantity;
              return (
                <Typography key={index} variant="subtitle1" style={{ color: isQuantityError ? 'red' : textColor }}>
                  {qty}
                </Typography>
              );
            })
            ) : (
              <Typography variant="subtitle1" color="textSecondary">
                {GetTextByRegion("No quantities available",selectedRegion)}
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='secondary'>
           {GetTextByRegion("Cancel",selectedRegion)}        
        </Button>
        <Button onClick={onConfirm} variant="contained" color="primary">
           {GetTextByRegion("Confirm",selectedRegion)}            
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItemRequestConfirmationDialog;
