import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Box, Grid, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import BarGraph from '../components/Graphs/Bargraph';
// import HeatMap from '../components/Graphs/HeatMap';
import CompletedMachinePieChart from '../components/Graphs/CompletedMachinePiechart';
import InhouseModelPiechart from '../components/Graphs/InhouseModelPiechart';
import MachineIssuesPieChart from '../components/Graphs/MachineIssuesPiechart';
import useWindowSize from '../hooks/useWindowSize';
import { useUser } from '../hooks/UserContext';
import { GetTextByRegion } from '../hooks/Translation';
import MachineSoldPiechart from '../components/Graphs/MachineSoldPiechart';

const backendString = process.env.REACT_APP_BACKEND_STRING;

function Homepage() {
  const [filteredItems, setFilteredItems] = useState([]);
  const [error, setError] = useState(null); // State for error messages
  const { windowSize, smallWidth } = useWindowSize();
  const isMobile = windowSize.width < smallWidth;
  const { selectedRegion, refreshUser } = useUser();
  let connectionString = backendString + '/items';


  const fetchFilteredItems = useCallback(async () => {
    try {
      const response = await axios.get(`${connectionString}?selectedRegion=${selectedRegion}`);
      setFilteredItems(response.data.filter(item => item.quantity <= item.minQuantity));
      setError(null); //reset error if selectedRegion was not found previously
    } catch (error) {
      console.error('Error fetching low quantity items:', error);
      setError(error.response?.data?.error || 'Error fetching low quantity items'); // Set error state
    }
  }, [connectionString, selectedRegion]);

  useEffect(() => {
    refreshUser();
  }, [refreshUser, selectedRegion]);

  useEffect(() => {
    fetchFilteredItems();
  }, [selectedRegion]);

  const LowQuantityItemsTable = ({ filteredItems, error }) => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ '& th': { padding: '6px 16px' } }}>
              <TableCell>{GetTextByRegion("Part Number", selectedRegion)}</TableCell>
              <TableCell>{GetTextByRegion("Quantity", selectedRegion)}</TableCell>
              <TableCell>{GetTextByRegion("Min Quantity", selectedRegion)}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredItems.length > 0 ? (
              filteredItems.map((item) => (
                <TableRow key={item._id} sx={{ '& td': { padding: '6px 16px' }, height: '36px' }}>
                  <TableCell>{item.partnumber}</TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell>{item.minQuantity}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  {error ? error : GetTextByRegion('No low quantity items found', selectedRegion)}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  // Configuration object for regions and their allowed charts
  const regionConfig = {
    USA: {
      pieCharts: [
        { component: <MachineIssuesPieChart />, key: 'MachineIssuesPieChart' },
        { component: <CompletedMachinePieChart />, key: 'CompletedMachinePieChart' },
        { component: <InhouseModelPiechart />, key: 'InhouseModelPiechart' },       
      ],
      barAndHeatmap: (
        <>
          <Box sx={{ flex: 1, maxWidth: isMobile ? '100%' : '48%', height: isMobile ? 'auto' : '100%' }}>
            <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#fff', height: '100%' }}>
              <BarGraph />
            </Paper>
          </Box>
          <Box sx={{ flex: 1, maxWidth: isMobile ? '100%' : '48%', height: isMobile ? 'auto' : '100%', maxHeight: '500px', overflow: 'auto' }}>
            <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#fff', height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                {GetTextByRegion("LOW QUANTITY ITEMS", selectedRegion)}
              </Typography>
              <LowQuantityItemsTable filteredItems={filteredItems} error={error} />
            </Paper>
          </Box>
        </>
        // barAndHeatmap: (
        //   <>
        //     <Box sx={{ flex: 1, maxWidth: isMobile ? '100%' : '48%', height: isMobile ? 'auto' : '100%' }}>
        //       <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#fff', height: '100%' }}>
        //         <BarGraph />
        //       </Paper>
        //     </Box>
        //     <Box sx={{ flex: 1, maxWidth: isMobile ? '100%' : '48%', height: isMobile ? 'auto' : '100%' }}>
        //       <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#fff', height: '100%' }}>
        //         {filteredItems}
        //       </Paper>
        //     </Box>
        //     {/* <Box sx={{ flex: 1, maxWidth: isMobile ? '100%' : '48%', height: isMobile ? 'auto' : '100%' }}>
        //       <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#fff', height: '100%', display: 'flex', justifyContent: 'center' }}>
        //         <div style={{ maxWidth: '640px', width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
        //           <HeatMap />
        //         </div>
        //       </Paper>
        //     </Box> */}
        //   </>
      )
    },
    MEXICO: {
      barAndHeatmap:
        <Box
          sx={{
            flex: 1,
            maxWidth: '100%',
            height: isMobile ? 'auto' : '100%',
            maxHeight: '100%',
            overflow: 'hidden',  // Prevents overflow on the main container
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Paper
            elevation={3}
            sx={{
              padding: 2,
              backgroundColor: '#fff',
              flex: 1,  // Allows the Paper to expand within the available space
              display: 'flex',
              flexDirection: 'column',
              maxHeight: windowSize.height * 0.7 // Set a specific max height for the Paper component
            }}
          >
            <Typography variant="h6" gutterBottom>
              {GetTextByRegion("LOW QUANTITY ITEMS", selectedRegion)}
            </Typography>
            <Box sx={{ flex: 1, overflow: 'auto' }}> {/* Scrollable table container */}
              <LowQuantityItemsTable filteredItems={filteredItems} error={error} />
            </Box>
          </Paper>
        </Box>
    },
    EU: {
      barAndHeatmap:
        <Box
          sx={{
            flex: 1,
            maxWidth: '100%',
            height: isMobile ? 'auto' : '100%',
            maxHeight: '100%',
            overflow: 'hidden',  // Prevents overflow on the main container
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Paper
            elevation={3}
            sx={{
              padding: 2,
              backgroundColor: '#fff',
              flex: 1,  // Allows the Paper to expand within the available space
              display: 'flex',
              flexDirection: 'column',
              maxHeight: windowSize.height * 0.7 // Set a specific max height for the Paper component
            }}
          >
            <Typography variant="h6" gutterBottom>
              {GetTextByRegion("LOW QUANTITY ITEMS", selectedRegion)}
            </Typography>
            <Box sx={{ flex: 1, overflow: 'auto' }}> {/* Scrollable table container */}
              <LowQuantityItemsTable filteredItems={filteredItems} error={error} />
            </Box>
          </Paper>
        </Box>
    },
    TAIWAN: {
      barAndHeatmap:
        <Box
          sx={{
            flex: 1,
            maxWidth: '100%',
            height: isMobile ? 'auto' : '100%',
            maxHeight: '100%',
            overflow: 'hidden',  // Prevents overflow on the main container
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Paper
            elevation={3}
            sx={{
              padding: 2,
              backgroundColor: '#fff',
              flex: 1,  // Allows the Paper to expand within the available space
              display: 'flex',
              flexDirection: 'column',
              maxHeight: windowSize.height * 0.7 // Set a specific max height for the Paper component
            }}
          >
            <Typography variant="h6" gutterBottom>
              {GetTextByRegion("LOW QUANTITY ITEMS", selectedRegion)}
            </Typography>
            <Box sx={{ flex: 1, overflow: 'auto' }}> {/* Scrollable table container */}
              <LowQuantityItemsTable filteredItems={filteredItems} error={error} />
            </Box>
          </Paper>
        </Box>
    },
  };

  // Fallback to a default layout if the region is not in the configuration
  const defaultConfig = {
    barAndHeatmap: (
      <img
        src="https://www.scienscope.com/wp-content/uploads/2023/11/Image-with-logo-1.png"
        style={{ width: '100%', height: 'auto' }}
        alt="Scienscope International"
      />
    )
  };

  // Get the charts for the selected region, or fallback to the default configuration
  const currentConfig = regionConfig[selectedRegion] || defaultConfig;

  return (
    <Box p={3} sx={{ backgroundColor: '#e0e0e0', minHeight: '100vh' }}>
      <Grid container spacing={3}>
        {/* Pie Charts Row */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: isMobile
                ? 'center'
                : currentConfig.pieCharts?.length === 2
                  ? 'space-around'
                  : 'center',
              gap: 2
            }}
          >
            {currentConfig.pieCharts?.map((chart) => (
              <Box
                key={chart.key}
                sx={{
                  flex: 1,
                  maxWidth: isMobile ? '100%' : currentConfig.pieCharts.length === 2 ? '48%' : '32%'
                }}
              >
                <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#fff' }}>
                  {chart.component}
                </Paper>
              </Box>
            ))}
          </Box>
        </Grid>

        {/* Bar Graph and HeatMap Row */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: isMobile ? 'center' : 'space-between',
              gap: 1
            }}
          >
            {currentConfig.barAndHeatmap}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Homepage;
