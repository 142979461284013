import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, Box, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import ErrorDialog from '../ErrorDialog';
import Resizer from 'react-image-file-resizer';
import { styled } from '@mui/material/styles';
import { GetCurrencySymbol, GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const Input = styled('input')({
  display: 'none',
});

const ItemForm = ({ onAdd, onClose }) => {
  const [formData, setFormData] = useState({
    partnumber: '',
    name: '',
    location: '',
    price: 0,
    quantity: '',
    image: '',
    notes: '',
    minQuantity: 1,
  });
  const [error, setError] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const { selectedRegion, user } = useUser();

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (files) {
      const file = files[0];
      Resizer.imageFileResizer(
        file,
        300,
        300,
        'JPEG',
        100,
        0,
        (uri) => {
          setFormData({
            ...formData,
            [name]: uri
          });
          setImagePreview(URL.createObjectURL(file));
        },
        'blob'
      );
    } else {
      const newValue = name === "price" ? value.replace(GetCurrencySymbol(selectedRegion), '') : value;
      setFormData((prev) => ({
        ...prev,
        [name]: newValue
      }));
    }
  };

  const validateForm = () => {

    const errors = {};
    if (!formData.partnumber) errors.partnumber = GetTextByRegion('Part Number is required', selectedRegion);
    if (!formData.location) errors.location = GetTextByRegion('Location is required', selectedRegion);
    if (!('quantity' in formData) || formData.quantity === "" || formData.quantity < 0) errors.quantity = GetTextByRegion('Quantity value is invalid!', selectedRegion);
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const addItem = async () => {

    if (!validateForm()) {

      return;
    }

    try {
      const formDataToSend = new FormData();
      formDataToSend.append('partnumber', formData.partnumber);
      formDataToSend.append('name', formData.name);
      formDataToSend.append('location', formData.location);
      formDataToSend.append('price', formData.price);
      formDataToSend.append('quantity', formData.quantity);
      formDataToSend.append('notes', formData.notes);
      formDataToSend.append('image', formData.image);
      formDataToSend.append('minQuantity', formData.minQuantity);


      let connectionString = '';
      connectionString = backendString + '/items';

      const response = await axios.post(`${connectionString}?selectedRegion=${selectedRegion}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': sessionStorage.getItem('token')
        }
      });

      const historyEntry = {
        partnumber: formData.partnumber,
        action: GetTextByRegion('ADD ITEM',selectedRegion),
        username: user.username,
        quantity: formData.quantity,
        timestamp: new Date().toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit', // Ensures month is two digits (e.g., "01" for January)
          day: '2-digit',   // Ensures day is two digits
          hour: '2-digit',  // 2-digit hour
          minute: '2-digit', // 2-digit minute
          hour12: false,    // Use 24-hour time
        }).replace(',', '') // Remove the comma between the date and time
      };

      // Add to history Log
      await axios.post( backendString + `/itemhistories?selectedRegion=${selectedRegion}`, historyEntry, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });

      onAdd(response.data);
      setFormData({
        partnumber: '',
        name: '',
        location: '',
        price: 0,
        quantity: 0,
        image: null,
        notes: '',
        minQuantity: 1,
      });
      onClose();
    } catch (error) {
      console.error(error);
      setError(error.response?.data?.error);
    }
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  return (
    <>
      <Dialog open={true} onClose={onClose}>
        <DialogTitle>{GetTextByRegion("Add Item", selectedRegion)}</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2} width={300} mt={1}>
            <label htmlFor="upload-button-file">
              <Input
                accept="image/jpeg, image/png"
                id="upload-button-file"
                type="file"
                name="image"
                onChange={handleInputChange}
              />
              <Button
                variant="contained"
                color="primary"
                component="span"
              >
                {GetTextByRegion("Choose Image", selectedRegion)}
              </Button>
            </label>
            {imagePreview && <img src={imagePreview} alt="Preview" style={{ marginTop: '16px', maxHeight: '200px', objectFit: 'contain' }} />}
            <TextField
              label={GetTextByRegion("Part Number", selectedRegion)}
              name="partnumber"
              value={formData.partnumber}
              onChange={handleInputChange}
              required
              variant="outlined"
              error={!!validationErrors.partnumber && !formData.partnumber}
              helperText={formData.partnumber ? '' : validationErrors.partnumber}
            />
            <TextField
              label={GetTextByRegion("Name", selectedRegion)}
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              variant="outlined"
            />
            <TextField
              label={GetTextByRegion("Location", selectedRegion)}
              name="location"
              value={formData.location}
              onChange={handleInputChange}
              required
              variant="outlined"
              error={!!validationErrors.location && !formData.location}
              helperText={formData.location ? '' : validationErrors.location}
            />
            <TextField
              label={GetTextByRegion("Quantity", selectedRegion)}
              name="quantity"
              type="number"
              value={formData.quantity}
              onChange={handleInputChange}
              required
              variant="outlined"
              error={!!validationErrors.quantity && formData.quantity < 0}
              helperText={formData.quantity >= 0 ? '' : validationErrors.quantity}
              onKeyPress={(e) => {
                const allowedKeys = /^[0-9]/;
                if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                  e.preventDefault();
                }
              }}
            />
            <TextField
              label={GetTextByRegion("Minimum Quantity", selectedRegion)}
              name="minQuantity"
              type="number"
              value={formData.minQuantity}
              onChange={handleInputChange}
              variant="outlined"
              onKeyPress={(e) => {
                const allowedKeys = /^[0-9]/;
                if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                  e.preventDefault();
                }
              }}
            />
            <TextField
              label={GetTextByRegion("Price", selectedRegion)}
              name="price"
              value={formData.price ? GetCurrencySymbol(selectedRegion)+`${formData.price}` : ''}
              onChange={handleInputChange}
              variant="outlined"
              onKeyPress={(e) => {
                let allowedKeys;
                if (selectedRegion === "TAIWAN" || selectedRegion === "CHINA") {
                  allowedKeys = /^[0-9]$/; 
                } else {
                  allowedKeys = /^[0-9.]$/; 
                }
                if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                  e.preventDefault();
                }
              }}
            />
            <TextField
              label={GetTextByRegion("Notes", selectedRegion)}
              name="notes"
              value={formData.notes}
              onChange={handleInputChange}
              variant="outlined"
              multiline
              rows={2}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            {GetTextByRegion("Cancel", selectedRegion)}
          </Button>
          <Button
            onClick={addItem}
            variant="contained"
            color="primary"
            disabled={!formData.partnumber || formData.partnumber === "" || formData.location === "" || !formData.quantity || formData.quantity < 0 || formData.quantity === ""}
          >
            {GetTextByRegion("Add Item", selectedRegion)}
          </Button>
        </DialogActions>
      </Dialog>

      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </>
  );
};

export default ItemForm;
