import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, TextField, FormHelperText } from '@mui/material';

const EditServiceView = ({ open, onClose, serviceData, onEditSubmit }) => {

    const [errorMessage,setErrorMesage]=useState("");
    const [service, setService] = useState({

        itemNumber: serviceData.itemNumber,
        quantity: serviceData.quantity,
        description: serviceData.description,
        cost: serviceData.cost,
        total: serviceData.total,
    }, []);

    const editService = () => {
        if (!service.itemNumber || service.quantity <= 0 || service.cost < 0) {
            return;
        }
        const total = service.cost * service.quantity;
        const newService = { ...service, total };

        onEditSubmit(newService);
        setService({
            itemNumber: "",
            quantity: "",
            description: "",
            cost: "",
            total: "",
        });
       onClose();
    };

    const handleChange = (field) => (event) => {
        setErrorMesage("");
        setService((prevService) => ({
            ...prevService,
            [field]: event.target.value,
        }));
    };

    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Edit Service</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Item Number"
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="normal"
                        value={service.itemNumber}
                        disabled={true} // Makes the field read-only       
                    />
                    <TextField
                        label="Quantity"
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="number"
                        value={service.quantity}
                        onChange={handleChange('quantity')}
                        onKeyPress={(e) => {
                            const allowedKeys = /^[0-9]+$/;
                            if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                e.preventDefault();
                                setErrorMesage("Error: Invalid Input!");
                            }
                        }}
                       
                    />
                    <TextField
                        label="Service Description"
                        fullWidth
                        variant="standard"
                        margin="normal"
                        value={service.description}
                        onChange={handleChange('description')}
                    />
                    <TextField
                        label="Cost"
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="normal"
                        value={service.cost ? `$${service.cost}` : ''}
                        onChange={(e) => {setService({ ...service, cost: e.target.value.replace('$', '') });setErrorMesage("")}}
                        onKeyPress={(e) => {
                            const allowedKeys = /^[0-9.]+$/;
                            if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                e.preventDefault();
                                setErrorMesage("Error: Invalid Input!");
                            }
                        }}
                    />
                     {errorMessage && <FormHelperText error><h4>Please check your input and try again!<br/>{errorMessage}</h4></FormHelperText>}
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                        <Button
                            onClick={()=>editService()}
                            variant="contained"
                            color="primary"
                            disabled={!service.itemNumber || service.quantity <= 0 || service.cost < 0}
                        >
                            Edit
                        </Button>
                        <Button onClick={onClose} variant="outlined" color="error" style={{ marginLeft: '8px' }}>
                            Close
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

        </>
    );
};

export default EditServiceView;