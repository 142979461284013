// This is machine model page. You can add , delete , update machine model on this page.
import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import MachineModelTable from '../components/MachineModel/MachineModelTable';
import MachineModelForm from '../components/MachineModel/MachineModelForm';
import { Button } from '@mui/material';
import { useUser, useValidateRegions } from '../hooks/UserContext';
import isEqual from 'lodash/isEqual';
import ErrorDialog from '../components/ErrorDialog';
import { GetTextByRegion } from '../hooks/Translation';

const backendString = process.env.REACT_APP_BACKEND_STRING;

function MachineModel() {
  const [models, setModels] = useState([]);
  const [racks, setRacks] = useState([]);
  const [tubes, setTubes] = useState([]);
  const [detector, setDetector] = useState([]);
  const [software, setSoftware] = useState([]);
  const [options, setOptions] = useState([]);
  const [isMachineModelPopupOpen, setIsMachineModelPopupOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0); // State for tab selection
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const validateSelectedRegion = ["USA", "MEXICO", "EU", "CHINA", "TAIWAN"];
  const { selectedRegion, refreshUser } = useUser();
  useValidateRegions(selectedRegion, validateSelectedRegion);

  const prevSelectedRegionRef = useRef();
  useEffect(() => {
    refreshUser().then(() => {
      const currentRegion = selectedRegion;
      if (prevSelectedRegionRef.current && !isEqual(prevSelectedRegionRef.current, currentRegion)) {
        setIsMachineModelPopupOpen(false);
        setOpenEditDialog(false)
        setOpenDeleteDialog(false);
      }
      prevSelectedRegionRef.current = currentRegion;
    });
  }, [refreshUser, selectedRegion, isMachineModelPopupOpen, openEditDialog, openDeleteDialog]);

  const fetchItems = useCallback(async () => {
    try {
      let endpoint;

      // Determine the type of item to fetch based on tabValue
      let type;
      switch (tabValue) {
        case 0: // Machines tab
          type = 'machine';
          break;
        case 1: // Tubes tab
          type = 'rack';
          break;
        case 2: // Tubes tab
          type = 'tube';
          break;
        case 3: // Software tab
          type = 'fpd';
          break;
        case 4: // FPD/Detector tab
          type = 'software';
          break;
        case 5: // FPD/Detector tab
          type = 'option';
          break;
        default:
          type = 'machine'; // Default to machines
      }

      // Construct the endpoint dynamically based on selected region and type
      endpoint = `${backendString}/dropdown?selectedRegion=${selectedRegion}&type=${type}`;

      const response = await axios.get(endpoint);

      // Set data based on the current tab
      switch (tabValue) {
        case 0:
          setModels(response.data); // Machines
          break;
        case 1:
          setRacks(response.data); // Racks
          break;
        case 2:
          setTubes(response.data); // Tubes
          break;
        case 3:
          setDetector(response.data); // Software
          break;
        case 4:
          setSoftware(response.data); // FPD/Detector
          break;
        case 5:
          setOptions(response.data); // FPD/Detector
          break;
        default:
          setModels(response.data); // Default to machines
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError("Error fetching data");
    }
  }, [selectedRegion, tabValue]);


  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const handleOpenPopup = () => {
    setIsMachineModelPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsMachineModelPopupOpen(false);
  };

  const handleAddMachineModel = (newModel) => {
    switch (tabValue) {
      case 0:
        setModels((prevModels) => [...prevModels, newModel]); // Machines
        break;
      case 1:
        setRacks((prevRacks) => [...prevRacks, newModel]); // Racks
        break;
      case 2:
        setTubes((prevTubes) => [...prevTubes, newModel]); // Tubes
        break;
      case 3:
        setDetector((prevDetector) => [...prevDetector, newModel]); // Detectors
        break;
      case 4:
        setSoftware((prevSoftware) => [...prevSoftware, newModel]); // Software
        break;
      case 5:
        setOptions((prevOptions) => [...prevOptions, newModel]); // Software
        break;
      default:
        setModels((prevModels) => [...prevModels, newModel]); // Default to Machines
    }
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  return (

    <div className="machine-model-container">
      <Button variant="contained" color="primary" sx={{ mb: 1 }} onClick={handleOpenPopup}>
        {GetTextByRegion("Add New Model", selectedRegion)}
      </Button>
      {isMachineModelPopupOpen && (
        <MachineModelForm tabValue={tabValue} onAdd={handleAddMachineModel} onClose={handleClosePopup} />
      )
      }
      <MachineModelTable
        data={models}
        setData={setModels}
        racks={racks}
        setRacks={setRacks}
        tubes={tubes}
        setTubes={setTubes}
        detector={detector}
        setDetector={setDetector}
        software={software}
        setSoftware={setSoftware}
        options={options}
        setOptions={setOptions}
        openEditDialog={openEditDialog}
        setOpenEditDialog={setOpenEditDialog}
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        tabValue={tabValue}
        setTabValue={setTabValue}
      />

      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </div>
  );
}

export default MachineModel;