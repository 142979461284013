import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, IconButton, Tabs, Tab, Typography, Tooltip } from '@mui/material';
import ErrorDialog from '../ErrorDialog';
import EditIcon from '@mui/icons-material/Edit';
import InventoryIcon from '@mui/icons-material/Inventory';
import DeleteIcon from '@mui/icons-material/Delete';
import useWindowSize from '../../hooks/useWindowSize';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import SearchBar from '../SearchBar';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import roles from '../../hooks/Roles';
import ChinaProductionForm from './ChinaProductionForm';
import MobileViewComponent from '../MobileView';
import { GetTextByRegion } from '../../hooks/Translation';
import ChinaProductionColumns from './ChinaProductionColumns';
import { useUser } from '../../hooks/UserContext';
import CustomDataGrid from '../CustomDataGrid'
import chinaProductionColumns from './ChinaProductionColumns';
import DateRangePicker from './DateRangePicker'; // Import your new component
import StatusProgressBar from '../StatusProgressBar';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const ChinaProductionTable = ({ machines, setMachines, openEditDialog, setOpenEditDialog, openDeleteDialog, setOpenDeleteDialog, tabValue, setTabValue }) => {
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [initialMachine, setInitialMachine] = useState(null);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredMachines, setFilteredMachines] = useState([]);
  const { windowSize, smallWidth } = useWindowSize();
  const { selectedRegion, user: { userRights } } = useUser();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isMachineDetailsOpen, setIsMachineDetailsOpen] = useState(false);


  useEffect(() => {
    const filteredByStage = machines.filter(machine => {
      if (tabValue === 3) { // Production tab
        return machine.stage === 'Demo';
      }
      else if (tabValue === 2) {
        return machine.stage === 'Shipped'
      }
      else if (tabValue === 1) {
        return machine.stage === 'Stock'
      }
      else {
        return machine.stage === 'Production'
      }
    });

    const filteredBySearch = filteredByStage.filter(machine =>
      (machine.machineModelAndConfig && machine.machineModelAndConfig.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.machineNo && machine.machineNo.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.poNumber && machine.poNumber.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.leadTimeOrDeliveryDate && machine.leadTimeOrDeliveryDate.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (machine.customer && machine.customer.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    // Date range filtering
    const filteredByDate = filteredBySearch.filter(machine => {
      if (!startDate && !endDate) return true;
      const machineDate = new Date(machine.leadTimeOrDeliveryDate);
      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;
      if (start && machineDate < start) return false;
      if (end && machineDate > end) return false;
      return true;
    });

    setFilteredMachines(filteredByDate);
  }, [searchQuery, machines, tabValue, startDate, endDate]);


  const handleEdit = (params) => {
    setSelectedMachine(params.row);
    setInitialMachine(params.row)
    setOpenEditDialog(true);
  };

  const handleDelete = (params) => {
    setSelectedMachine(params.row);
    setOpenDeleteDialog(true);
  };

  const handleEditSubmit = async () => {
    try {

      if (selectedMachine?.status && initialMachine?.status) {

        if (initialMachine.status !== "Test" && selectedMachine.status === "Test") { //notify china test
          const response = await axios.get(backendString + `/users/email/China Testing`);
          await axios.post(backendString + `/chinaproduction/send-email/testing`, {
            email: response.data.email,
            serial: selectedMachine.machineNo
          });
        }
        else if (initialMachine.status !== "QA/QC" && selectedMachine.status === "QA/QC") { //notify china QA/QC
          const response = await axios.get(backendString + `/users/email/China QAQC`);
          await axios.post(backendString + `/chinaproduction/send-email/qaqc`, {
            email: response.data.email,
            serial: selectedMachine.machineNo
          });
        }
        else if (initialMachine.status !== "Re-work" && selectedMachine.status === "Re-work") { //notify china QA/QC
          const response = await axios.get(backendString + `/users/email/China Testing`);
          await axios.post(backendString + `/chinaproduction/send-email/rework`, {
            email: response.data.email,
            serial: selectedMachine.machineNo
          });
          const response1 = await axios.get(backendString + `/users/email/China QCRelease`);
          await axios.post(backendString + `/chinaproduction/send-email/qcrelease`, {
            email: response1.data.email,
            serial: selectedMachine.machineNo
          });
        }
        else if (initialMachine.status !== "QC Release" && selectedMachine.status === "QC Release") { //notify china QA/QC
          const response = await axios.get(backendString + `/users/email/China QCRelease`);
          await axios.post(backendString + `/chinaproduction/send-email/qcrelease`, {
            email: response.data.email,
            serial: selectedMachine.machineNo
          });
        }
      }

      await axios.put(backendString + `/chinaproduction/${selectedMachine._id}`, selectedMachine, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        },
      });
      setOpenEditDialog(false);
      setMachines(prevData =>
        prevData.map(item =>
          item._id === selectedMachine._id ? selectedMachine : item
        )
      );
    } catch (error) {
      console.error('Error editing machine:', error);
      setError(error.response?.data?.error);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(backendString + `/chinaproduction/${selectedMachine._id}`, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });
      setOpenDeleteDialog(false);
      setMachines(prevData =>
        prevData.filter(item => item._id !== selectedMachine._id)
      );
    } catch (error) {
      console.error('Error deleting machine:', error);
      setError(error.response?.data?.error);
    }
  };

  const handleOpenInventory = (params) => {
    setSelectedMachine(params.row);
    setIsMachineDetailsOpen(true);
  };

  const handleCloseInventory = () => {
    setIsMachineDetailsOpen(false);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedMachine(null);
    setInitialMachine(null);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedMachine(null);
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setSelectedMachine(prevMachine => ({
      ...prevMachine,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const exportToExcel = () => {
    const dataToExport = filteredMachines.map(machine => {
      const { _id, financialRelease, ...rest } = machine;
      return {
        ...rest,
        financialRelease: financialRelease ? 'Yes' : 'No'
      };
    });
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'chinaProduction.xlsx');
  };

  const exportToCSV = () => {
    const dataToExport = filteredMachines.map(machine => {
      const { _id, financialRelease, ...rest } = machine;
      return {
        ...rest,
        financialRelease: financialRelease ? 'Yes' : 'No'
      };
    });
    const csvData = Papa.unparse(dataToExport);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'chinaProduction.csv';
    link.click();
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const tempColumns = ChinaProductionColumns
    .filter(column => column.required === true) // Filter by required: true
    .map(column => ({
      field: column.name,
      //headerName: GetTextByRegion(column.label, selectedRegion),
      flex: column.name === 'status' ? 2 : 1, // Increase flex for 'status' column
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => { 
        return (        
          <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion(column.label, selectedRegion)}<br /></Typography>
        );
      },
      renderCell: (params) => {
        if (column.name === 'financialRelease') {
          return params.value ? 'Yes' : 'No';
        } else if (column.name === 'status') {
          return <StatusProgressBar status={params.value} component={'chinaproduction'} selectedRegion={selectedRegion} />;
        } else {
          return params.value; // Render the cell value as-is for other columns
        }
      }
    }));


  const columns = [
    ...tempColumns,
    {
      field: 'actions',
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion("Actions", selectedRegion)}</Typography>
      ),
      minWidth: 150,
      renderCell: (params) => (
        <div>
          <Tooltip title={GetTextByRegion("Details", selectedRegion)} anrrow>
            <IconButton aria-label="inventory" onClick={() => handleOpenInventory(params)}>
              <InventoryIcon />
            </IconButton>
          </Tooltip>
          {(userRights === roles['SALES'] || userRights > roles['PRODUCTION']) && selectedRegion === "CHINA" && (
            <>
              <Tooltip title={GetTextByRegion("Edit", selectedRegion)} anrrow>
                <IconButton aria-label="edit" color="primary" onClick={() => handleEdit(params)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
          {userRights >= roles['MANAGER'] && selectedRegion === "CHINA" && (
            <Tooltip title={GetTextByRegion("Delete", selectedRegion)} anrrow>
              <IconButton aria-label="delete" color="secondary" onClick={() => handleDelete(params)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  return (
    <div style={{ height: windowSize.height * 0.72, width: windowSize.width * 1 }}>
      <div style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: !windowSize.width > smallWidth ? 'flex-end' : 'center',
        flexDirection: windowSize.width <= smallWidth ? 'column' : 'row', // Column for mobile, row for larger screens
      }}>
        <SearchBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          placeholder={GetTextByRegion("Search Machines...", selectedRegion)}
        />
        {userRights >= roles['SERVICE'] && (
          <div style={{
            display: 'flex',
            flexDirection: 'row', // Stack buttons in column for mobile
            position: windowSize.width > smallWidth ? 'absolute' : 'relative', // Absolute on larger screens
            left: windowSize.width > smallWidth ? 0 : 'auto', // Align left on larger screens
          }}>
            <Button onClick={exportToCSV} sx={{ marginRight: 1 }}>
              {GetTextByRegion("Export to CSV", selectedRegion)}
            </Button>
            <Button onClick={exportToExcel}>
              {GetTextByRegion("Export to Excel", selectedRegion)}
            </Button>
          </div>
        )}

        {/* <DateRangePicker startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} /> */}
      </div>


      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label={GetTextByRegion("In Production", selectedRegion)} />
        <Tab label={GetTextByRegion("Stock", selectedRegion)} />
        <Tab label={GetTextByRegion("Shipped", selectedRegion)} />
        <Tab label={GetTextByRegion("Demo", selectedRegion)} />
      </Tabs>

      {windowSize.width > smallWidth ? (
        <CustomDataGrid rows={filteredMachines} columns={columns} />
      ) : (<MobileViewComponent
        items={filteredMachines}
        columnData={ChinaProductionColumns}
        handleEditClick={selectedRegion === "CHINA" ? handleEdit : null}
        handleDelete={selectedRegion === "CHINA" ? handleDelete : null}
        handleView={handleOpenInventory}
        selectedRegion={selectedRegion}
        component={'chinaproduction'} />
      )}



      {openEditDialog && (
        <ChinaProductionForm
          open={openEditDialog}
          onClose={handleCloseEditDialog}
          machineData={selectedMachine}
          onChange={handleInputChange}
          onSubmit={handleEditSubmit}
          mode="edit"
          viewOnly={false}
        />
      )}

      {isMachineDetailsOpen && (
        <ChinaProductionForm
          open={isMachineDetailsOpen}
          onClose={handleCloseInventory}
          machineData={selectedMachine}
          onChange={handleInputChange}
          onSubmit={handleEditSubmit}
          mode="view"
          viewOnly={true}
        />
      )}

      <DeleteConfirmationDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDeleteConfirm}
        selectedRegion={selectedRegion}
      />

      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </div>
  );
};

export default ChinaProductionTable;