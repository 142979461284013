const ItemRequestColumns = [
    { label: 'Submitted Date', name: 'submittedDate', type: 'date', flex: 1 },
    { label: 'Customer Name', name: 'customerName', type: 'text', flex: 1 },
    { label: 'Part Number', name: 'partNumber', type: 'text', flex: 2 },
    { label: 'Quantity', name: 'quantity', type:"number", flex: 1 },
    { label: 'Model', name: 'model',  type: 'text', flex: 1 },
    { label: 'Serial Number', name: 'serial', type: 'text', flex: 1 },
    { label: 'Notes', name: 'notes', type: 'text', flex: 2 },
    { label: 'Submitted By', name: 'submittedBy', type: 'text', flex: 1 },
    { label: 'Progress', name: 'status', type: 'progress', flex: 1 },
  ];
  
  export default ItemRequestColumns;