import React, { useState } from 'react';
import axios from 'axios';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, TextField, Tabs, Tab } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useWindowSize from '../../hooks/useWindowSize';
import ErrorDialog from '../ErrorDialog';
import CustomDataGrid from '../CustomDataGrid';
import MobileViewComponent from '../MobileView';
import { useUser } from '../../hooks/UserContext';
import { GetTextByRegion } from '../../hooks/Translation';
import roles from '../../hooks/Roles';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const MachineModelTable = ({ data, setData, racks, setRacks, tubes, setTubes, detector, setDetector, software, setSoftware, options, setOptions, openEditDialog, setOpenEditDialog, openDeleteDialog, setOpenDeleteDialog, tabValue, setTabValue }) => {
  const [selectedItem, setSelectedItem] = useState({ _id: '', ModelName: '' });
  const [error, setError] = useState(null);
  const { windowSize, smallWidth } = useWindowSize();
  const { user: { userRights }, selectedRegion } = useUser();

  const tabTypeMap = {
    0: 'machine',
    1: 'rack',
    2: 'tube',
    3: 'fpd',
    4: 'software',
    default: 'option'
  };

  const handleDelete = (params) => {
    setSelectedItem(params.row);
    setOpenDeleteDialog(true);
  };

  const handleEdit = (params) => {
    setSelectedItem(params.row);
    setOpenEditDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedItem({ _id: '', ModelName: '' }); // Reset selectedItem
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedItem({ _id: '', ModelName: '' }); // Reset selectedItem
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedItem(prevSelectedItem => ({
      ...prevSelectedItem,
      [name]: value
    }));
  };

  const handleDeleteConfirm = async () => {
    try {
      // Determine type and update function based on tab value
      const type = tabTypeMap[tabValue] || tabTypeMap.default;
      const stateUpdateMap = {
        0: setData,
        1: setRacks,
        2: setTubes,
        3: setDetector,
        4: setSoftware,
        5: setOptions,
      };

      // Perform delete request
      await axios.delete(`${backendString}/dropdown/${selectedItem._id}?selectedRegion=${selectedRegion}&type=${type}`, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });

      setOpenDeleteDialog(false);

      // Update the corresponding data state
      const updateFunction = stateUpdateMap[tabValue];
      if (updateFunction) {
        updateFunction(prevState => prevState.filter(item => item._id !== selectedItem._id));
      }
    } catch (error) {
      console.error('Error deleting item:', error);
      setError(error.response?.data?.error);
    }
  };

  const handleEditSubmit = async () => {
    try {
      // Determine type based on tab value
      // const type = tabValue === 0 ? 'machine' : tabValue === 1 ? 'tube' : tabValue === 2 ? 'fpd' : tabValue === 3 ? 'software' : 'option';
      const type = tabTypeMap[tabValue] || tabTypeMap.default;

      await axios.put(`${backendString}/dropdown/${selectedItem._id}?selectedRegion=${selectedRegion}&type=${type}`, selectedItem, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });

      setOpenEditDialog(false);

      // Update the correct data array based on tab value
      switch (tabValue) {
        case 0:
          setData(prevData =>
            prevData.map(item => item._id === selectedItem._id ? selectedItem : item)
          );
          break;
        case 1:
          setRacks(prevRacks =>
            prevRacks.map(item => item._id === selectedItem._id ? selectedItem : item)
          );
          break;
        case 2:
          setTubes(prevTubes =>
            prevTubes.map(item => item._id === selectedItem._id ? selectedItem : item)
          );
          break;
        case 3:
          setDetector(prevDetector =>
            prevDetector.map(item => item._id === selectedItem._id ? selectedItem : item)
          );
          break;
        case 4:
          setSoftware(prevSoftware =>
            prevSoftware.map(item => item._id === selectedItem._id ? selectedItem : item)
          );
          break;
        case 5:
          setOptions(prevOptions =>
            prevOptions.map(item => item._id === selectedItem._id ? selectedItem : item)
          );
          break;
        default:
          break;
      }
    } catch (error) {
      console.error('Error editing item:', error);
      setError(error.response?.data?.error);
    }
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // const sortedData = (() => {
  //   switch (tabValue) {
  //     case 0:
  //       return data?.slice().sort((a, b) => a.ModelName.localeCompare(b.ModelName)) || [];
  //     case 1:
  //       return tubes?.slice().sort((a, b) => a.ModelName.localeCompare(b.ModelName)) || [];
  //     case 2:
  //       return detector?.slice().sort((a, b) => a.ModelName.localeCompare(b.ModelName)) || [];
  //     case 3:
  //       return software?.slice().sort((a, b) => a.ModelName.localeCompare(b.ModelName)) || [];
  //     case 4:
  //       return options?.slice().sort((a, b) => a.ModelName.localeCompare(b.ModelName)) || [];
  //     default:
  //       return data?.slice().sort((a, b) => a.ModelName.localeCompare(b.ModelName)) || [];
  //   }
  // })();
  const sortedData = (() => {
    switch (tabValue) {
      case 0:
        return data?.filter(item => item?.ModelName).slice().sort((a, b) => a.ModelName.localeCompare(b.ModelName)) || [];
      case 1:
        return racks?.filter(item => item?.ModelName).slice().sort((a, b) => a.ModelName.localeCompare(b.ModelName)) || [];
      case 2:
        return tubes?.filter(item => item?.ModelName).slice().sort((a, b) => a.ModelName.localeCompare(b.ModelName)) || [];
      case 3:
        return detector?.filter(item => item?.ModelName).slice().sort((a, b) => a.ModelName.localeCompare(b.ModelName)) || [];
      case 4:
        return software?.filter(item => item?.ModelName).slice().sort((a, b) => a.ModelName.localeCompare(b.ModelName)) || [];
      case 5:
        return options?.filter(item => item?.ModelName).slice().sort((a, b) => a.ModelName.localeCompare(b.ModelName)) || [];
      default:
        return data?.filter(item => item?.ModelName).slice().sort((a, b) => a.ModelName.localeCompare(b.ModelName)) || [];
    }
  })();


  // Table columns
  const columns = [
    { field: 'ModelName', headerName: GetTextByRegion('Model', selectedRegion), flex: 6 },
    {
      field: 'actions',
      headerName: GetTextByRegion('Actions', selectedRegion),
      flex: 1,
      renderCell: (params) =>
        userRights >= roles['MANAGER'] && (
          <div>
            <IconButton aria-label="edit" color="primary" onClick={() => handleEdit(params)}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label="delete" color="secondary" onClick={() => handleDelete(params)}>
              <DeleteIcon />
            </IconButton>
          </div>
        )
    }
  ];

  const mobileColumns = [
    { label: GetTextByRegion("Model", selectedRegion), name: "ModelName", type: "text", value: "", required: true, InputLabelProps: {}, position: 'left' },
  ];

  return (
    <div style={{ height: windowSize.height * 0.8, width: windowSize.width * 1 }}>

      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label={GetTextByRegion("Machines", selectedRegion)} />
        <Tab label={GetTextByRegion("Racks", selectedRegion)} />
        <Tab label={GetTextByRegion("Tube", selectedRegion)} />
        <Tab label={GetTextByRegion("Detector", selectedRegion)} />
        <Tab label={GetTextByRegion("Software", selectedRegion)} />
        {selectedRegion === 'CHINA' && (<Tab label={GetTextByRegion("Options", selectedRegion)} />)}
      </Tabs>
      {windowSize.width < smallWidth ? (
        <MobileViewComponent items={sortedData} columnData={mobileColumns} handleEditClick={handleEdit} handleDelete={handleDelete} />
      ) : (
        <CustomDataGrid
          rows={sortedData}
          columns={columns}
        />

      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>{GetTextByRegion("Delete Model", selectedRegion)}</DialogTitle>
        <DialogContent>
          {GetTextByRegion("Are you sure you want to delete this model?", selectedRegion)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>{GetTextByRegion("Cancel", selectedRegion)}</Button>
          <Button onClick={handleDeleteConfirm} variant="contained" color="secondary">{GetTextByRegion("Delete", selectedRegion)}</Button>
        </DialogActions>
      </Dialog>

      {/* Edit machine model Dialog */}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>{GetTextByRegion("Edit Model", selectedRegion)}</DialogTitle>

        <DialogContent>
          <TextField
            label={GetTextByRegion("Model Name", selectedRegion)}
            variant="outlined"
            name="ModelName"
            value={selectedItem.ModelName || ''}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
            type="text"
            sx={{ mt: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="secondary" >{GetTextByRegion("Cancel", selectedRegion)}</Button>
          <Button onClick={handleEditSubmit} variant="contained" color="primary">{GetTextByRegion("Save", selectedRegion)}</Button>
        </DialogActions>
      </Dialog>

      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />

    </div>


  );
};

export default MachineModelTable;
