import React, { useState } from 'react';
import { Box, Paper, IconButton, Pagination, Typography, Tooltip } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import roles from '../hooks/Roles';
import '../styles/AfterSalesTableStyles.css';
import { lighten } from '@mui/material/styles';
import placeholderImage from '../images/placeholder-image.jpg';
import { GetTextByRegion } from '../hooks/Translation';
import StatusProgressBar from './StatusProgressBar';
import { useUser } from '../hooks/UserContext';

const MobileViewComponent = ({ items = [], columnData = [], handleEditClick = null, handleDelete = null, handleView = null, handleGeneratePDF = null, handleConfirmCompleted = null, handleShippingEdit = null,connectionString = null, selectedRegion = null, isReport = false, isItemRequest = false, component=null }) => {

  const [page, setPage] = useState(1); // Current page state
  const itemsPerPage = 10; // Number of items per page
  const { user: { userRights } } = useUser();

  // Calculate the items to be displayed on the current page
  const paginatedItems = items.slice((page - 1) * itemsPerPage, page * itemsPerPage);
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {paginatedItems.length === 0 && (<Box> {GetTextByRegion("No data", selectedRegion)} </Box>)}
      {paginatedItems.map((rowData) => (
        <Paper
          key={rowData._id}
          sx={{
            width: '90%',
            margin: '10px 0',
            padding: '10px',
            border: '2px solid',                 // Add border with custom color
            borderColor: lighten('#1976d2', 0.5), // Use a slightly darker shade for the border
            backgroundColor: rowData.urgent > 0 && '#FF7300'
          }}
        >
          <Box>
          <table className='aftersale_table' style={{ width: '100%', tableLayout: 'fixed' }}>
            <tbody>
              {columnData.map((header) => (
                <tr className='aftersale_tr' key={header.name}>
                  <td className='aftersale_td' style={{ width: '25%', overflowWrap: 'break-word', wordBreak: 'break-word' }}>
                    <strong>{GetTextByRegion(header.label,selectedRegion)}</strong>
                  </td>
                  <td className='aftersale_td' style={{ width: '75%', overflowWrap: 'break-word', wordBreak: 'break-word' }}>
                    {header.type === "image" ? (
                      <img
                        src={rowData.image ? `${connectionString}/images/${rowData.image}?selectedRegion=${selectedRegion}` : placeholderImage}
                        alt="Item"
                        style={{ width: '100px', height: '100px', objectFit: 'cover', marginRight: '16px', borderRadius: '10%' }}
                      />
                    ) : header.type === "boolean" ? (
                      String(rowData[header.name])
                    ) : header.name === "color" ? (
                      <Box
                        width={20}
                        height={20}
                        bgcolor={rowData[header.name] || '#d3d3d3'}
                        borderRadius="50%"
                      />
                    ) : Array.isArray(rowData[header.name]) ? (
                      rowData[header.name].join(', ')
                    ) : header.type === "progress" ? (
                      <StatusProgressBar status={rowData[header.name]} component={component} selectedRegion={selectedRegion} />
                    ) : header.name === "quantity" && rowData.minQuantity !== undefined ? (
                      <Typography 
                        variant="body2" 
                        component="span" 
                        style={{ color: rowData.quantity <= rowData.minQuantity ? 'red' : 'inherit' }}
                      >
                        {rowData[header.name]}
                      </Typography>
                    ) : header.name==="price"?(
                      rowData[header.name]?('$'+rowData[header.name]):""
                    ):(
                      rowData[header.name]
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </Box>
          <Box display="flex" justifyContent="flex-end" marginTop="10px">
            {userRights >= roles['SERVICE'] && handleView && (
              <Tooltip title={GetTextByRegion("View", selectedRegion)} arrow>
                <IconButton aria-label="view" color="primary" onClick={() => handleView({ row: rowData })}>
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            )}
            {userRights >= roles['PRODUCTION'] && handleGeneratePDF && (
              <Tooltip title={GetTextByRegion("Delete", selectedRegion)} arrow>
                <IconButton aria-label="delete" color="success" onClick={() => handleGeneratePDF({ row: rowData })}>
                  <FileDownloadIcon />
                </IconButton>
              </Tooltip>
            )}
            {userRights >= roles['SERVICE'] && handleEditClick && (
              <>
                {isReport ? (
                  rowData.status === "Approved" && (
                    <Tooltip title={GetTextByRegion("Edit", selectedRegion)} arrow>
                      <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick({ row: rowData })}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  )
                ) : (
                  <Tooltip title={GetTextByRegion("Edit", selectedRegion)} arrow>
                    <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick({ row: rowData })}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
            {userRights >= roles['MODERATOR'] && handleShippingEdit && (
              <>
                {isItemRequest ? (
                  rowData.status === "finished" && (
                    <Tooltip title={GetTextByRegion("Edit", selectedRegion)} arrow>
                      <IconButton aria-label="edit" color="primary" onClick={() => handleShippingEdit({ row: rowData })}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  )
                ) : (
                  <Tooltip title={GetTextByRegion("Edit", selectedRegion)} arrow>
                    <IconButton aria-label="edit" color="primary" onClick={() => handleShippingEdit({ row: rowData })}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
            {userRights >= roles['MODERATOR'] && handleConfirmCompleted && rowData.status === 'finished' && (
              <Tooltip title={GetTextByRegion("Comlete", selectedRegion)} arrow>
                <IconButton aria-label="edit" color="success" onClick={() => handleConfirmCompleted({ row: rowData })}>
                  <CheckIcon />
                </IconButton>
              </Tooltip>
            )}
            {userRights >= roles['MANAGER'] && handleDelete && (
              <Tooltip title={GetTextByRegion("Delete", selectedRegion)} arrow>
                <IconButton aria-label="delete" color="secondary" onClick={() => handleDelete({ row: rowData })}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Paper>
      ))}

      {/* Pagination Component */}
      <Pagination
        count={Math.ceil(items.length / itemsPerPage)}
        page={page}
        onChange={handlePageChange}
        color="primary"
        style={{ marginTop: '20px' }}
      />
    </Box>
  );
};

export default MobileViewComponent;
