import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from '../hooks/UserContext';

const UserProtectedRoute = () => {
  const { user, error } = useUser();

  if (!user && !error) {
    return <div>Loading...</div>; // Display a loading indicator while user rights are being fetched
  }

  return !error ? <Outlet /> : <Navigate to="/login" />;
};

export default UserProtectedRoute;