import { IconButton, Tooltip } from '@mui/material';
import { GetTextByRegion } from '../../hooks/Translation';
import DeleteIcon from '@mui/icons-material/Delete';
import { useUser } from '../../hooks/UserContext';
const IconDeleteButton = ({ onClickEvent, params }) => {
    const { selectedRegion } = useUser();
    return (
        <Tooltip title={GetTextByRegion("Delete", selectedRegion)} arrow>
            <IconButton aria-label="delete" color="secondary" onClick={() => onClickEvent(params)}>
                <DeleteIcon />
            </IconButton>
        </Tooltip>
    );
}
export default IconDeleteButton;