// src/components/AlertDialog.js
import React, { useState } from 'react';
import axios from 'axios';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button
} from '@mui/material';
import { GetTextByRegion } from '../../hooks/Translation';
import ErrorDialog from '../ErrorDialog';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const AlertDialog = ({ open, onClose, selectedRegion }) => {
  const [email, setEmail] = useState('');
  const [notes, setNotes] = useState('');
  const [error, setError] = useState(null); // To handle potential errors

  const handleClose = () => {
    onClose();
    // Optionally reset email and notes
    setEmail('');
    setNotes('');
  };

  const handleSubmit = async () => {
    try {
      // Send the email and notes to the backend
      const response = await axios.post(`${backendString}/chinaproduction/send-email/deliveryAlert`, {
        email,
        notes,
      }, {
        headers: {
          'Authorization': sessionStorage.getItem('token') // Add authentication token if needed
        }
      });

      handleClose();
    } catch (err) {
      console.error('Error submitting email and notes:', err);
      setError('Failed to submit. Please try again.');
    }
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{GetTextByRegion("Delivery Date Alert", selectedRegion)}</DialogTitle>
        <DialogContent>
          <TextField
            label={GetTextByRegion("Email", selectedRegion)}
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="dense"
          />
          <TextField
            label={GetTextByRegion("Notes", selectedRegion)}
            multiline
            rows={4}
            fullWidth
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </>
  );
};

export default AlertDialog;
