import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { GetTextByRegion } from '../hooks/Translation';

const DeleteConfirmationDialog = ({ open, onClose, onConfirm, selectedRegion}) => {  
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{GetTextByRegion("Delete Item",selectedRegion)}</DialogTitle>
      <DialogContent>{GetTextByRegion("Are you sure you want to delete this item?",selectedRegion)}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{GetTextByRegion("Cancel",selectedRegion)}</Button>
        <Button onClick={onConfirm} variant="contained" color="secondary">{GetTextByRegion("Delete",selectedRegion)}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;