import { useState, useEffect } from 'react';
const smallWidth = 1150; //trigger mobileView
// const maxWidth = 1420; //Reduce Navbar DELETE
const maxWidth = 1320; // Navbar <-> Hamburger

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {windowSize, smallWidth, maxWidth};
}

export { smallWidth, maxWidth }
export default useWindowSize;
