import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';
import PropTypes from 'prop-types';

const IconRejectButton = ({ onClickEvent }) => {
    const { selectedRegion } = useUser();


    return (
        <Tooltip title={GetTextByRegion("Reject", selectedRegion)} arrow>
            <IconButton color="error" onClick={onClickEvent}>
                <CancelIcon />
            </IconButton>
        </Tooltip>
    );


}

export default IconRejectButton;
