import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import CustomerForm from '../components/Customer/CustomerForm.js';
import CustomerTable from '../components/Customer/CustomerTable.js';
import { useUser, useValidateRegions } from '../hooks/UserContext.js';

const Customer = () => {
  const [isCustomerPopupOpen, setIsCustomerPopupOpen] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [customers, setCustomers] = useState([]);
  const validateSelectedRegion = ["USA"];
  const {selectedRegion, refreshUser} = useUser();
  useValidateRegions(selectedRegion,validateSelectedRegion);
  useEffect(()=> {
    refreshUser();
  },[refreshUser,isCustomerPopupOpen,openEditDialog,openDeleteDialog]);

  const handleOpenPopup = () => {
    setIsCustomerPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsCustomerPopupOpen(false);
  };

  const handleAddCustomer = (newCustomer) => {
    setCustomers([...customers, newCustomer]);
  };

  return (
    <div>
        <Button variant="contained" color="primary" onClick={handleOpenPopup}>
        Add New Customer
        </Button>
        {isCustomerPopupOpen && (
        <CustomerForm  onAdd={handleAddCustomer} onClose={handleClosePopup}  />
        )}
        <CustomerTable 
          customers={customers} 
          setCustomers={setCustomers} 
          openEditDialog={openEditDialog} 
          setOpenEditDialog={setOpenEditDialog} 
          openDeleteDialog={openDeleteDialog} 
          setOpenDeleteDialog={setOpenDeleteDialog}/>
    </div>
  );
};

export default Customer;