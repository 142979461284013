import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
  Typography,
  IconButton,
  Divider
} from '@mui/material';
import { GetTextByRegion } from '../../hooks/Translation';
import AddIcon from '@mui/icons-material/Add';
import { useUser } from '../../hooks/UserContext';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const ChinaReportForm = ({ open, onClose, onSubmit, initialValues }) => {
  const [formValues, setFormValues] = useState({
    dateReported: '',
    model: '',
    reportNumber: '',
    serialNumber: '',
    customer: '',
    reportBy: '',
    issueType: '',
    problem: '',
    description: '',
    suggestedSolution: '',
    formName: '',
    reportedRegion: '',
    // dateResolved: '',
    // details: '',
    entry: [{ _id: 0, dateResolved: '', details: '' }], // initialize as array
    dateResolvedPermanent: '',
    ECN: '',
  });

  const { selectedRegion } = useUser();

  useEffect(() => {
    if (initialValues) {
      setFormValues(initialValues);
    }
    else {
      setFormValues({
        dateReported: '',
        model: '',
        reportNumber: '',
        serialNumber: '',
        customer: '',
        reportBy: '',
        issueType: '',
        problem: '',
        description: '',
        suggestedSolution: '',
        formName: '',
        reportedRegion: '',
        // dateResolved: '',
        // details: '',
        entry: [{ _id: 0, dateResolved: '', details: '' }], // initialize as array
        dateResolvedPermanent: '',
        ECN: '',
      });
    }
  }, [initialValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleEntryChange = (index, field, value) => {
    setFormValues(prevValues => {
      const updatedEntries = [...prevValues.entry];
      updatedEntries[index] = { ...updatedEntries[index], [field]: value };
      return { ...prevValues, entry: updatedEntries };
    });
  };

  const handleFileChange = (e, index) => {
    const files = Array.from(e.target.files); // Get the files from the event
    setFormValues(prevValues => {
      const updatedEntries = [...prevValues.entry];
      updatedEntries[index] = {
        ...updatedEntries[index],
        files: [...updatedEntries[index].files, ...files] // Append new files to existing ones
      };
      return { ...prevValues, entry: updatedEntries };
    });
  };


  const addNewEntry = () => {
    setFormValues(prevValues => ({
      ...prevValues,
      entry: [...prevValues.entry, { _id: prevValues.entry.length, dateResolved: '', details: '' }],
    }));
  };

  const handleSubmit = () => {
    onSubmit(formValues);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{GetTextByRegion("Nonconforming Report", selectedRegion)}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled
              label={GetTextByRegion("Date Reported", selectedRegion)}
              type="date"
              name="dateReported"
              value={formValues.dateReported}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled
              label={GetTextByRegion("Model", selectedRegion)}
              name="model"
              value={formValues.model}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled
              label={GetTextByRegion("Report Number", selectedRegion)}
              name="reportNumber"
              value={formValues.reportNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled
              label={GetTextByRegion("Serial Number", selectedRegion)}
              name="serialNumber"
              value={formValues.serialNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled
              label={GetTextByRegion("Customer", selectedRegion)}
              name="customer"
              value={formValues.customer}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled
              label={GetTextByRegion("Report By", selectedRegion)}
              name="reportBy"
              value={formValues.reportBy}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled
              label={GetTextByRegion("Issue Type", selectedRegion)}
              name="issueType"
              value={formValues.issueType}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              label={GetTextByRegion("Problem", selectedRegion)}
              name="problem"
              value={formValues.problem}
              onChange={handleChange}
              multiline
              rows={2}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              label={GetTextByRegion("Description", selectedRegion)}
              name="description"
              value={formValues.description}
              onChange={handleChange}
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              label={GetTextByRegion("Suggested Solution", selectedRegion)}
              name="suggestedSolution"
              value={formValues.suggestedSolution}
              onChange={handleChange}
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12} mb={6}>
            <label>{GetTextByRegion("Images", selectedRegion)}:</label>
            <Grid container spacing={2}>
              {initialValues.images && initialValues.images.length > 0 ? (
                initialValues.images.map((image, index) => (
                  <Grid item xs={3} key={index}>
                    <a
                      href={`${backendString}/nonconforming/images/` + image + `?reportedRegion=${formValues.reportedRegion}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={`${backendString}/nonconforming/images/` + image + `?reportedRegion=${formValues.reportedRegion}`} // Assuming `image` contains the URL or base64 encoded data
                        alt={`Preview ${index + 1}`}
                        style={{ width: '100%', height: '100%', borderRadius: 8 }}
                      />
                    </a>
                  </Grid>
                ))
              ) : (
                <Typography mt={3} ml={2} >{GetTextByRegion("No images to preview", selectedRegion)}</Typography>
              )}
            </Grid>
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              fullWidth
              label={GetTextByRegion("Date Resolved", selectedRegion)}
              type="date"
              name="dateResolved"
              value={formValues.dateResolved}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={GetTextByRegion("Details", selectedRegion)}
              name="details"
              value={formValues.details}
              onChange={handleChange}
            />
          </Grid> */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {GetTextByRegion("Filled in by CHINA", selectedRegion)}
            </Typography>
            <Divider fullWidth/>
          </Grid>

          {formValues.entry.map((entry, index) => (
            <React.Fragment key={index}>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label={GetTextByRegion("Date Resolved", selectedRegion)}
                  type="date"
                  value={entry.dateResolved}
                  onChange={(e) => handleEntryChange(index, 'dateResolved', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  multiline
                  label={GetTextByRegion("Details", selectedRegion)}
                  value={entry.details}
                  onChange={(e) => handleEntryChange(index, 'details', e.target.value)}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <Typography variant="body1"> {GetTextByRegion("Upload Images", selectedRegion)}:</Typography>
                <Button
                  variant="contained"
                  component="label"
                  color="primary"
                >
                  {GetTextByRegion("Upload File", selectedRegion)}
                  <input
                    type="file"
                    hidden
                    multiple
                    onChange={(e) => handleFileChange(e, index)} // Pass the index here
                  />
                </Button>

                <div>
                  {entry.files && entry.files.length > 0 && (
                    entry.files.map((file, fileIndex) => (
                      <Typography key={fileIndex} variant="body2">
                        {file.name}
                      </Typography>
                    ))
                  )}
                </div>
              </Grid> */}
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            <IconButton onClick={addNewEntry} color="primary">
              <AddIcon />
            </IconButton>
            <Typography>{GetTextByRegion("Add Entry", selectedRegion)}</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label={GetTextByRegion("Date Resolved Permanent", selectedRegion)}
              type="date"
              name="dateResolvedPermanent"
              value={formValues.dateResolvedPermanent}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={GetTextByRegion("ECN", selectedRegion)}
              name="ECN"
              value={formValues.ECN}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">{GetTextByRegion("Cancel", selectedRegion)}</Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">{GetTextByRegion("Submit", selectedRegion)}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChinaReportForm;
