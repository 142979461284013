import React, { useState } from 'react';
import { Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import MachineSoldPiechart from "../components/Graphs/MachineSoldPiechart"; 

const MachineStatisticsView = () => {
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    // Function to create year options for the Select component
    const setYearsOptions = () => {
        const years = Array.from({ length: 10 }, (_, index) => selectedYear - 5 + index);
        return years.map((year) => (
            <MenuItem key={year} value={year}>
                {year}
            </MenuItem>
        ));
    };

    return (
        <Box>
            <FormControl fullWidth>
                <InputLabel>Year</InputLabel>
                <Select
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                    label="Year"
                >
                    {setYearsOptions()}
                </Select>
            </FormControl>
            <MachineSoldPiechart selectedYear={selectedYear} />
        </Box>
    );
};

export default MachineStatisticsView;
