import scienscopelogo from '../../images/scienscopeLogo.png';
import approveStamp from '../../images/ApproveImage.png';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
//ttf files
import '../../misc/NotoSansSC-Regular.ttf';
import '../../misc/NotoSansSC-Bold.ttf';
//font base64
import fontBase64_regular from '../../misc/NotoScansSC-Regular';
import fontBase64_bold from '../../misc/NotoScansSC-Bold';


export const generateItemRequestPDF = (data, onSuccess, onError, selectedRegion) => {
    const footerHeight = 30;
    const pageMargin = 10;

    /*////////////////////////////////////////////////////////////
    //    Add text with a Underline and give it a fixed width  //
    //////////////////////////////////////////////////////////////*/
    const addTextWithUnderlines = (doc, text, x, y, maxWidth, pageMargin, contentMargin) => {

        let currentX = x;   //start x
        let currentY = y;   //start y

        const textHeight = doc.getTextDimensions(text).h;  //text height
        const pageHeight = doc.internal.pageSize.height;

        //return if text is empty
        if (!text || text === '') {
            return y + textHeight;
        }

        //split the text with a given width
        const splitText = doc.splitTextToSize(text, maxWidth - 2 * contentMargin);

        for (const line of splitText) {
            if (currentY + textHeight * 1.2 > pageHeight - pageMargin * 2) {
                doc.addPage();
                currentY = pageMargin;
                currentY += textHeight + 1;
            }
            doc.text(line, currentX, currentY);
            doc.line(currentX - contentMargin, currentY + 1, x + maxWidth - contentMargin, currentY + 1);
            currentY += textHeight + 1;
        }
        return currentY;

    };

    /*/////////////////////////////////////////////////
    //    Add text and check if it fits on the page  //
    ///////////////////////////////////////////////////*/
    const addText = (doc, text, x, y, Yoffset) => {
        const pageHeight = doc.internal.pageSize.height - footerHeight;
        const pagewidth = doc.internal.pageSize.width;
        const textHeight = doc.getTextDimensions(text).h;
        const pageMargin = 10;

        text = text.replace(/\n/g, ' ');

        const lines = [];
        let line = '';
        const words = text.split(' ');
        for (const word of words) {
            const testLine = line + (line ? ' ' : '') + word;
            const testWidth = doc.getTextWidth(testLine) + pageMargin * 2;
            if (testWidth > pagewidth) {
                lines.push(line);
                line = word;
            } else {
                line = testLine;
            }
        }
        if (line) {
            lines.push(line);
        }

        for (const row of lines) {
            if (y + textHeight * 1.2 > pageHeight - pageMargin * 2) {
                doc.addPage();
                addApproveStamp(doc, pagewidth, pageHeight, pageMargin);
                y = pageMargin;
                y += Yoffset;
            }
            doc.text(row, x, y);
            y += Yoffset;
        }
        if (lines.length === 0) {
            return y + Yoffset;
        } else {
            return y;
        }
    }

    /*////////////////////////////////////////////////////////////
    //    Add text with a box and check if it fits on the page  //
    //////////////////////////////////////////////////////////////*/
    const addTextWithRect = (doc, text, x, y, maxBoxWidth, contentMargin = 5) => {

        let currentY = y;
        let currentX = x;
        if (currentX < pageMargin) {
            currentX = pageMargin;
        }
        let startIndex = 0;

        const pageHeight = doc.internal.pageSize.height - footerHeight;
        const pageWidth = doc.internal.pageSize.width;
        const textHeight = doc.getTextDimensions(text).h;
        const lineHeight = textHeight * 1.2;

        if (maxBoxWidth > pageWidth) {
            maxBoxWidth = pageWidth - 2 * pageMargin;
        }

        //split the text with a given width
        const splitText = doc.splitTextToSize(text, maxBoxWidth - 2 * contentMargin);

        while (startIndex < splitText.length) {
            // calculate current lines. = avaliable area height / line height
            const linesToShow = Math.round((pageHeight - currentY - 2 * contentMargin - pageMargin) / lineHeight);
            // calculate how much area it will take
            const lines = splitText.slice(startIndex, startIndex + linesToShow);
            const boxHeight = (lines.length) * lineHeight;

            // draw text.   
            doc.text(lines, x + contentMargin, currentY + lineHeight + contentMargin);
            doc.rect(x, currentY, maxBoxWidth, boxHeight + 2 * contentMargin);

            // update start index and current position y after draw the box
            startIndex += linesToShow;
            currentY += boxHeight + 2 * contentMargin; //top and bottom content margin

            // if there are more lines
            if (startIndex < splitText.length) {
                doc.addPage(); //new page
                currentY = pageMargin; // reset y position
                addApproveStamp(doc, pageWidth, pageHeight, pageMargin);
            }
        }
        return currentY + lineHeight + 2 * contentMargin;;
    }

    const formatDate = (date) => {
        const month = date.getMonth() + 1; // getMonth() start from 0
        const day = date.getDate();
        const year = date.getFullYear();
        return `${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}${year}`;
    }

    // the approve stamp will be displyed on fixed location
    const addApproveStamp = (doc, pageWidth, pageHeight) => {
        //Approve note//////////////////////////////
        const approveImgData = new Image();
        approveImgData.src = approveStamp;
        const approveImgHeight = 20;
        const approveImgWidth = 60;
        doc.addImage(approveImgData, "PNG", pageWidth - pageMargin - approveImgWidth, pageHeight - pageMargin - approveImgHeight + 20, approveImgWidth, approveImgHeight);

    }

    try {

        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight() - footerHeight;
        const pageCenterX = (pageWidth - 2 * pageMargin) / 2;
        const Yoffset = doc.getTextDimensions("W").h * 1.2;

        //font (supports chinese)
        doc.addFileToVFS('NotoSansSC-Regular.ttf', fontBase64_regular);
        doc.addFileToVFS('NotoSansSC-Bold.ttf', fontBase64_bold);
        doc.addFont('NotoSansSC-Regular.ttf', 'NotoScansSC', 'normal');
        doc.addFont('NotoSansSC-Bold.ttf', 'NotoScansSC', 'bold');

        //x y begin positions (should be below the Logo image)
        let x1 = pageMargin;
        let y1 = pageMargin;

        const tableWidth = pageWidth - 2 * pageMargin;

        const blackColorCode = [0, 0, 0];
        const greyColorCode = [200, 200, 200];

        doc.setFontSize(8);
        const today = new Date();
        const requestNumberString = `Request #: XR-SPRF-${formatDate(today)}-${data.customerName}`;
        y1 = addTextWithRect(doc, requestNumberString, x1, pageMargin, doc.getTextWidth(requestNumberString) + 4, 2);

        /*//////////////////////////////////////////////////     scienscope logo image      ////////////////////////////////////////////////////*/
        const imgData = new Image();
        imgData.src = scienscopelogo;
        const imageWidth = 32;
        const imageHeight = 8;
        doc.addImage(imgData, 'PNG', pageWidth - pageMargin - imageWidth, y1, imageWidth, imageHeight);

        /*/////////////////////////////////////////////          header for PDF report              //////////////////////////////////////////////////////*/
        doc.setFontSize(14);
        doc.setFont('NotoScansSC', 'bold');
        const Title = `Spare Parts Request Form (零部件索取表)`;
        const title_x = pageCenterX - doc.getTextWidth(Title) / 2 + pageMargin;
        y1 = addTextWithUnderlines(doc, Title, title_x, y1 + 6, doc.getTextWidth(Title) + 2, pageMargin, 1);
        y1 += Yoffset;
        addApproveStamp(doc, pageWidth, pageHeight, pageMargin);

        /*////////////////////////////////////////////////////              main body content          ////////////////////////////////////////////////////*/
        doc.setFontSize(10);

        const rowsdata = [
            [
                { content: "Submitted Date(发送日期):", styles: { halign: 'left', fontStyle: 'bold' } },
                { content: `${data.submittedDate}`, styles: { halign: 'center' } },
                { content: "Submitted By(申请者):", styles: { halign: 'left', fontStyle: 'bold' } },
                { content: `${data.submittedBy}`, styles: { halign: 'center' } }
            ],
            [
                { content: "Purpose:", colSpan: 1, styles: { halign: 'left', fontStyle: 'bold' } },               
                { content: `${data.purpose}`, colSpan: 3, styles: { halign: 'left' } },
            ]
        ];
        doc.autoTable({
            body: rowsdata,
            startY: y1, // Position the table
            startX: x1,
            margin: { top: 0, left: pageMargin, bottom: 0, right: 0 }, // Set margin to zero         
            styles: {
                font: 'NotoScansSC',
                cellPadding: 1, // Cell padding
                fontSize: 10, // Body text font size
                halign: 'left',
                valign: 'middle',
                textColor: blackColorCode,
                lineWidth: 0.2, // Body border width
                lineColor: blackColorCode, // Body border color (black)
                overflow: 'linebreak', // Handle text overflow
            },
            columnStyles: {
                0: { cellWidth: tableWidth * 0.25 },
                1: { cellWidth: tableWidth * 0.25 },
                2: { cellWidth: tableWidth * 0.25 },
                3: { cellWidth: tableWidth * 0.25 },
            },
            theme: 'grid',
        });

        //get the y1 positon after placing the table 
        y1 = doc.autoTable.previous.finalY + Yoffset;
        y1 = addText(doc, `Item Requested(需求零件):`, x1, y1, 2)

        //create partnumber table
        // Define table columns and data
        const columns = [
            { header: '#', dataKey: 'index' },
            { header: 'Part Number', dataKey: 'partnumber' },
            { header: 'Quantity', dataKey: 'quantity' },
        ];
        const requested_data = data.partNumber.map((partnum, index) => ({
            index: index,
            partnumber: partnum,
            quantity: data.quantity[index],
        }));

        // Add table to PDF
        doc.autoTable({
            columns: columns,
            body: requested_data,
            startY: y1, // Position the table
            startX: x1,
            margin: { top: 5, left: pageMargin, bottom: 0, right: 0 }, // Set margin to zero
            headStyles: {
                font: 'NotoScansSC',
                cellPadding: 1,
                fillColor: greyColorCode, // Header background color (black)
                textColor: blackColorCode, // Header text color (white)
                fontSize: 10, 
                halign: 'center', 
                valign: 'middle', 
                fontStyle: 'bold', 
                lineWidth: 0.2, // Header border width
                lineColor: blackColorCode, // Header border color (black)
            },
            styles: {
                cellPadding: 1, // Cell padding
                fontSize: 10, // Body text font size
                font: 'NotoScansSC',
                textColor: blackColorCode,
                halign: 'center',
                valign: 'middle',
                lineWidth: 0.2, // Body border width
                lineColor: blackColorCode, // Body border color (black)
                overflow: 'linebreak', // Handle text overflow
            },
            columnStyles: {
                0: { cellWidth: tableWidth * 0.1 },
                1: { cellWidth: tableWidth * 0.7 },
                2: { cellWidth: tableWidth * 0.2 }
            },
            theme: 'grid',
        });

        //get the y1 positon after placing the table 
        y1 = doc.autoTable.previous.finalY + Yoffset;


        const Others = [
            [
                { content: 'Model(型号):', styles: { halign: 'left', fontStyle: 'bold' } },
                { content: `${data.model}`, styles: { halign: 'left' } },
                { content: 'Warranty(保修):', styles: { halign: 'left', fontStyle: 'bold' } },
                { content: `${data.warranty ? "YES" : "NO"}`, styles: { halign: 'left' } },
            ],
            [
                { content: 'Customer Name(客户名称):', styles: { halign: 'left', fontStyle: 'bold' } },
                { content: `${data.customerName}`, styles: { halign: 'left' } },
                { content: 'Contact Name:(联系人):', styles: { halign: 'left', fontStyle: 'bold' } },
                { content: `${data.contactName}`, styles: { halign: 'left' } },
            ],
            [
                { content: 'Contact Phone:(联系电话):', styles: { halign: 'left', fontStyle: 'bold' } },
                { content: `${data.contactPhone}`, styles: { halign: 'left' } },
                { content: '', styles: { halign: 'left', fontStyle: 'bold' } },
                { content: '', styles: { halign: 'left' } },
            ],
            [
                { content: 'Shipping Address(送货地址):', styles: { halign: 'left', fontStyle: 'bold' } },
                { content: `${data.shippingAddress}`, colSpan: 3, styles: { halign: 'left' } },
            ],
            [
                { content: 'Shipping Account(送货账号):', styles: { halign: 'left', fontStyle: 'bold' } },
                { content: `${data.shippingAccount}`, styles: { halign: 'left' } },
                { content: 'Shipping Method(送货方式):', styles: { halign: 'left', fontStyle: 'bold' } },
                { content: `${data.shippingMethod}`, styles: { halign: 'left' } },
            ],

        ];

        // Add table to PDF
        doc.autoTable({
            body: Others,
            startY: y1, // Position the table
            startX: x1,
            margin: { top: 0, left: pageMargin, bottom: 0, right: 0 }, // Set margin to zero
            styles: {
                font: 'NotoScansSC',
                cellPadding: 1, // Cell padding
                fontSize: 10, // Body text font size
                halign: 'center',
                valign: 'middle',
                textColor: blackColorCode,
                lineWidth: 0, // Body border width
                lineColor: blackColorCode, // Body border color (black)
                overflow: 'linebreak', // Handle text overflow
            },
            columnStyles: {
                0: { cellWidth: tableWidth * 0.28 },
                1: { cellWidth: tableWidth * 0.22 },
                2: { cellWidth: tableWidth * 0.28 },
                3: { cellWidth: tableWidth * 0.22 }
            },
            theme: 'grid',
        });
        //get the y1 positon after placing the table 
        y1 = doc.autoTable.previous.finalY + Yoffset;

        //fill the Nodes

        doc.setFont('NotoScansSC', 'bold');
        y1 = addText(doc, "Requestor's Notes(请求者备注): ", x1, y1, Yoffset);

        doc.setFont('NotoScansSC', 'normal');
        y1 = addTextWithRect(doc, `${data.notes}`, x1, y1, pageWidth - 2 * pageMargin);

        doc.setFont('NotoScansSC', 'bold');
        y1 = addText(doc, "Approval Notes(核准备注):", x1, y1, Yoffset);
        doc.setFont('NotoScansSC', 'normal');
        y1 = addTextWithRect(doc, `${data.approvalNotes}`, x1, y1, pageWidth - 2 * pageMargin);


        onSuccess(doc);

    } catch (error) {
        onError(error);
    }
}

export default generateItemRequestPDF;