import React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import roles from '../../hooks/Roles';
import { useUser } from '../../hooks/UserContext';
import { GetTextByRegion } from '../../hooks/Translation';

const EventDetailsPopup = ({ open, onClose, onEdit, onDelete, event, isDashboard,seletedRegion }) => {
  const {user:{userRights} } = useUser();
  if (!event) return null;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{event.title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          <strong>{GetTextByRegion("Description",seletedRegion)}:</strong> {event.description}
        </Typography>
        <Typography variant="body1">
          <strong>{GetTextByRegion("Start",seletedRegion)}:</strong> {event.start.toLocaleString()}
        </Typography>
        <Typography variant="body1">
          <strong>{GetTextByRegion("End",seletedRegion)}:</strong> {event.end.toLocaleString()}
        </Typography>
        <Typography variant="body1">
          <strong>{GetTextByRegion("Customer Name",seletedRegion)}:</strong> {event.customer}
        </Typography>
        <Typography variant="body1">
          <strong>{GetTextByRegion("Address",seletedRegion)}:</strong> {event.address}
        </Typography>
        <Typography variant="body1">
          <strong>{GetTextByRegion("Serial Number",seletedRegion)}:</strong> {event.serial}
        </Typography>
        <Typography variant="body1">
          <strong>{GetTextByRegion("Model(s)",seletedRegion)}:</strong> {event.model.join(', ')}
        </Typography>
        <Typography variant="body1">
          <strong>{GetTextByRegion("Type of Service",seletedRegion)}:</strong> {event.serviceType}
        </Typography>
        <Typography variant="body1">
          <strong>{GetTextByRegion("Technicians",seletedRegion)}:</strong> {event.technicians}
        </Typography>
        <Typography variant="body1">
          <strong>{GetTextByRegion("Additional Technicians",seletedRegion)}:</strong> {event.addTechnicians}
        </Typography>
      </DialogContent>
      <DialogActions>
        {(userRights === roles['SALES'] || userRights >= roles['MANAGER']) && (
          <>
            <Button onClick={onEdit} color="primary">
              {GetTextByRegion("Edit", seletedRegion)}
            </Button>
            <Button onClick={onDelete} color="secondary">
              {GetTextByRegion("Delete", seletedRegion)}
            </Button>
          </>
        )}
        <Button onClick={onClose} color="primary">
          {GetTextByRegion("Close",seletedRegion)}
        </Button>
      </DialogActions>
    </Dialog>
    );
  };
export default EventDetailsPopup;