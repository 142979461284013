import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorDialog from '../ErrorDialog';
import roles from '../../hooks/Roles';
import { useUser } from '../../hooks/UserContext';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const QuestionList = ({ questions = [], setQuestions, isDialogOpen, setIsDialogOpen}) => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [answerContent, setAnswerContent] = useState('');
  const [deleteQuestionId, setDeleteQuestionId] = useState(null);
  const [deleteAnswerId, setDeleteAnswerId] = useState(null);
  const [error, setError] = useState(null);
  const {user:{userRights} } = useUser();

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(backendString + '/questions');
        setQuestions(response.data);
      } catch (error) {
        console.error('Error fetching questions:', error);
        setError("Error fetching questions");
      }
    };

    fetchQuestions();
  }, [setQuestions]);

  const handleQuestionClick = async (id) => {
    try {
      const response = await axios.get(backendString + `/questions/${id}`);
      setSelectedQuestion(response.data);
    } catch (error) {
      console.error('Error fetching question details:', error);
      setError("Error fetching question details");
    }
  };

  const handleAnswerSubmit = async () => {
    if (selectedQuestion && answerContent.trim()) {
      try {
        const response = await axios.post(
          backendString + `/questions/${selectedQuestion._id}/answers`,
          { content: answerContent }, {headers: {
            'Authorization': sessionStorage.getItem('token')
          }
        }
        );
        setSelectedQuestion({
          ...selectedQuestion,
          answers: [...selectedQuestion.answers, response.data],
        });
        setAnswerContent('');
        setQuestions((prevQuestions) =>
          prevQuestions.map((question) =>
            question._id === selectedQuestion._id
              ? { ...question, answers: [...question.answers, response.data] }
              : question
          )
        );
      } catch (error) {
        console.error('Error submitting answer:', error);
        setError(error.response?.data?.error);
      }
    }
  };

  const handleDeleteClick = (id) => {
    setDeleteQuestionId(id);
    setIsDialogOpen(true);
  };

  const handleDeleteAnswerClick = (id) => {
    setDeleteAnswerId(id);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setDeleteQuestionId(null);
    setDeleteAnswerId(null);
  };

  const handleDeleteConfirm = async () => {
    if (deleteQuestionId) {
      try {
        await axios.delete(backendString + `/questions/${deleteQuestionId}`, {headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });
        setQuestions(questions.filter((question) => question._id !== deleteQuestionId));
        setSelectedQuestion(null);
        handleCloseDialog();
      } catch (error) {
        console.error('Error deleting question:', error);
        setError(error.response?.data?.error);
      }
    } else if (deleteAnswerId) {
      try {
        await axios.delete(backendString + `/answers/${deleteAnswerId}`, {headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });
        setSelectedQuestion({
          ...selectedQuestion,
          answers: selectedQuestion.answers.filter((answer) => answer._id !== deleteAnswerId),
        });
        setQuestions((prevQuestions) =>
          prevQuestions.map((question) =>
            question._id === selectedQuestion._id
              ? { ...question, answers: question.answers.filter((answer) => answer._id !== deleteAnswerId) }
              : question
          )
        );
        handleCloseDialog();
      } catch (error) {
        console.error('Error deleting answer:', error);
        setError(error.response?.data?.error);
      }
    }
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  return (
    <>
    <Box p={3}>
      <Typography variant="h4">Questions & Answers</Typography>
      {selectedQuestion ? (
        <Box mt={2}>
          <Button onClick={() => setSelectedQuestion(null)}>Back to Questions</Button>
          <Typography variant="h5" mt={2}>{selectedQuestion.title}</Typography>
          <Typography variant="body1" mt={1}>{selectedQuestion.content}</Typography>
          <Box mt={2}>
            <Typography variant="h6">Answers</Typography>
            {selectedQuestion.answers.map((answer) => (
              <Box key={answer._id} display="flex" alignItems="center" justifyContent="space-between" mt={1}>
                <Typography variant="body2">{answer.content}</Typography>
                {userRights >= roles['MANAGER'] && <IconButton onClick={() => handleDeleteAnswerClick(answer._id)}>
                  <DeleteIcon />
                </IconButton>}
              </Box>
            ))}
            {userRights >= roles['SERVICE'] && <Box mt={2}>
              <TextField
                label="Your Answer"
                multiline
                rows={4}
                value={answerContent}
                onChange={(e) => setAnswerContent(e.target.value)}
                fullWidth
              />
              <Button variant="contained" color="primary" onClick={handleAnswerSubmit} style={{ marginTop: '8px' }}>
                Submit Answer
              </Button>
            </Box>}
          </Box>
        </Box>
      ) : (
        <Box mt={2}>
          {questions.length > 0 ? (
            questions.map((question) => (
              <Box key={question._id} mt={1} p={1} border={1} borderRadius={4} borderColor="grey.400">
                <Typography variant="h6" onClick={() => handleQuestionClick(question._id)} style={{ cursor: 'pointer' }}>
                  {question.title}
                </Typography>
                <Typography variant="body2">{question.content}</Typography>
                {question.answers && question.answers.length > 0 && (
                  <Box mt={1}>
                    <Typography variant="body2">Answers:</Typography>
                    {question.answers.map((answer) => (
                      <Box key={answer._id} mt={1} ml={2}>
                        <Typography variant="body2">{answer.content}</Typography>
                      </Box>
                    ))}
                  </Box>
                )}
                {userRights >= roles['MANAGER'] && setIsDialogOpen && (
                <IconButton onClick={() => handleDeleteClick(question._id)}>
                  <DeleteIcon />
                </IconButton>
                )}
              </Box> 
            ))
          ) : (
            <Typography>No questions available.</Typography>
          )}
        </Box>
      )}

      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Delete {deleteQuestionId ? 'Question' : 'Answer'}</DialogTitle>
        <DialogContent>Are you sure you want to delete this {deleteQuestionId ? 'question' : 'answer'}?</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} variant="contained" color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>
    </Box>

    <ErrorDialog
      open={!!error}
      onClose={handleCloseErrorDialog}
      errorMessage={error}
    />
    </>
  );
};

export default QuestionList;
