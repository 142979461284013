import React, { useState, useRef,  } from 'react';
import { Button, Box,  IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import AddServiceView from "./AddServiceView";
import EditServiceView from "./EditServiceView";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
/*
    component to add the service item
*/

const ServiceItemView = ({services,totalServiceCost,setServices}) => {

    const [selectedService, setSelectedService] = useState(null);
    const [serviceView, setServiceView] = useState(false);
    const [editServiceView, setEditServiceView] = useState(false);

    const tableContainerRef = useRef(null);

    const handleEditService = (serviceData) => {
        setSelectedService(serviceData);
        setEditServiceView(true);
    };

    const handleDeleteService = (serviceData) => {
        setServices((prevServices) => prevServices.filter(service => service !== serviceData));
    };

    const openAddServiceView = () => {
        setServiceView(true);
    };

    const closeEditServiceView = () => {
        setEditServiceView(false);
    };
    const closeAddServiceView = () => {
        setServiceView(false);
    };

    const ServiceTotalSummary = () => {
        return (
            <Box style={{ marginTop: 16, textAlign: 'right' }}>
                <strong>Sub Total: ${totalServiceCost.toFixed(2)}</strong>
            </Box>
        );
    };

    const onServiceEditSubmit = (updatedService) => {
        setServices((prevServices) =>
            prevServices.map(service =>
                service.itemNumber === updatedService.itemNumber
                    ? { ...service, ...updatedService } // Corrected line
                    : service
            )
        );
    }

    return (

        <Box>
            <br />
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                PM Quote Content
            </Box>

            <Button variant="contained" color="primary" onClick={openAddServiceView}>
                Add Service
            </Button>

            <TableContainer
                component={Paper}
                ref={tableContainerRef}
                sx={{
                    mt: 2,
                    maxHeight: 300,
                    overflow: 'auto'
                }}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Item Number</TableCell>
                            <TableCell>Qty</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Cost</TableCell>
                            <TableCell>Line Total</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {services.map((service, index) => (
                            <TableRow key={index}>
                                <TableCell>{service.itemNumber}</TableCell>
                                <TableCell>{service.quantity}</TableCell>
                                <TableCell>{service.description}</TableCell>
                                <TableCell>${parseFloat(service.cost).toFixed(2)}</TableCell>
                                <TableCell>${parseFloat(service.total).toFixed(2)}</TableCell>
                                <TableCell>
                                    <IconButton color='primary' onClick={() => { handleEditService(service) }} >
                                        <EditIcon />
                                    </IconButton>
                                    <Button>
                                        <DeleteIcon sx={{ color: 'purple' }} onClick={() => handleDeleteService(service)} />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ServiceTotalSummary />

            {serviceView &&
                <AddServiceView open={serviceView} onClose={closeAddServiceView} setServices={setServices} />
            }
             {editServiceView &&
                <EditServiceView open={editServiceView} onClose={closeEditServiceView} serviceData={selectedService} onEditSubmit={onServiceEditSubmit} />
            }
        </Box>

    );
}

export default ServiceItemView;