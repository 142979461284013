import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import axios from 'axios';
import { useUser } from '../../hooks/UserContext';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const TechnicianDropdown = ({ formData, handleChange, name, label }) => {
  const [technicians, setTechnicians] = useState([]);
  const {selectedRegion} = useUser();

  useEffect(() => {
    const fetchTechnicians = async () => {
      try {
        const response = await axios.get(backendString + `/users/regions?selectedRegion=${selectedRegion}`, {
          headers: {
            'Authorization': sessionStorage.getItem('token')
          }
        });
        setTechnicians(response.data);
      } catch (error) {
        console.error('Error fetching technicians:', error);
      }
    };

    fetchTechnicians();
  }, [selectedRegion]);

  return (
    <Autocomplete
      options={technicians}
      getOptionLabel={(option) => option.username}
      value={technicians.find((tech) => tech.username === formData) || null}
      onChange={(event, newValue) => {
        handleChange({ target: { name, value: newValue ? newValue.username : '' } });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
        />
      )}
    />
  );
};

export default TechnicianDropdown;