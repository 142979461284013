import React, { useEffect, useState } from 'react';
import ZingChart from 'zingchart-react';
import axios from 'axios';
import {GetTextByRegion} from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const CompletedMachinePieChart = () => {
  const [chartData, setChartData] = useState([]);
  const {selectedRegion} = useUser();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendString}/machines?selectedRegion=${selectedRegion}`);
        const completedData = response.data.filter(machine => machine.status === 'Completed');
        const processedData = processChartData(completedData);
        setChartData(processedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [selectedRegion]);

  // Function to process data
  const processChartData = (data) => {
    // Example processing logic
    // Replace with your actual data structure and processing logic
    const modelCount = data.reduce((acc, item) => {
      const model = item.model;  // Adjust this based on your data structure
      acc[model] = (acc[model] || 0) + 1;
      return acc;
    }, {});

    return Object.keys(modelCount).map(model => ({
      text: model,  // This will be the label for each slice
      values: [modelCount[model]],
    }));
  };

  const config = {
    type: 'pie',
    title: {
      text: GetTextByRegion('Completed Models',selectedRegion), // Set the title text
      fontSize: 18,
      fontFamily: 'Arial',
      color: '#333',
      backgroundColor: 'transparent', // Transparent background for the title
      align: 'center', // Center-align the title
      marginTop: '10px',
    },
    series: chartData,
    plot: {
      slice: {
        'text': '%t: %v', // Label and value inside the slice
        'text-size': 14, // Font size of the text
        'text-color': '#fff', // Color of the text
        'text-offset': 0.5, // Adjust text position if needed
      },
      tooltip: {
        text: '%t: %v', // Tooltip content
      },
    },
    legend: {
      // Disabled the legend since we're placing labels inside the slices
      visible: false,
    },
  };

  return <ZingChart data={config} />;
};

export default CompletedMachinePieChart;
