import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import '../../styles/LoginForm.css'; // Import your CSS file
import ErrorDialog from '../ErrorDialog';
import { useAuth } from '../AuthContext';
import { useUser } from '../../hooks/UserContext';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const LoginForm = () => {
  const { login } = useAuth();
  const [formData, setFormData] = useState({
    username:'',
    password: '',
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Hook for navigation
  const {user:{userRights},refreshUser} = useUser();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  
  const loginSubmit = async () => {
    try {
      const response = await axios.post(backendString + '/users/login', formData);
      const { token } = response.data; // Assuming the response has a 'token' field
      login(token);
      navigate('/');
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response?.data?.error); // Display server-side error message
      } else {
        setError("Error logging in"); // Display server-side error message
      }
    }
  };

  useEffect(() => {
    refreshUser();
    if(userRights > 0) navigate('/');
  },[refreshUser,userRights,navigate]);

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      await loginSubmit();
    }
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  return (
    <div className='login-container'>
      <h2>Login</h2>
      <TextField
        label="Username"
        variant="standard"
        name="username"
        value={formData.username}
        onChange={handleChange}
        onKeyDown={handleKeyDown} // Handle Enter key press
        style={{ marginBottom: '20px' }}
      />
      <TextField
        label="Password"
        variant="standard"
        type="password"
        name="password"
        value={formData.password}
        onChange={handleChange}
        onKeyDown={handleKeyDown} // Handle Enter key press
        style={{ marginBottom: '20px' }}
      />
      <Button variant="outlined" onClick={loginSubmit} className='login-button'>Login</Button>

      {/* Render the error dialog */}
      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </div>
  );
};

export default LoginForm;
