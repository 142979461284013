import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, Box, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import ErrorDialog from '../ErrorDialog';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const AddQuestionForm = ({ onAdd, onClose }) => {
  const [formData, setFormData] = useState({
    title: '',
    content: '',
  });
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.title) newErrors.title = 'Title is required';
    if (!formData.content) newErrors.content = 'Content is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const addItem = async () => {
    if (!validate()) return;

    try {
      const response = await axios.post(backendString + '/questions', formData, {
        headers: {
          'Authorization': sessionStorage.getItem('token'),
        },
      });
      onAdd(response.data);
      setFormData({
        title: '',
        content: '',
      });
      onClose(); // Close the dialog after adding the item
    } catch (error) {
      console.error(error);
      setError(error.response?.data?.error);
    }
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  return (
    <>
      <Dialog open={true} onClose={onClose}>
        <DialogTitle>Add Item</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2} width={300} mt={1}>
            <TextField
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              required
              variant="outlined"
              error={!!errors.title && !formData.title}
              helperText={formData.title ? '' : errors.title}
            />
            <TextField
              label="Content"
              name="content"
              value={formData.content}
              onChange={handleInputChange}
              required
              variant="outlined"
              multiline
              rows={4} // You can adjust the number of rows as needed
              error={!!errors.content && !formData.content}
              helperText={formData.content ? '' : errors.content}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={addItem} variant="contained" color="primary">
            Add Question
          </Button>
        </DialogActions>
      </Dialog>

      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </>
  );
};

export default AddQuestionForm;