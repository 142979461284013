
const afterSaleColumnData = [ 
    {label:"Application Date",   label_chinese:"申请日期",        name:"applicationdate",       type:"date",      value:"applicationdate",        required:true, InputLabelProps:{ shrink: true }, position:'left'},
    {label:"Order Number",       label_chinese:"索取单编号/订单号",name:"ordernumber",           type:"text",      value:"ordernumber",             required:true,InputLabelProps:{ }, position:'left'},                 
    {label:"Customer Name",      label_chinese:"客户名称",        name:"customername",          type:"text",      value:"customername",           required:true,InputLabelProps:{ }, position:'left'}, 
    {label:"Machine ID",         label_chinese:"机台编号",        name:"machineID",             type:"text",      value:"machineID",              required:false,InputLabelProps:{ }, position:'left'}, 
    {label:"Machine Model",      label_chinese:"机台型号",        name:"machinemodel",          type:"text",      value:"machinemodel",           required:false,InputLabelProps:{ }, position:'left'}, 
    {label:"Material Name",      label_chinese:"物料名称",        name:"materialname",          type:"text",      value:"materialname",           required:true,InputLabelProps:{ }, position:'left'}, 
    {label:"Material #",         label_chinese:"物料号",          name:"materialnumber",        type:"text",      value:"materialnumber",         required:false,InputLabelProps:{ }, position:'left'}, 
    {label:"Request Quantity",   label_chinese:"索取数量",        name:"requestedquantity",     type:"number",    value:"requestedquantity",      required:true,InputLabelProps:{ shrink: true }, position:'left'}, 
    {label:"Charge Status",      label_chinese:"收费狀態",        name:"chargestatus",          type:"text",      value:"chargestatus",           required:false,InputLabelProps:{ }, position:'left'}, 
    {label:"Request Reason",     label_chinese:"索取原因",        name:"requestreason",         type:"text",      value:"requestreason",          required:true,InputLabelProps:{ }, position:'right'},
    {label:"Region",             label_chinese:"地区",            name:"region",                type:"text",      value:"region",                 required:true,InputLabelProps:{ }, position:'right'}, 
    {label:"Shipped Quantity",   label_chinese:"实发数量",        name:"shippedquantity",       type:"number",    value:"shippedquantity",        required:true,InputLabelProps:{ shrink: true }, position:'right'}, 
    {label:"Delivery Note",      label_chinese:"领料单/送货单",   name:"deliverynote",          type:"text",      value:"deliverynote",           required:false,InputLabelProps:{ }, position:'right'}, 
    {label:"Tracking #",         label_chinese:"快递单号",        name:"trackingnumber",        type:"text",      value:"trackingnumber",         required:false,InputLabelProps:{ }, position:'right'}, 
    {label:"Dispatch Date",      label_chinese:"发出日期",        name:"dispatchdate",          type:"date",      value:"dispatchdate",           required:false,InputLabelProps:{ shrink: true }, position:'right'},  
    {label:"Recycle Status",     label_chinese:"部件（是/否）回收",name:"recyclestatus",         type:"checkbox",  value:"recyclestatus",          required:true,InputLabelProps:{ shrink: true }, position:'right'},  
    {label:"Returned Date",      label_chinese:"返厂日期",        name:"returneddate",          type:"date",      value:"returneddate",           required:false,InputLabelProps:{ shrink: true }, position:'right'},  
    {label:"Warehouse Receipt #",label_chinese:"仓库入库单号",     name:"warehousereceiptnumber",type:"text",      value:"warehousereceiptnumber", required:false,InputLabelProps:{ }, position:'right'}, 
    {label:"Remarks",            label_chinese:"备注",            name:"remarks",               type:"text",      value:"remarks",                required:false,InputLabelProps:{ }, position:'left'}, 
];

export default afterSaleColumnData;