import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ImageDialog = ({ open, onClose, imageSrc }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <img
          src={imageSrc}
          alt="Selected Item"
          style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ImageDialog;
