import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Grid } from '@mui/material';
import QuestionList from '../components/Q&A/QuestionList'; // Create these components next
import AddQuestionForm from '../components/Q&A/AddQuestionForm';
import roles from '../hooks/Roles';
import { useUser, useValidateRegions } from '../hooks/UserContext';

const Forum = () => {
  const [questions, setQuestions] = useState([]);
  const [isAddQuestion, setIsAddQuestion] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const validateSelectedRegion = ["USA", "EU", "MEXICO"];
  const {user:{userRights}, selectedRegion, refreshUser} = useUser();
  useValidateRegions(selectedRegion,validateSelectedRegion);

  const handleOpenDialog = () => {
    setIsAddQuestion(true);
  };

  const handleCloseDialog = () => {
    setIsAddQuestion(false);
  };

  const handleAddQuestion = (newQuestion) => {
    setQuestions([...questions, newQuestion]);
  };

  useEffect(() => {
    refreshUser();
  }, [refreshUser,isAddQuestion,isDialogOpen]);

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" align="center" gutterBottom>
        Q&A
      </Typography>
      {userRights >= roles['SERVICE'] && <Button variant="contained" color="primary" onClick={handleOpenDialog}>
          Add New Question
      </Button>}
      {isAddQuestion && (
          <AddQuestionForm onAdd={handleAddQuestion} onClose={handleCloseDialog} />
        )}
      <Grid container spacing={3}>
        <Grid item xs={12} md={16}>
          
          <QuestionList 
            questions={questions} 
            setQuestions={setQuestions}
            isDialogOpen={isDialogOpen}
            setIsDialogOpen={setIsDialogOpen}
            />
        </Grid>

      </Grid>
    </Container>
  );
};

export default Forum;
