import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions,FormHelperText } from '@mui/material';

const EditPartView = ({ open, onClose,requestedPart , setRequestedPart ,onEditSubmit}) => {

    const [part, setPart] = useState(requestedPart);
    const [errorMessage,setErrorMesage]=useState("");

    const editPart = () => {
        
        if (!part.partnumber ||  part.quantity <= 0 || part.price < 0) {
            return;
        }
        const total = part.price * part.quantity;
        const newPart = { ...part, total };
        
        onEditSubmit(newPart);
        setRequestedPart({
            partnumber: '',
            quantity: '',
            price: '',
            description: '',
            total: 0
        });
        onClose();
    }

    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>
                    Add Part
                </DialogTitle>
                <DialogContent>
                    <TextField
                        label="Part Number"
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="normal"
                        value={part.partnumber}
                        disabled={true}                      
                    />
                    <TextField
                        label="Description"
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="normal"
                        value={part.description}
                        onChange={(e)=>{setPart({...part,description:e.target.value });setErrorMesage("");}}
                    />
                    <TextField
                        label="Request Quantity"
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="normal"
                        value={part.quantity}
                        onChange={(e) => {setPart({ ...part, quantity: e.target.value });setErrorMesage("");}}
                        onKeyPress={(e) => {
                            const allowedKeys = /^[0-9.]+$/;
                            if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                e.preventDefault();
                                setErrorMesage("Error: Invalid Input!");
                            }
                        }}
                    />
                    <TextField
                        label="Unit Price"
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="normal"
                        value={part.price ? `$${part.price}` : ''}
                        onChange={(e) => {setPart({ ...part, price: e.target.value.replace('$', '') }); setErrorMesage("");}}
                        onKeyPress={(e) => {
                            const allowedKeys = /^[0-9.]+$/;
                            if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                e.preventDefault();
                                setErrorMesage("Error: Invalid Input!");
                            }
                        }}
                    />
                      {/* {errorMessage && <FormHelperText error><h4>Please check your input and try again!<br/>{errorMessage}</h4></FormHelperText>} */}
                </DialogContent>
                <DialogActions>
                    <Button 
                    color="primary"  
                    variant="contained" 
                    onClick={editPart}
                    disabled={!part.partnumber || part.quantity <= 0 ||  part.price < 0 ||!part.price}
                    >
                        Edit
                    </Button>
                    <Button onClick={onClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

}

export default EditPartView;