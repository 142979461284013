import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions,FormHelperText } from '@mui/material';

const AddPartView = ({ open, onClose, setParts, requestedPart , setRequestedPart ,partlist}) => {

    const [part, setPart] = useState(requestedPart);
    const [errorMessage,setErrorMesage]=useState("");

    const addPart = () => {
        
        if (!part.partnumber ||  part.quantity <= 0 || part.price < 0) {
            return;
        }
          // Check if part number already exists
          if (partlist.some(existingPart => existingPart.partnumber === part.partnumber)) {
            setErrorMesage("Error: This Part number already exists.");
            return;
        }

        const total = part.price * part.quantity;
        const newPart = { ...part, total };
        setParts((prev) => [...prev, newPart]);
        setRequestedPart({
            partnumber: '',
            quantity: '',
            price: '',
            description: '',
            total: 0
        });
        onClose();
    }

    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>
                    Add Part
                </DialogTitle>
                <DialogContent>
                    <TextField
                        label="Part Number"
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="normal"
                        value={part.partnumber}
                        onChange={(e)=>{setPart({ ...part, partnumber: e.target.value });setErrorMesage("")}}
                    />
                    <TextField
                        label="Description"
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="normal"
                        value={part.description}
                        onChange={(e)=>{setPart({...part,description:e.target.value });setErrorMesage("")}}
                    />
                    <TextField
                        label="Request Quantity"
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="normal"
                        value={part.quantity}
                        onChange={(e) => {setPart({ ...part, quantity: e.target.value });setErrorMesage("")}}
                        onKeyPress={(e) => {
                            const allowedKeys = /^[0-9.]+$/;
                            if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                e.preventDefault();
                                setErrorMesage("Error: Invalid Input!");
                            }
                        }}
                    />
                   
                    <TextField
                        label="Unit Price"
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="normal"
                        value={part.price ? `$${part.price}` : ''}
                        onChange={(e) => {setPart({ ...part, price: e.target.value.replace('$', '') });setErrorMesage("")}}
                        onKeyPress={(e) => {
                            const allowedKeys = /^[0-9.]+$/;
                            if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                e.preventDefault();
                                setErrorMesage("Error: Invalid Input!");
                            }
                        }}
                    />
                    {errorMessage && <FormHelperText error><h4>Please check your input and try again!<br/>{errorMessage}</h4></FormHelperText>}
                </DialogContent>
                <DialogActions>
                    <Button 
                    color="primary"  
                    variant="contained" 
                    onClick={addPart}
                    disabled={!part.partnumber || part.quantity <= 0 ||  part.price < 0 ||!part.price}
                    >
                        Add
                    </Button>
                    <Button onClick={onClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
               
            </Dialog>
        </>
    );

}

export default AddPartView;