import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import CheckCircleUnfilled from '@mui/icons-material/CheckCircleOutlineSharp';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';

const IconAcceptUnfilledButton = ({ onInventoryUpdate, inventoryErrors }) => {
    const { selectedRegion } = useUser();

    return (
        <Tooltip title={GetTextByRegion("Accept", selectedRegion)} arrow>
            <IconButton color="success" onClick={() => onInventoryUpdate(inventoryErrors)}>
                <CheckCircleUnfilled />
            </IconButton>
        </Tooltip>
    );
}

export default IconAcceptUnfilledButton;
