import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  Box,
  TextField,
  Button,
  Checkbox,
  List,
  ListItem,
  IconButton,
  Typography,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import ErrorDialog from '../ErrorDialog';
import roles from '../../hooks/Roles';
import { useUser } from '../../hooks/UserContext';

const backendString = process.env.REACT_APP_BACKEND_STRING;

function ToDoList() {
  const [todoList, setTodoList] = useState([]);
  const [task, setTask] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteTaskId, setDeleteTaskId] = useState(null);
  const [deleteTaskIndex, setDeleteTaskIndex] = useState(null);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [error, setError] = useState(null);
  const [processingIndex, setProcessingIndex] = useState(null);
  const {selectedRegion, user:{userRights}, refreshUser} = useUser();

  useEffect(() => {
    refreshUser();
  }, [refreshUser,selectedRegion]);

  const fetchTodos = useCallback(async () => {
    try {
      const response = await axios.get(`${backendString}/todos`);
      setTodoList(response.data);
    } catch (error) {
      console.error('Error fetching todos:', error);
      setError('Error fetching todos');
    }
  }, []);

  useEffect(() => {
    fetchTodos();
  }, [fetchTodos]);

  const addTask = async () => {
    if (task.trim()) {
      try {
        const newTask = { task, urgent: false, completed: false };
        const response = await axios.post(`${backendString}/todos`, newTask, {
          headers: { 'Authorization': sessionStorage.getItem('token') }
        });
        setTask('');
        setTodoList(prevTodoList => [...prevTodoList, response.data]); // Use response data
      } catch (error) {
        console.error('Error adding task:', error);
        setError(error.response?.data?.error || 'Error adding task');
      }
    }
  };

  const deleteTask = async (id, index) => {
    try {
      await axios.delete(`${backendString}/todos/${id}`, {
        headers: { 'Authorization': sessionStorage.getItem('token') }
      });
      setTodoList(prevTodoList => prevTodoList.filter((_, i) => i !== index));
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error('Error deleting task:', error);
      setError(error.response?.data?.error || 'Error deleting task');
    }
  };

  const toggleUrgency = async (index) => {
    if (userRights >= roles['SERVICE']) {
      try {
        const updatedList = [...todoList];
        updatedList[index].urgent = !updatedList[index].urgent;
        await axios.put(
          `${backendString}/todos/urgency/${updatedList[index]._id}`,
          { urgent: updatedList[index].urgent },
          { headers: { 'Authorization': sessionStorage.getItem('token') } }
        );
        setTodoList(updatedList);
      } catch (error) {
        console.error('Error updating urgency:', error);
        setError(error.response?.data?.error || 'Error updating task');
      }
    } else {
      setOpenLoginDialog(true);
    }
  };

  const handleDeleteClick = (id, index) => {
    if (userRights >= roles['SERVICE']) {
      setDeleteTaskId(id);
      setDeleteTaskIndex(index);
      setOpenDeleteDialog(true);
    } else {
      setOpenLoginDialog(true);
    }
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDeleteTaskId(null);
    setDeleteTaskIndex(null);
  };

  const handleDeleteConfirm = () => {
    if (deleteTaskId !== null && deleteTaskIndex !== null) {
      deleteTask(deleteTaskId, deleteTaskIndex);
    }
  };

  const handleCloseLoginDialog = () => {
    setOpenLoginDialog(false);
  };

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      await addTask();
    }
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  const toggleTaskCompletion = async (index) => {
    if (userRights >= roles['SERVICE'] && processingIndex === null) {
      try {
        setProcessingIndex(index);
        const updatedList = [...todoList];
        updatedList[index].completed = !updatedList[index].completed;
        setTodoList(updatedList);
        await axios.put(
          `${backendString}/todos/completed/${updatedList[index]._id}`,
          { completed: updatedList[index].completed },
          { headers: { 'Authorization': sessionStorage.getItem('token') } }
        );
        setProcessingIndex(null);
      } catch (error) {
        console.error('Error updating task completion:', error);
        setError(error.response?.data?.error || 'Error updating task completion');
        const revertedList = [...todoList];
        revertedList[index].completed = !revertedList[index].completed;
        setTodoList(revertedList);
        setProcessingIndex(null);
      }
    } else if (!(userRights >= roles['SERVICE'])) {
      setOpenLoginDialog(true);
    }
  };

  return (
    <>
      <Box p={3}>
        <Typography variant="h5" gutterBottom>
          To-Do List
        </Typography>
        <Box display="flex" alignItems="center" mb={2} gap={2}>
          <TextField
            variant="outlined"
            label="New Task"
            value={task}
            onChange={(e) => setTask(e.target.value)}
            onKeyDown={handleKeyDown}
            fullWidth
          />
          <Button variant="contained" color="primary" onClick={addTask}>
            Add
          </Button>
        </Box>
        <List>
          {todoList.map((todo, index) => (
            <ListItem
              key={todo._id}
              button
              onClick={() => toggleTaskCompletion(index)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                pointerEvents: processingIndex === index ? 'none' : 'auto',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                <Checkbox
                  checked={todo.completed}
                  style={{ marginRight: 8 }}
                />
                <div style={{
                  flexGrow: 1,
                  overflowWrap: 'break-word',
                  wordWrap: 'break-word',
                  whiteSpace: 'normal',
                  textDecoration: todo.completed ? 'line-through' : 'none',
                }}>
                  {todo.task}
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Checkbox
                    checked={todo.urgent}
                    onChange={(e) => {
                      e.stopPropagation();
                      toggleUrgency(index);
                    }}
                    icon={<AccessTimeOutlinedIcon />}
                    checkedIcon={
                      <Chip
                        label="Urgent"
                        color="secondary"
                        icon={<AccessTimeOutlinedIcon />}
                        style={{ textDecoration: 'none' }}
                      />
                    }
                  />
                </div>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteClick(todo._id, index);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </ListItem>
          ))}
        </List>
      </Box>

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this task?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} variant="contained" color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openLoginDialog} onClose={handleCloseLoginDialog}>
        <DialogTitle>Login Required</DialogTitle>
        <DialogContent>
          <Typography>You need to log in to manage your tasks.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLoginDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </>
  );
}

export default ToDoList;