import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select, CircularProgress, Box, FormHelperText } from '@mui/material';
import axios from 'axios';
import { GetTextByRegion } from '../hooks/Translation';

const backendString = process.env.REACT_APP_BACKEND_STRING;

// FETCHES BOTH MACHINES AND RACKS
// USED IN ALL OTHER REGIONS EXCEPT CHINA

const ModelDropdown = ({ value, onChange, required = false, errors = null, helperText = null, selectedRegion, disabled, china, chinaLabel,type='both'}) => {
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        if (type === 'both'){
          const response1 = await axios.get(`${backendString}/dropdown?selectedRegion=${selectedRegion}&type=machine`);
          const response2 = await axios.get(`${backendString}/dropdown?selectedRegion=${selectedRegion}&type=rack`);
          setModels([...response1.data, ...response2.data]); // Adjust based on your backend response structure
        }
        else{
          const response1 = await axios.get(`${backendString}/dropdown?selectedRegion=${selectedRegion}&type=${type}`);
          setModels(response1.data); // Adjust based on your backend response structure
        }
      } catch (error) {
        console.error('Error fetching machine models:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchModels();
  }, [selectedRegion]);

  const hasError = !!errors && !value;

  return (
    <>
    <FormControl 
      variant="outlined" 
      fullWidth 
      required={required} 
      error={hasError} 
      disabled={disabled}
    >
      <InputLabel>{china ? chinaLabel : GetTextByRegion("Model",selectedRegion)}</InputLabel>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Select
            label={china ? chinaLabel : GetTextByRegion("Model",selectedRegion)}
            name="model"
            value={value}
            onChange={onChange}
          >
            {models
              .slice()
              .sort((a, b) => a.ModelName.localeCompare(b.ModelName))
              .map((model) => (
                <MenuItem key={model._id} value={model.ModelName}>
                  {model.ModelName}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>{hasError ? errors : helperText}</FormHelperText>
        </>
      )}
    </FormControl>
    </>
  );
};

export default ModelDropdown;
