import React, { version } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import scienscopelogo from '../../images/scienscopeLogo.png';

// Function to add footer to the current page
function addFooter(doc, text, versionText) {
    const footerText = text;
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;
    const marginBottom = 10; // margin from the bottom
    doc.setFontSize(9);
    doc.text(footerText, pageWidth / 2 - doc.getTextWidth(text) / 2, pageHeight - marginBottom);
    doc.text(versionText, pageWidth - marginBottom - doc.getTextWidth(versionText), pageHeight - marginBottom);
}

function formatDate(dateString) {

    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    // Concatenate to form the desired format
    return `${month}${day}${year}`;
}


const generatePackingListPDF = (data) => {

    const doc = new jsPDF();

    // page settings
    const pageMargin = 10;
    const pageWidth = doc.internal.pageSize.width;
    //let x1 = pageMargin;
    let y1 = pageMargin + 13;

    const tableWidth = pageWidth - 2 * pageMargin;

    const blackColorCode = [0, 0, 0];
    const whiteColorCode = [255, 255, 255];
    const greyColorCode = [200, 200, 200];
    const yellowColorCode = [250, 240, 140];

    /*//////////////////////////////////////////////////     scienscope logo image      ////////////////////////////////////////////////////*/
    const imgData = new Image();
    imgData.src = scienscopelogo;
    const imageWidth = 50;
    const imageHeight = 13;
    doc.addImage(imgData, 'PNG', pageMargin + 8, pageMargin - 3, imageWidth, imageHeight);

    doc.text("Machine Configuration List",pageWidth/2-doc.getTextWidth("Machine Configuration List")/2,y1-8);

    const packinglist_model = data.model;
    const packinglist_customer = data.customer;
    const packinglist_SN = data.serial;
    const packinglist_PO = data.poNumber;
    const packinglist_isDEMO = data.isDemo ? 'Yes' : 'No';
    const packinglist_dateManufactured = data.manufacturedDate;
    const packinglist_shippingDate = 'N/A';
    const packinglist_shippingVia = "N/A";
    const packinglist_trackingNumber = "N/A";
    const packinglist_note=data.packing_notes;
    const packinglist_fileName = packinglist_model+" Machine Packing List "+packinglist_SN+" - "+packinglist_customer; 
    const packinglist_partnumbers = data.packing_partnumbers;
    const packinglist_description = data.packing_descriptions;
    const packinglist_quantites = data.packing_quantities;
    const packinglist_serial = data.packing_serials;
    const packinglist_data = packinglist_partnumbers.map((partnumber, index) => ({
        partnumber,
        description: packinglist_description[index],
        quantity: packinglist_quantites[index],
        serial: packinglist_serial[index],
    }));
    //7 columns    
    const headers = [];
    const body = [];
    body.push(
        [
            {content:"Model:", colSpan: 2,styles: { halign: 'right', fillColor: whiteColorCode, lineWidth: 0 }},
            {content: packinglist_model, colSpan: 2,styles: { halign: 'center', fillColor: whiteColorCode, lineWidth: 0 }},
            {content:"Customer:",styles: { halign: 'right', fillColor: whiteColorCode, lineWidth: 0 }},
            {content: packinglist_customer, colSpan: 2,styles: { halign: 'center', fillColor: whiteColorCode , lineWidth: 0}},
        ],
        [
            {content:"SN:", colSpan: 2,styles: { halign: 'right', fillColor: whiteColorCode , lineWidth: 0}},
            {content: packinglist_SN, colSpan: 2,styles: { halign: 'center', fillColor: whiteColorCode , lineWidth: 0}},
            {content:"PO:",styles: { halign: 'right', fillColor: whiteColorCode , lineWidth: 0}},
            {content: packinglist_PO, colSpan: 2,styles: { halign: 'center', fillColor: whiteColorCode , lineWidth: 0}},
        ],
        [
            {content:"Check here for DEMO:", colSpan: 2,styles: { halign: 'right', fillColor: whiteColorCode , lineWidth: 0}},
            {content: packinglist_isDEMO, colSpan: 2,styles: { halign: 'center', fillColor: whiteColorCode , lineWidth: 0}},
            {content: '', colSpan: 3,styles: { halign: 'center', fillColor: whiteColorCode , lineWidth: 0}},
        ],
        [
            {content:"Date Manufactured:", colSpan: 2,styles: { halign: 'right', fillColor: whiteColorCode , lineWidth: 0}},
            {content: packinglist_dateManufactured, colSpan: 2,styles: { halign: 'center', fillColor: whiteColorCode , lineWidth: 0}},
            {content: '', colSpan: 3,styles: { halign: 'center', fillColor: whiteColorCode , lineWidth: 0}},
        ],
        [
            {content:"", colSpan: 4,styles: { halign: 'right', fillColor: whiteColorCode, lineWidth: 0}},          
            {content:"Shipping Date:",styles: { halign: 'right', fillColor: whiteColorCode , lineWidth: 0}},
            {content: packinglist_shippingDate, colSpan: 2,styles: { halign: 'center', fillColor: whiteColorCode , lineWidth: 0}},
        ],
        [
            {content:"", colSpan: 4,styles: { halign: 'right', fillColor: whiteColorCode , lineWidth: 0}},          
            {content:"shipped Via:",styles: { halign: 'right', fillColor: whiteColorCode , lineWidth: 0}},
            {content: packinglist_shippingVia, colSpan: 2,styles: { halign: 'center', fillColor: whiteColorCode , lineWidth: 0}},
        ],
        [
            {content:"", colSpan: 4,styles: { halign: 'right', fillColor: whiteColorCode, lineWidth: 0 }},          
            {content:"Tracking No:",styles: { halign: 'right', fillColor: whiteColorCode , lineWidth: 0}},
            {content: packinglist_trackingNumber, colSpan: 2,styles: { halign: 'center', fillColor: whiteColorCode , lineWidth: 0}},
        ],

    );
    body.push(
        [{ content: '', colSpan: 7,styles: { lineWidth: 0}}], // Empty row for spacing      
        [
            { content: 'Part Number', styles: { halign: 'center', fillColor: greyColorCode , lineWidth: 0.2} },
            { content: 'Description', colSpan: 3, styles: { halign: 'center', fillColor: greyColorCode } },
            { content: 'Qty', styles: { halign: 'center', fillColor: greyColorCode } },
            { content: 'Serial Number', colSpan: 2, styles: { halign: 'center', fillColor: greyColorCode } },
        ]
    );

    const partList = packinglist_data.map(row => [
        { content: row.partnumber, colSpan: 2, styles: { halign: 'center' } },
        { content: row.description, colSpan: 2 },
        { content: row.quantity, styles: { halign: 'center' } },
        { content: row.serial, colSpan: 2, styles: { halign: 'center' } },
    ]);

    body.push(...partList);


    body.push( [ 
        {content: '' , colSpan: 7, styles: { halign: 'left', fillColor: whiteColorCode , lineWidth: 1}}, 
        
    ],[ 
        {content: 'Notes:' , colSpan: 7, styles: { halign: 'left', fillColor: whiteColorCode , lineWidth: 0}}, 
        
    ],[      
        {content: packinglist_note, colSpan: 7 , styles: { halign: 'left', fillColor: whiteColorCode }} 
    ]);

    // Generate the PDF with the autoTable
    doc.autoTable({
        head: headers,
        body: body,
        columnStyles: {
            0: { cellWidth: tableWidth / 7 - 2 },
            1: { cellWidth: tableWidth / 7 },
            2: { cellWidth: tableWidth / 7 },
            3: { cellWidth: tableWidth / 7 },
            4: { cellWidth: tableWidth / 7 },
            5: { cellWidth: tableWidth / 7 },
            6: { cellWidth: tableWidth / 7 }
        },
        theme: 'grid',
        margin: { top: 20 },
        startY: y1,
        styles: { fontSize: 9, textColor: blackColorCode, cellPadding: 1, lineHeight: 1.2 }, // Set global font size
        didDrawPage: () => {
            // Add footer on each page
            addFooter(doc, "5751 Schaefer Ave., Chino, CA 91765",packinglist_SN);
        },
    });

    doc.save(packinglist_fileName);


}

export default generatePackingListPDF;