import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { GetTextByRegion } from '../../hooks/Translation';

const CompletedConfirmationDialog = ({ open, onClose, onConfirm ,selectedRegion}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{GetTextByRegion("Completed Spare Part Request",selectedRegion)}</DialogTitle>
      <DialogContent>{GetTextByRegion("Are you sure you sure the spare part request is completed?",selectedRegion)}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{GetTextByRegion("Cancel",selectedRegion)}</Button>
        <Button onClick={onConfirm} variant="contained" color="secondary">{GetTextByRegion("Confirm",selectedRegion)}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompletedConfirmationDialog;