import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ErrorDialog from '../components/ErrorDialog';
import { Edit, Delete, ArrowUpward, ArrowDownward } from '@mui/icons-material'; // Import arrow icons
import axios from 'axios';
import DeleteConfirmationDialog from '../components/DeleteConfirmationDialog';
import { useUser } from '../hooks/UserContext';
import ModelDropdown from '../components/ModelDropdown';
import useWindowSize from '../hooks/useWindowSize';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const PackingListView = () => {
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState('');
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
  const [deleteAllConfirmationDialogOpen, setDeleteAllConfirmationDialogOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [newModel, setNewModel] = useState('');
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    partnumber: '',
    description: '',
    quantity: '',
  });
  const [editIndex, setEditIndex] = useState(null);

  // Dialog state
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openModelDialog, setOpenModelDialog] = useState(false); // New state for "Add Model" dialog

  const { selectedRegion } = useUser();
  const { windowSize, smallWidth } = useWindowSize();



  useEffect(() => {
    fetchPackingLists();
  }, []);

  const fetchPackingLists = async () => {
    try {
      const response = await axios.get(`${backendString}/packingList`);
      setModels(response.data);
    } catch (error) {
      console.error('Error fetching packing lists', error);
    }
  };

  const handleModelChange = (event) => {
    setSelectedModel(event.target.value);
    setFormData({
      partnumber: '',
      description: '',
      quantity: '',
    });
    setEditIndex(null); // Reset edit index on model change
  };

  const handleAddNewModel = async () => {
    if (!newModel) return;
    try {
      const response = await axios.post(`${backendString}/packingList`, { model: newModel });
      setModels([...models, response.data]);
      setNewModel('');
      setSelectedModel(response.data._id);
      setOpenModelDialog(false); // Close the dialog after adding the model
    } catch (error) {
      setError(error.response?.data?.message);
      console.error('Error adding new model', error);
    }
  };

  const handleAddToModel = async (modelId) => {
    const selectedModelData = models.find((model) => model._id === modelId);
    if (!selectedModelData) return;

    const updatedModel = {
      ...selectedModelData,
      partnumber: [...selectedModelData.partnumber, formData.partnumber],
      description: [...selectedModelData.description, formData.description],
      quantity: [...selectedModelData.quantity, formData.quantity],
    };

    try {
      await axios.put(`${backendString}/packingList/${modelId}`, updatedModel);
      const updatedModels = models.map((model) =>
        model._id === modelId ? updatedModel : model
      );
      setModels(updatedModels);
      setFormData({ partnumber: '', description: '', quantity: '' });
      setOpenAddDialog(false);
    } catch (error) {
      setError(error.response?.data?.message);
      console.error('Error adding row to model', error);
    }
    setFormData({ partnumber: '', description: '', quantity: '' });
  };

  const handleEditRow = (index) => {
    const selectedModelData = models.find((model) => model._id === selectedModel);
    if (!selectedModelData) return;

    const selectedRow = {
      partnumber: selectedModelData.partnumber[index],
      description: selectedModelData.description[index],
      quantity: selectedModelData.quantity[index],
    };

    setFormData(selectedRow);
    setEditIndex(index);
    setOpenEditDialog(true);
  };

  const handleSaveEdit = async () => {
    const selectedModelData = models.find((model) => model._id === selectedModel);
    if (!selectedModelData) return;

    const updatedModel = {
      ...selectedModelData,
      partnumber: selectedModelData.partnumber.map((part, index) =>
        index === editIndex ? formData.partnumber : part
      ),
      description: selectedModelData.description.map((desc, index) =>
        index === editIndex ? formData.description : desc
      ),
      quantity: selectedModelData.quantity.map((qty, index) =>
        index === editIndex ? formData.quantity : qty
      ),
    };

    try {
      await axios.put(`${backendString}/packingList/${selectedModel}`, updatedModel);
      const updatedModels = models.map((model) =>
        model._id === selectedModel ? updatedModel : model
      );
      setModels(updatedModels);
      setFormData({ partnumber: '', description: '', quantity: '' });
      setOpenEditDialog(false);
    } catch (error) {
      setError(error.response?.data?.message);
      console.error('Error saving edited row', error);
    }
  };

  const handleDeleteRow = async (index) => {
    setDeleteIndex(index);
    setDeleteConfirmationDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    const selectedModelData = models.find((model) => model._id === selectedModel);
    if (!selectedModelData || deleteIndex === null) return;

    const updatedModel = {
      ...selectedModelData,
      partnumber: selectedModelData.partnumber.filter((_, idx) => idx !== deleteIndex),
      description: selectedModelData.description.filter((_, idx) => idx !== deleteIndex),
      quantity: selectedModelData.quantity.filter((_, idx) => idx !== deleteIndex),
    };

    try {
      await axios.put(`${backendString}/packingList/${selectedModel}`, updatedModel);
      const updatedModels = models.map((model) =>
        model._id === selectedModel ? updatedModel : model
      );
      setModels(updatedModels);
      setDeleteConfirmationDialogOpen(false);
    } catch (error) {
      setError(error.response?.data?.message);
      console.error('Error deleting row', error);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteConfirmationDialogOpen(false);
  };

  const handleCloseDeleteAllDialog = () => {
    setDeleteAllConfirmationDialogOpen(false);
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  const handleConfirmDeleteAll = async () => {
    const modelId = selectedModel; // Get the ID of the current model
    try {
      await axios.delete(`${backendString}/packingList/${modelId}`); // Call the delete endpoint for the current model
      setModels(models.filter((model) => model._id !== modelId)); // Remove the deleted model from state
      setSelectedModel(''); // Reset the selected model
    } catch (error) {
      console.error('Error deleting all items from packing list', error);
    } finally {
      setDeleteAllConfirmationDialogOpen(false); // Close dialog after confirming
    }
  };

  const moveRow = async (direction, index) => {
    if (index === null || !selectedModel) return;

    const selectedModelData = models.find((model) => model._id === selectedModel);
    if ((direction === 'up' && index === 0) || (direction === 'down' && index === selectedModelData.partnumber.length - 1)) return;

    const updatedModel = { ...selectedModelData };

    // Calculate new index
    const newIndex = direction === 'up' ? index - 1 : index + 1;

    // Swap the rows
    const tempPartnumber = [...selectedModelData.partnumber];
    const tempDescription = [...selectedModelData.description];
    const tempQuantity = [...selectedModelData.quantity];

    // Swap the elements
    [tempPartnumber[index], tempPartnumber[newIndex]] = [tempPartnumber[newIndex], tempPartnumber[index]];
    [tempDescription[index], tempDescription[newIndex]] = [tempDescription[newIndex], tempDescription[index]];
    [tempQuantity[index], tempQuantity[newIndex]] = [tempQuantity[newIndex], tempQuantity[index]];

    // Update the model with swapped arrays
    updatedModel.partnumber = tempPartnumber;
    updatedModel.description = tempDescription;
    updatedModel.quantity = tempQuantity;

    try {
      const response = await axios.put(`${backendString}/packingList/${selectedModel}`, updatedModel);
      const updatedModels = models.map((model) =>
        model._id === selectedModel ? response.data : model
      );
      setModels(updatedModels);
      // Update the edit index to reflect the new position
      setEditIndex(newIndex);
    } catch (error) {
        setError(error.response?.data?.message);
      console.error('Error moving row in packing list', error);
    }
  };

  return (
    <div>
      <Box sx={{ padding: 2 }}>
        <h3>Select a Model</h3>
        <FormControl fullWidth>
          <InputLabel id="model-select-label">Model</InputLabel>
          <Select
            labelId="model-select-label"
            value={selectedModel}
            onChange={handleModelChange}
          >
            {models.map((model) => (
              <MenuItem key={model._id} value={model._id}>
                {model.model}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Button to open "Add New Model" dialog */}
        <Button
          variant="contained"
          onClick={() => setOpenModelDialog(true)} // Opens the dialog
          sx={{ marginTop: 2 }}
        >
          Add New Model
        </Button>
      </Box>

      {/* "Add New Model" Dialog */}
      <Dialog open={openModelDialog} onClose={() => setOpenModelDialog(false)}>
        <DialogTitle>Add New Model</DialogTitle>
        <DialogContent>
          {/* <TextField
            label="Model Name"
            fullWidth
            value={newModel}
            onChange={(e) => setNewModel(e.target.value)}
          /> */}
          <ModelDropdown
            selectedRegion={selectedRegion}
            value={newModel}
            name="model"
            onChange={(e) => setNewModel(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModelDialog(false)}>Cancel</Button>
          <Button onClick={handleAddNewModel} variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {selectedModel && (
        <div>
          <Box sx={{ padding: 2 }} >
            <h3>{models.find((model) => model._id === selectedModel)?.model} Packing List</h3>
            <Button
              variant="contained"
              onClick={() => setOpenAddDialog(true)}
              sx={{ marginBottom: 2 }}
            >
              Add Row
            </Button>

            <DataGrid
              rows={models
                .find((model) => model._id === selectedModel)
                ?.partnumber.map((_, index) => ({
                  id: index,
                  partnumber: models.find((model) => model._id === selectedModel)?.partnumber[index],
                  description: models.find((model) => model._id === selectedModel)?.description[index],
                  quantity: models.find((model) => model._id === selectedModel)?.quantity[index],
                })) || []}
              columns={[
                { field: 'partnumber', headerName: 'Part Number', flex: 1 },
                { field: 'description', headerName: 'Description', flex: 2 },
                { field: 'quantity', headerName: 'Quantity', flex: 1 },
                {
                  field: 'actions',
                  headerName: 'Actions',
                  width: 200,
                  renderCell: (params) => (
                    <div>
                      <IconButton color='primary' onClick={() => handleEditRow(params.id)}>
                        <Edit />
                      </IconButton>
                      <IconButton color='secondary' onClick={() => handleDeleteRow(params.id)}>
                        <Delete />
                      </IconButton>
                      <IconButton onClick={() => moveRow('up', params.id)}>
                        <ArrowUpward />
                      </IconButton>
                      <IconButton onClick={() => moveRow('down', params.id)}>
                        <ArrowDownward />
                      </IconButton>
                    </div>
                  ),
                },
              ]}
              autoHeight
            />

            <Button
              color="error"
              onClick={() => setDeleteAllConfirmationDialogOpen(true)}
              sx={{ marginTop: 2, marginLeft: 2 }}
            >
              Delete Model
            </Button>
          </Box>

          {/* Add Row Dialog */}
          <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
            <DialogTitle>Add New Row</DialogTitle>
            <DialogContent>
              <TextField
                label="Part Number"
                fullWidth
                value={formData.partnumber}
                onChange={(e) => setFormData({ ...formData, partnumber: e.target.value })}
              />
              <TextField
                label="Description"
                fullWidth
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                sx={{ marginTop: 2 }}
              />
              <TextField
                label="Quantity"
                fullWidth
                value={formData.quantity}
                onChange={(e) => setFormData({ ...formData, quantity: e.target.value })}
                sx={{ marginTop: 2 }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => { setOpenAddDialog(false); setFormData({ partnumber: '', description: '', quantity: '' }); }}>Cancel</Button>
              <Button
                onClick={() => handleAddToModel(selectedModel)}
                variant="contained"
              >
                Add
              </Button>
            </DialogActions>
          </Dialog>

          {/* Edit Row Dialog */}
          <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
            <DialogTitle>Edit Row</DialogTitle>
            <DialogContent>
              <TextField
                label="Part Number"
                fullWidth
                value={formData.partnumber}
                onChange={(e) => setFormData({ ...formData, partnumber: e.target.value })}
              />
              <TextField
                label="Description"
                fullWidth
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                sx={{ marginTop: 2 }}
              />
              <TextField
                label="Quantity"
                fullWidth
                value={formData.quantity}
                onChange={(e) => setFormData({ ...formData, quantity: e.target.value })}
                sx={{ marginTop: 2 }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {setOpenEditDialog(false); setFormData({ partnumber: '', description: '', quantity: '' });}}>Cancel</Button>
              <Button
                onClick={handleSaveEdit}
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>

          {/* Delete Confirmation Dialog */}
          <DeleteConfirmationDialog
            open={deleteConfirmationDialogOpen}
            onClose={handleCloseDeleteDialog}
            onConfirm={handleConfirmDelete}
            message="Are you sure you want to delete this row?"
          />

          {/* Delete All Confirmation Dialog */}
          <DeleteConfirmationDialog
            open={deleteAllConfirmationDialogOpen}
            onClose={handleCloseDeleteAllDialog}
            onConfirm={handleConfirmDeleteAll}
            message="Are you sure you want to delete all rows?"
          />
        </div>
      )}

      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
        selectedRegion={selectedRegion}
      />
    </div>
  );
};

export default PackingListView;
