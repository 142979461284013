import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const UserContext = createContext();

export const useValidateRegions = (selectedRegion, validRegions) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (validRegions && !validRegions.includes(selectedRegion)) {
      navigate('/');
    }
  }, [selectedRegion, validRegions, navigate]);
};

export const UserProvider = ({ children }) => {
  const initialUserState = React.useMemo(() => ({
    userRights: 0, 
    role: '',
    region: [],
    username: '',
    email: '',
    authError: null,
  }), []);
  
  const navigate = useNavigate();
  const [user, setUser] = useState(initialUserState);
  const [selectedRegion, setSelectedRegion] = useState(getStoredRegion() || '');
  const [error, setError] = useState(null);

  const updateUser = useCallback((userData) => {
    setUser((prevUser) => ({ ...prevUser, ...userData }));
    
    if (getStoredRegion() !== selectedRegion) {
      setSelectedRegion(getStoredRegion());
    }
    
    if (userData.region && userData.region.length > 0 && !userData.region.includes(selectedRegion)) {
      const initialRegion = userData.region[0];
      setSelectedRegion(initialRegion);
      setStoredRegion(initialRegion);
    }
  }, [selectedRegion]);

  const resetUser = useCallback(() => {
    setUser(initialUserState);
    setSelectedRegion('');
    sessionStorage.clear();
    setError(null);
  }, [initialUserState]);

  const refreshUser = useCallback(async () => {
    const backendString = process.env.REACT_APP_BACKEND_STRING;
    setError(null);
    try {
      const response = await axios.get(`${backendString}/users/validate`, {
        headers: {
          'Authorization': sessionStorage.getItem('token'),
        },
      });
      updateUser({
        userRights: response.data.rolenumber,
        role: response.data.role,
        region: response.data.region || [],
        username: response.data.username,
        email: response.data.email,
        authError: null,
      });
    } catch (error) {
      console.error('Error refreshing user data', error);
      resetUser(); // Reset user on error
      setError(error);
    }
  }, [updateUser, resetUser]);

  useEffect(() => {
    if (user.region.length > 0 && !user.region.includes(selectedRegion)) {
      const initialRegion = user.region[0];
      setSelectedRegion(initialRegion);
      setStoredRegion(initialRegion);
    }
  }, [user.region, selectedRegion]);

  useEffect(() => {
    if (error) {
      navigate('/login');
      setError(null);
    }
  }, [error, navigate]);

  return (
    <UserContext.Provider value={{ user, updateUser, resetUser, refreshUser, selectedRegion, setSelectedRegion, error, setError }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);

export const getStoredRegion = () => sessionStorage.getItem('selectedRegion');
export const setStoredRegion = (region) => sessionStorage.setItem('selectedRegion', region);
