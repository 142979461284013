import React, { useState, useEffect, useCallback } from 'react';
import { Button } from '@mui/material';
import axios from 'axios';
import ServiceTable from '../components/Service/ServiceTable';
import ServiceOrderForm from '../components/Service/ServiceOrderForm';
import { useUser, useValidateRegions } from '../hooks/UserContext';
import roles from '../hooks/Roles';
import ErrorDialog from '../components/ErrorDialog';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const connectionString = `${backendString}/services`

const ServiceOrder = () => {
    const [isServicePopupOpen, setIsServicePopupOpen] = useState(false);
    const [services, setServices] = useState([]);
    const validateSelectedRegion = ["USA"];
    const [error, setError] = useState(null);
    const {selectedRegion, user:{userRights}, refreshUser} = useUser();
    useValidateRegions(selectedRegion, validateSelectedRegion); 

    useEffect(() => {
      refreshUser();
    },[refreshUser,isServicePopupOpen]);

    const fetchServices = useCallback(async () => {
      try {
        const response = await axios.get(`${connectionString}`, {headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });
        setServices(response.data);
      } catch (error) {
        console.error('Error fetching services:', error);
        setError(error.response?.data?.error);
      }
    }, [setServices]);

    useEffect(() => {
      fetchServices();
    },[fetchServices]);
  
    const handleOpenPopup = () => {
      setIsServicePopupOpen(true);
    };
  
    const handleClosePopup = () => {
      setIsServicePopupOpen(false);
    };
  
    const handleAddService = (newService) => {
      setServices([...services, newService]);
    };

    const handleCloseErrorDialog = () => {
      setError(null);
    };
  
    return (
      <div>
        <Button variant="contained" color="primary" onClick={handleOpenPopup}>
          Add New Service Order
        </Button>
        {isServicePopupOpen && (userRights === roles["SALES"] || userRights >= roles["MODERATOR"]) &&
          <ServiceOrderForm
            mode="add"
            open={isServicePopupOpen}
            onClose={handleClosePopup}
            onAdd={handleAddService}
          />
        }
        <ServiceTable services = {services} setServices = {setServices}/>

        <ErrorDialog
          open={!!error}
          onClose={handleCloseErrorDialog}
          errorMessage={error}
        />
      </div>
    );
  };
  
  export default ServiceOrder;