import axios from 'axios';

const backendString = process.env.REACT_APP_BACKEND_STRING;

export const CheckInventory = async (partNumbers, quantities, selectedRegion) => {
  const fetchItems = async () => {
    const connectionString = backendString + '/items';

    try {
      const response = await axios.get(`${connectionString}?selectedRegion=${selectedRegion}`);
      return response.data; // Return the fetched data
    } catch (error) {
      console.error('Error fetching items:', error);
      throw new Error('Error fetching items'); // Rethrow error to allow calling code to handle it
    }
  };

  try {
    // Fetch inventory items
    const inventory = await fetchItems();

    // Create a map for quick lookup of partNumber and its available quantity
    const inventoryMap = new Map(inventory.map(item => [item.partnumber, item.quantity]));

    // Check the availability of each part number and quantity
    const errors = partNumbers.map((partNumber, index) => {
      const quantity = quantities[index];
      const availableQuantity = inventoryMap.get(partNumber);

      if (availableQuantity === undefined) {
        return { partNumber, isExist: false, message: `Part number ${partNumber} does not exist in the inventory.` };
      } else if (availableQuantity < quantity) {
        return { partNumber, isExist: true, message: `Not enough quantity for part number ${partNumber}. Requested: ${quantity}, Available: ${availableQuantity}` };
      }
      return null;
    }).filter(error => error !== null);

    return errors;
  } catch (error) {
    console.error('Failed to fetch inventory or process data:', error);
    return [{ partNumber: 'N/A', message: 'Failed to fetch inventory or process data.' }];
  }
};
