import React from 'react';
import { Typography, Container, styled } from '@mui/material';

const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '80vh',
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
}));

const Title = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
}));

const BodyText = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    fontSize: '1.2rem',
}));

const WorkInProgressPage = () => {
    return (
        <StyledContainer>
            <Title variant="h4" gutterBottom>
                Work in Progress<br/>正在更新<br/>En Progreso<br/>進行中的頁面
            </Title>
            <BodyText variant="body1">
                This page is currently under development. Please check back later for updates.<br/>
                此页面目前正在开发中。请稍后再来查看最新进展。<br/>
                Esta página se encuentra actualmente en desarrollo. Por favor, vuelva a consultar más tarde para actualizaciones.<br/>
                此頁面目前正在開發中。請稍後再來查看最新進展。<br/>
            </BodyText>
        </StyledContainer>
    );
};

export default WorkInProgressPage;
