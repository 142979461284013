import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, Button, Dialog, DialogTitle, DialogContent, IconButton, DialogActions, Typography, Divider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import ErrorDialog from '../ErrorDialog';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const SavedServicesList = ({ open, onClose, onSelect }) => {
  const [services, setServices] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (open) {
      const fetchDrafts = async () => {
        try {
          const response = await axios.get(`${backendString}/remoteservices/drafts`, {
            headers: {
              'Authorization': sessionStorage.getItem('token')
            },
          });
          setServices(response.data);
        } catch (error) {
          console.error('Error fetching remote services:', error);
          setError(error.response?.data?.error);
        }
      };

      fetchDrafts();
    }
  }, [open]);

  const handleDelete = async () => {
    if (serviceToDelete) {
      try {
        await axios.delete(`${backendString}/remoteservices/${serviceToDelete._id}`, {
          headers: {
            'Authorization': sessionStorage.getItem('token')
          },
        });
        setServices(services.filter(service => service._id !== serviceToDelete._id));
        setServiceToDelete(null);
        setConfirmDialogOpen(false);
      } catch (error) {
        console.error('Error deleting remote service:', error);
        setError(error.response?.data?.error);
      }
    }
  };

  const handleOpenConfirmDialog = (service, event) => {
    event.stopPropagation(); // Stop click event from propagating to ListItem
    setServiceToDelete(service);
    setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setServiceToDelete(null);
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>
          Resume Saved Remote Service
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close" style={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
        {services.length === 0 ? (
            <Typography>No drafts available.</Typography>
          ) : (
        <List>
              {services.map(service => (
                <React.Fragment key={service._id}>
                  <ListItem
                    button
                    onClick={() => onSelect(service)}
                    secondaryAction={
                      <IconButton edge="end" color="secondary" aria-label="delete" onClick={(event) => handleOpenConfirmDialog(service, event)}>
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      secondary={
                        <>
                          <Typography component="span" variant="body2" color="textPrimary">
                            Customer: {service.customer} - Issue Type: {service.issueType}
                          </Typography>
                          <br />
                        </>
                      }
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={confirmDialogOpen}
        onClose={handleCloseConfirmDialog}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this service?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
       {/* Error Dialog */}
       <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </>
  );
};

export default SavedServicesList;
