import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorDialog from '../ErrorDialog';
import useWindowSize from '../../hooks/useWindowSize';
import CustomDataGrid from '../CustomDataGrid';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import ImportCSVButton from '../Inventory/ImportCVSButton';
import SearchBar from '../SearchBar';
import MobileViewComponent from '../MobileView';
import Papa from 'papaparse';
import roles from '../../hooks/Roles';
import { useUser } from '../../hooks/UserContext';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const CustomerTable = ({ customers, setCustomers, openEditDialog, setOpenEditDialog, openDeleteDialog, setOpenDeleteDialog }) => {
  const [editCustomer, setEditCustomer] = useState({});
  const [deleteCustomerId, setDeleteCustomerId] = useState(null);
  const [error, setError] = useState(null);
  const { windowSize, smallWidth } = useWindowSize();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const { selectedRegion, user: { userRights } } = useUser();

  const fetchCustomers = useCallback(async () => {
    try {
      const response = await axios.get(backendString + '/customers', {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });
      setCustomers(response.data);
    } catch (error) {
      setError(error.response?.data?.error);
    }
  }, [setCustomers]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  useEffect(() => {
    setFilteredCustomers(customers.filter(customer =>
      Object.values(customer).some(val =>
        String(val).toLowerCase().includes(searchQuery.toLowerCase())
      )
    ));
  }, [searchQuery, customers]);

  const handleEdit = (customerId) => {
    const customerToEdit = customers.find(customer => customer._id === customerId);
    if (customerToEdit) {
      setEditCustomer(customerToEdit);
      setOpenEditDialog(true);
    }
  };

  const handleEditSubmit = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      await axios.put(backendString + `/customers/${editCustomer._id}`, editCustomer, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });
      setOpenEditDialog(false);
      fetchCustomers();
    } catch (error) {
      setError(error.response?.data?.error);
    }
  };

  const handleDelete = (customerId) => {
    setDeleteCustomerId(customerId);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(backendString + `/customers/${deleteCustomerId}`, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });
      setOpenDeleteDialog(false);
      fetchCustomers();
    } catch (error) {
      setError(error.response?.data?.error);
    }
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setEditCustomer({});
    setValidationErrors({});
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDeleteCustomerId(null);
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  const validateForm = () => {
    // Add your validation logic here
    const errors = {};
    let isValid = true;

    if (!editCustomer.OrganizationName) {
      errors.OrganizationName = 'Organization Name is required';
      isValid = false;
    }

    setValidationErrors(errors);
    return isValid;
  };

  const exportToCSV = () => {
    // Map the items to exclude the _id field and ensure all fields are present
    const dataToExport = filteredCustomers.map(item => {
      const { _id, ...rest } = item;

      // Ensure all fields are included with default values if they are empty or missing
      return {
        org: rest.org || '',
        phone: rest.phone || '',
        street: rest.street || '',
        city: rest.city || '',
        state: rest.state || '',
        country: rest.country || '',
        psotal: rest.postal || '',
      };
    });
    // Convert data to CSV format
    const csvData = Papa.unparse(dataToExport);

    // Create a Blob from the CSV data
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

    // Create a link element for downloading the CSV file
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'customers.csv';

    // Programmatically click the link to trigger the download
    link.click();
  };

  const importCSV = async (csvFile) => {
    if (!csvFile) {
      setError('No file selected');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('csvFile', csvFile); // Append the CSV file to FormData
      await axios.post(backendString + '/customers/import', formData, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });
    } catch (error) {
      setError('Error importing customer');
    }
  };

  const columns = [
    { field: 'OrganizationName', headerName: 'Organization Name', flex: 1 },
    { field: 'Background', headerName: 'Background', flex: 1 },
    { field: 'BillingAddressStreet', headerName: 'Billing Address Street', flex: 1 },
    { field: 'BillingAddressCity', headerName: 'Billing Address City', flex: 1 },
    { field: 'BillingAddressState', headerName: 'Billing Address State', flex: 1 },
    { field: 'BillingAddressPostalCode', headerName: 'Billing Address Postal Code', flex: 1 },
    { field: 'BillingAddressCountry', headerName: 'Billing Address Country', flex: 1 },
    { field: 'ShippingAddressStreet', headerName: 'Shipping Address Street', flex: 1 },
    { field: 'ShippingAddressCity', headerName: 'Shipping Address City', flex: 1 },
    { field: 'ShippingAddressState', headerName: 'Shipping Address State', flex: 1 },
    { field: 'ShippingAddressCountry', headerName: 'Shipping Address Country', flex: 1 },
    { field: 'ShippingAddressPostalCode', headerName: 'Shipping Address Postal Code', flex: 1 },
    { field: 'Phone', headerName: 'Phone', flex: 1 },
    { field: 'Fax', headerName: 'Fax', flex: 1 },
    { field: 'Website', headerName: 'Website', flex: 1 },
    { field: 'EmailDomain', headerName: 'Email Domain', flex: 1 },
    { field: 'Tag1', headerName: 'Tag 1', flex: 1 },
    { field: 'Tag2', headerName: 'Tag 2', flex: 1 },
    { field: 'Tag3', headerName: 'Tag 3', flex: 1 },
    { field: 'Tag4', headerName: 'Tag 4', flex: 1 },
    { field: 'Tag5', headerName: 'Tag 5', flex: 1 },
    { field: 'DateOfLastActivity', headerName: 'Date of Last Activity', flex: 1 },
    { field: 'DateOfNextActivity', headerName: 'Date of Next Activity', flex: 1 },
    { field: 'OrganisationOwner', headerName: 'Organisation Owner', flex: 1 },
    { field: 'OrganizationType', headerName: 'Organization Type', flex: 1 },
    { field: 'Rep', headerName: 'Rep', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        userRights >= roles['MANAGER'] && (
          <Box>
            <IconButton aria-label="edit" color="primary" onClick={() => handleEdit(params.id)}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label="delete" color="secondary" onClick={() => handleDelete(params.id)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        )
      ),
    }
  ];

  const mobileColumns = [
    { label: "Organization Name", name: "OrganizationName", type: "text", value: "organizationname", required: true, InputLabelProps: {}, position: 'left' },
    { label: "Background", name: "Background", type: "text", value: "background", required: true, InputLabelProps: {}, position: 'left' },
    { label: "Billing Address Street", name: "BillingAddressStreet", type: "text", value: "billingaddressstreet", required: true, InputLabelProps: {}, position: 'left' },
    { label: "Billing Address City", name: "BillingAddressCity", type: "text", value: "billingaddresscity", required: true, InputLabelProps: {}, position: 'left' },
    { label: "Billing Address State", name: "BillingAddressState", type: "text", value: "billingaddressstate", required: true, InputLabelProps: {}, position: 'left' },
    { label: "Billing Address Postal Code", name: "BillingAddressPostalCode", type: "text", value: "billingaddresspostalcode", required: true, InputLabelProps: {}, position: 'left' },
    { label: "Billing Address Country", name: "BillingAddressCountry", type: "text", value: "billingaddresscountry", required: true, InputLabelProps: {}, position: 'left' },
    { label: "Shipping Address Street", name: "ShippingAddressStreet", type: "text", value: "shippingaddressstreet", required: true, InputLabelProps: {}, position: 'left' },
    { label: "Shipping Address City", name: "ShippingAddressCity", type: "text", value: "shippingaddresscity", required: true, InputLabelProps: {}, position: 'left' },
    { label: "Shipping Address State", name: "ShippingAddressState", type: "text", value: "shippingaddressstate", required: true, InputLabelProps: {}, position: 'left' },
    { label: "Shipping Address Postal Code", name: "ShippingAddressPostalCode", type: "text", value: "shippingaddresspostalcode", required: true, InputLabelProps: {}, position: 'left' },
    { label: "Shipping Address Country", name: "ShippingAddressCountry", type: "text", value: "shippingaddresscountry", required: true, InputLabelProps: {}, position: 'left' },
    { label: "Phone", name: "Phone", type: "text", value: "phone", required: true, InputLabelProps: {}, position: 'right' },
    { label: "Fax", name: "Fax", type: "text", value: "fax", required: true, InputLabelProps: {}, position: 'right' },
    { label: "Website", name: "Website", type: "text", value: "website", required: true, InputLabelProps: {}, position: 'right' },
    { label: "Email Domain", name: "EmailDomain", type: "text", value: "emaildomain", required: true, InputLabelProps: {}, position: 'right' },
    { label: "Tag1", name: "Tag1", type: "text", value: "tag1", required: false, InputLabelProps: {}, position: 'right' },
    { label: "Tag2", name: "Tag2", type: "text", value: "tag2", required: false, InputLabelProps: {}, position: 'right' },
    { label: "Tag3", name: "Tag3", type: "text", value: "tag3", required: false, InputLabelProps: {}, position: 'right' },
    { label: "Tag4", name: "Tag4", type: "text", value: "tag4", required: false, InputLabelProps: {}, position: 'right' },
    { label: "Tag5", name: "Tag5", type: "text", value: "tag5", required: false, InputLabelProps: {}, position: 'right' },
    { label: "Date Of Last Activity", name: "DateOfLastActivity", type: "date", value: "dateoflastactivity", required: true, InputLabelProps: { shrink: true }, position: 'right' },
    { label: "Date Of Next Activity", name: "DateOfNextActivity", type: "date", value: "dateofnextactivity", required: true, InputLabelProps: { shrink: true }, position: 'right' },
    { label: "Organisation Owner", name: "OrganisationOwner", type: "text", value: "organisationowner", required: true, InputLabelProps: {}, position: 'right' },
    { label: "Organization Type", name: "OrganizationType", type: "text", value: "organizationtype", required: true, InputLabelProps: {}, position: 'right' },
    { label: "Rep", name: "Rep", type: "text", value: "rep", required: true, InputLabelProps: {}, position: 'right' },
  ];

  return (
    <div style={{ height: windowSize.height * 0.7, width: windowSize.width * 1 }}>
      <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} placeholder={"Search Customers..."} />
      <Box display="flex">
        {userRights >= roles['PRODUCTION'] && (<><ImportCSVButton importCSV={importCSV} selectedRegion={selectedRegion} />
          <Button onClick={exportToCSV}>Export to CSV</Button>
        </>)}
      </Box>

      {windowSize.width > smallWidth ? <CustomDataGrid rows={filteredCustomers} columns={columns} pageSize={windowSize.width < 600 ? 5 : 10} width={5000} />
        : <MobileViewComponent items={filteredCustomers} columnData={mobileColumns} handleEditClick={handleEdit} handleDelete={handleDelete} />}

      {/* Edit Dialog */}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit Customer</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2} width={300}>
            {Object.keys(editCustomer).map((key) => (
              <TextField
                key={key}
                margin="dense"
                label={key.replace(/([A-Z])/g, ' $1').trim()}
                fullWidth
                value={editCustomer[key]}
                onChange={(e) =>
                  setEditCustomer({
                    ...editCustomer,
                    [key]: e.target.value
                  })
                }
                error={Boolean(validationErrors[key]) && !editCustomer[key]}
                helperText={editCustomer[key] ? '' : validationErrors[key]}
                multiline
                rows={key.includes('Address') ? 3 : 1}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>Cancel</Button>
          <Button onClick={handleEditSubmit} variant="contained" color="primary">Save</Button>
        </DialogActions>
      </Dialog>

      <DeleteConfirmationDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDeleteConfirm}
        selectedRegion={selectedRegion}
      />
      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </div>
  );
};

export default CustomerTable;
