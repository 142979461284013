import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText, Divider, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import ErrorDialog from '../ErrorDialog';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const DraftsList = ({ open, onClose, drafts, onSelectForm, onSelectReport, fetchDrafts }) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [draftToDelete, setDraftToDelete] = useState(null);
  const [error, setError] = useState(null);
  const {selectedRegion} = useUser();

  const handleDelete = async () => {
    if (draftToDelete) {
      try {
              // Determine the base URL based on the selected region
        let connectionString = '';
        connectionString = `${backendString}/nonconforming`;

        const endpoint = draftToDelete.formName === 'Nonconforming' 
        ? `${connectionString}/${draftToDelete._id}?selectedRegion=${selectedRegion}` 
        : `${backendString}/serviceforms/${draftToDelete._id}?selectedRegion=${selectedRegion}`;

        await axios.delete(endpoint, {
          headers: {
            'Authorization': sessionStorage.getItem('token')
          },
        });
        setDraftToDelete(null);
        setConfirmDialogOpen(false);
        fetchDrafts();
      } catch (error) {
        console.error('Error deleting draft:', error);
        setError(error.response?.data?.error);
      }
    }
  };

  const handleOpenConfirmDialog = (draft, event) => {
    event.stopPropagation(); // Stop click event from propagating to ListItem
    setDraftToDelete(draft);
    setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setDraftToDelete(null);
  };
  
  const handleCloseErrorDialog = () => {
    setError(null);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>
          {GetTextByRegion("Saved Forms",selectedRegion)}
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close" style={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {drafts.length === 0 ? (
            <Typography>{GetTextByRegion("No drafts available",selectedRegion)}.</Typography>
          ) : (
            <List>
              {drafts.map(draft => (
                <React.Fragment key={draft._id}>
                  <ListItem
                    button
                    onClick={() => {
                      // Determine which function to call based on formName
                      if (draft.formName === 'Service Form') {
                        onSelectForm(draft);
                      } else {
                        onSelectReport(draft);
                      }
                    }}
                    secondaryAction={
                      <IconButton edge="end" color="secondary" aria-label="delete" onClick={(event) => handleOpenConfirmDialog(draft, event)}>
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      primary={GetTextByRegion("Report Number",selectedRegion) +": "+ draft.reportNumber}
                      secondary={
                        <>
                          <Typography component="span" variant="body2" color="textPrimary">
                            {GetTextByRegion("Serial Number",selectedRegion)}: {draft.serialNumber}
                          </Typography>
                          <br />
                          {GetTextByRegion("Customer",selectedRegion)}: {draft.customer}
                        </>
                      }
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          )}
        </DialogContent>
      </Dialog>

      <DeleteConfirmationDialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog} onConfirm={handleDelete} />
       {/* Error Dialog */}
       <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </>
  );
};

export default DraftsList;
