import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Optional: if you want to use the auto table feature
import scienscopelogo from '../../images/scienscopeLogo.png';
//ttf files
import '../../misc/NotoSansSC-Regular.ttf';
import '../../misc/NotoSansSC-Bold.ttf';
//font base64
import fontBase64_regular from '../../misc/NotoScansSC-Regular';
import fontBase64_bold from '../../misc/NotoScansSC-Bold';

const backendString = process.env.REACT_APP_BACKEND_STRING;

export const generateNonconformingPDF = (report, onSuccess, onError, selectedRegion) => {

  const pageMargin = 10;

  /*////////////////////////////////////////////////////////////
  //    Add text with a Underline and give it a fixed width  //
  //////////////////////////////////////////////////////////////*/
  const addTextWithUnderlines = (doc, text, x, y, maxWidth) => {
    const lines = [];
    const textHeight = doc.getTextDimensions('W').h * 1.2;
    //return if text is empty
    if (!text || text === '') {
      return y + textHeight;
    }
    //replace the newline characters
    text = text.replace(/\n/g, ' ');
    //split text if it is too long
    let line = '';
    const words = text.split(' ');
    for (const word of words) {
      const testLine = line + (line ? ' ' : '') + word;
      const testWidth = doc.getTextWidth(testLine);
      //if the current string width will be lager the max row width
      if (testWidth > maxWidth) {
        lines.push(line);
        line = word;
      } else {
        line = testLine;
      }
    }
    if (line) {
      lines.push(line);
    }

    lines.forEach(line => {
      addText(doc, line, x, y, textHeight);
      doc.line(x, y + 1, x + doc.getTextWidth(line), y + 1);
      y += textHeight;
    });
    return y;

  };


  /*/////////////////////////////////////////////////
  //    Add text and check if it fits on the page  //
  ///////////////////////////////////////////////////*/
  function addText(doc, text, x, y, Yoffset) {
    const pageHeight = doc.internal.pageSize.height;
    const pagewidth = doc.internal.pageSize.width;
    const textHeight = doc.getTextDimensions(text).h;

    text = text.replace(/\n/g, ' ');

    const lines = [];
    let line = '';
    const words = text.split(' ');
    for (const word of words) {
      const testLine = line + (line ? ' ' : '') + word;
      const testWidth = doc.getTextWidth(testLine) + pageMargin * 2;
      if (testWidth > pagewidth) {
        lines.push(line);
        line = word;
      } else {
        line = testLine;
      }
    }
    if (line) {
      lines.push(line);
    }

    for (const row of lines) {
      if (y + textHeight * 1.2 > pageHeight - pageMargin * 2) {
        doc.addPage();
        y = pageMargin;
        y += Yoffset;
      }
      doc.text(row, x, y);
      y += Yoffset;
    }
    if (lines.length === 0) {
      return y + Yoffset;
    } else {
      return y;
    }
  }

  /*////////////////////////////////////////////////////////////
//    Add text with a box and check if it fits on the page  //
//////////////////////////////////////////////////////////////*/
  function addTextWithRect(doc, text, x, y, maxBoxWidth) {

    let currentY = y;
    let currentX = x;
    if (currentX < pageMargin) {
      currentX = pageMargin;
    }
    let startIndex = 0;
    let contentMargin = 5;

    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;
    const textHeight = doc.getTextDimensions(text).h;
    const lineHeight = textHeight * 1.2;

    if (maxBoxWidth > pageWidth) {
      maxBoxWidth = pageWidth - 2 * pageMargin;
    }

    //split the text with a given width
    const splitText = doc.splitTextToSize(text, maxBoxWidth - 2 * contentMargin);

    while (startIndex < splitText.length) {
      // calculate current lines. = avaliable area height / line height
      const linesToShow = Math.round((pageHeight - currentY - 2 * contentMargin - pageMargin) / lineHeight);
      // calculate how much area it will take
      const lines = splitText.slice(startIndex, startIndex + linesToShow);
      const boxHeight = (lines.length) * lineHeight;

      // draw text.   
      doc.text(lines, x + contentMargin, currentY + lineHeight + contentMargin);
      doc.rect(x, currentY, maxBoxWidth, boxHeight + 2 * contentMargin);

      // update start index and current position y after draw the box
      startIndex += linesToShow;
      currentY += boxHeight + 2 * contentMargin; //top and bottom content margin

      // if there are more lines
      if (startIndex < splitText.length) {
        doc.addPage(); //new page
        currentY = pageMargin; // reset y position
      }
    }
    return currentY + lineHeight;
  }


  /*/////////////////////////////////////////////////
  //    Add image and check if it fits on the page  //
  ///////////////////////////////////////////////////*/
  function addImage(doc, imageData, x, y, imageHeight, imageWidth) {
    const pageHeight = doc.internal.pageSize.height;
    //const pagewidth = doc.internal.pageSize.width;
    const pageMargin = 10;

    if (y + imageHeight > pageHeight - pageMargin) {
      doc.addPage();
      y = pageMargin * 2;
    }
    doc.addImage(imageData, x, y, imageWidth, imageHeight);
    return y;
  }

  try {
    const doc = new jsPDF();
    doc.addFileToVFS('NotoSansSC-Regular.ttf', fontBase64_regular);
    doc.addFileToVFS('NotoSansSC-Bold.ttf', fontBase64_bold);
    doc.addFont('NotoSansSC-Regular.ttf', 'NotoScansSC', 'normal');
    doc.addFont('NotoSansSC-Bold.ttf', 'NotoScansSC', 'bold');
    doc.setFont('NotoScansSC', 'normal');

    const PageWidth = doc.internal.pageSize.getWidth();
    const PageHeight = doc.internal.pageSize.getHeight();

    const pageCenterX = (PageWidth - 3 * pageMargin) / 2;
    const Yoffset = 8;
    const lineHeight = 0.5;
    const tableWidth = PageWidth - 2 * pageMargin;
    const blackColorCode = [0, 0, 0];
    const greyColorCode = [200, 200, 200];

    let x1 = pageMargin;
    let y1 = pageMargin;
    let x2 = x1 + pageCenterX + pageMargin;
    let y2 = y1;

    /*//////////////////////////////////////////////////     scienscope logo image      ////////////////////////////////////////////////////*/
    const imgData = new Image();
    imgData.src = scienscopelogo;
    let imageWidth = 32;
    let imageHeight = 8;
    doc.addImage(imgData, 'PNG', PageWidth - pageMargin - imageWidth - 15, y1, imageWidth, imageHeight);

    /*/////////////////////////////////////////////          header for PDF report              //////////////////////////////////////////////////////*/
    doc.setFontSize(14);
    doc.setFont('NotoScansSC', 'bold');
    const Title = `Spare Parts Request Form (零部件索取表)`;
    const title_x = pageCenterX - doc.getTextWidth(Title) / 2 + pageMargin;
    y1 = addTextWithUnderlines(doc, Title, title_x, y1 + 6, doc.getTextWidth(Title) + 2, pageMargin, 1);

    // Draw underline
    y2 = y1;
    /*////////////////////////////////////////////////////              main body content          ////////////////////////////////////////////////////*/
    doc.setFontSize(10);
    const GrayColor = [105, 105, 105];
    // Information sections
    const rowsdata = [
      [
        { content: "Date Reported(报告日期):", styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
        { content: `${report.dateReported}`, styles: { halign: 'left', lineWidth: 0, textColor: GrayColor } },
        { content: "Model(模型):", styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
        { content: `${report.model}`, styles: { halign: 'left', lineWidth: 0, textColor: GrayColor } },
      ],
      [
        { content: 'Report Number(报告编号):', styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
        { content: `${report.reportNumber}`, styles: { halign: 'left', lineWidth: 0, textColor: GrayColor } },
        { content: "Serial Number(序列号):", styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
        { content: `${report.serialNumber}`, styles: { halign: 'left', lineWidth: 0, textColor: GrayColor } },
      ],
      [
        { content: "Customer(客户):", colSpan: 1, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
        { content: `${report.customer}`, colSpan: 3, styles: { halign: 'left', lineWidth: 0, textColor: GrayColor } },
      ],
      [
        { content: "Report By(报告者):", styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
        { content: `${report.reportBy}`, styles: { halign: 'left', lineWidth: 0, textColor: GrayColor } },
        { content: "Issue Type(问题类别):", styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
        { content: `${report.issueType}`, styles: { halign: 'left', lineWidth: 0, textColor: GrayColor } },
      ],
      [
        { content: "Problem:(问题):", colSpan: 4, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
      ],
      [
        { content: `${report.problem}`, colSpan: 4, styles: { halign: 'left', lineWidth: 0.1, textColor: GrayColor } },
      ],
      [
        { content: " ", colSpan: 4, styles: { halign: 'left', lineWidth: 0 } },
      ],
      [
        { content: "Description(问题描述):", colSpan: 4, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
      ],
      [
        { content: `${report.description}`, colSpan: 4, styles: { halign: 'left', lineWidth: 0.1, textColor: GrayColor } },
      ],
      [
        { content: "Suggested Solution - 建议解决方案:", colSpan: 4, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
      ],
      [
        { content: `${report.suggestedSolution || ''}`, colSpan: 4, styles: { halign: 'left', lineWidth: 0.1, textColor: GrayColor } },
      ],
    ];

    doc.autoTable({
      body: rowsdata,
      startY: y1, // Position the table
      startX: x1,
      margin: { top: 0, left: pageMargin, bottom: 0, right: 0 }, // Set margin to zero         
      styles: {
        font: 'NotoScansSC',
        cellPadding: 2, // Cell padding
        fontSize: 10, // Body text font size
        halign: 'left',
        valign: 'top',
        textColor: blackColorCode,
        lineColor: blackColorCode, // Body border color (black)
        overflow: 'linebreak', // Handle text overflow
      },
      columnStyles: {
        0: { cellWidth: tableWidth * 0.25 },
        1: { cellWidth: tableWidth * 0.25 },
        2: { cellWidth: tableWidth * 0.25 },
        3: { cellWidth: tableWidth * 0.25 },
      },
      theme: 'grid',
    });

    y1 = doc.autoTable.previous.finalY + Yoffset;

    y1 = addText(doc, "Image Attachments: ", x1, y1, Yoffset);
    x1 = pageMargin;

    // Add images
    const ImagesIDs = report.images;
    imageHeight = 50;
    imageWidth = 50;
    let index = 0

    ImagesIDs.forEach(imageId => {
      const imageUrl = backendString + '/nonconforming/images/' + imageId + `?reportedRegion=${selectedRegion}`;
      const imgData = new Image();
      imgData.src = imageUrl;
      if ((x1 + imageWidth) > (PageWidth - pageMargin)) {
        y1 += imageHeight;
        x1 = pageMargin;     
      }
      y1 = addImage(doc, imgData, x1, y1, imageHeight, imageWidth);
      x1 += imageWidth;
      index++;
    });

    if (index != 0) {
      y1 += imageHeight + pageMargin;
    } else {
      y1 = addText(doc, "No Image.", x1, y1, Yoffset);
    }
    // // To be filled by Kunshan Office
    if (y1 >= PageHeight) {
      y1 = pageMargin;
    }
    x1 = pageMargin;
    const rowsdata2 = [
      [{ content: "To be filled by Kunshan Office: ", colSpan: 4, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0, fontSize: 12 } }],
      [
        { content: "Resolution to the case (本案解决方案):", colSpan: 4, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0, fontSize: 10 } },
      ],
      [
        { content: "Date Resolved(解决日期):", colSpan: 1, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0, fontSize: 10 } },
        { content: `${report.dateResolved !== undefined ? report.dateResolved : ""}`, colSpan: 3, styles: { halign: 'left', lineWidth: 0, textColor: GrayColor, fontSize: 10 } },
      ],
      [
        { content: "Details(详细内容):", colSpan: 4, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0, fontSize: 10 } },
      ],
      [
        { content: `${report.details !== undefined ? report.details : ""}`, colSpan: 4, styles: { halign: 'left', lineWidth: 0.1, textColor: GrayColor, fontSize: 10 } },
      ],
      [
        { content: ``, colSpan: 4, styles: { halign: 'left', lineWidth: 0, fontSize: 10 } },
      ],
      [
        { content: "Permanent Resolution(永久解决方案):", colSpan: 4, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0, fontSize: 10 } },
      ],
      [
        { content: "Date Resolved(解决日期):", colSpan: 1, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0, fontSize: 10 } },
        { content: `${report.dateResolvedPermanent !== undefined ? report.dateResolvedPermanent : ""}`, colSpan: 3, styles: { halign: 'left', lineWidth: 0, textColor: GrayColor, fontSize: 10 } },
      ],
      [
        { content: "Details(详细内容):", colSpan: 4, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0, fontSize: 10 } },
      ],
      [
        { content: `${report.detailsPermanent !== undefined ? report.detailsPermanent : ""}`, colSpan: 4, styles: { halign: 'left', lineWidth: 0.1, textColor: GrayColor, fontSize: 10 } },
      ],
    ];

    doc.autoTable({
      body: rowsdata2,
      startY: y1, // Position the table
      startX: x1,
      margin: { top: 0, left: pageMargin, bottom: 0, right: pageMargin }, // Set margin to zero         
      styles: {
        font: 'NotoScansSC',
        cellPadding: 1, // Cell padding
        fontSize: 10, // Body text font size
        halign: 'left',
        valign: 'top',
        textColor: blackColorCode,
        lineColor: blackColorCode, // Body border color (black)
        overflow: 'linebreak', // Handle text overflow
      },
      columnStyles: {
        0: { cellWidth: tableWidth * 0.25 },
        1: { cellWidth: tableWidth * 0.25 },
        2: { cellWidth: tableWidth * 0.25 },
        3: { cellWidth: tableWidth * 0.25 },
      },
      theme: 'grid',
    });

    y1 = doc.autoTable.previous.finalY + Yoffset;

    const USEUteamData = [
      [
        { content: "To be filled by the US/EU team ", colSpan: 5, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0, fontSize: 12 } },
      ],
      [
        { content: "Follow up actions: ", colSpan: 3, styles: { halign: 'left', fontStyle: 'bold', fontSize: 10 } },
        { content: "By", colSpan: 1, styles: { halign: 'left', fontStyle: 'bold', fontSize: 10 } },
        { content: " ", colSpan: 1, styles: { halign: 'left', fontStyle: 'bold', fontSize: 10 } }
      ],
      [
        { content: "Check current stock situation", colSpan: 3, styles: { halign: 'left', fontStyle: 'bold', fontSize: 10 } },
        { content: " ", colSpan: 1 },
        { content: `Qty: ${report.quantity !== undefined ? report.quantity : ''}`, colSpan: 1 }
      ],
      [
        { content: "Internal training", colSpan: 3, styles: { halign: 'left', fontStyle: 'bold', fontSize: 10 } },
        { content: " ", colSpan: 1 },
        { content: `Date: ${report.trainingDate !== undefined ? report.trainingDate : ''}`, colSpan: 1, styles: { halign: 'left', fontStyle: 'bold', fontSize: 10 } }
      ],
      [
        { content: "Request new parts based on ECN", colSpan: 3, styles: { halign: 'left', fontStyle: 'bold', fontSize: 10 } },
        { content: " ", colSpan: 1 },
        { content: `Date: ${report.requestECNDate !== undefined ? report.requestECNDate : ''}`, colSpan: 1, styles: { halign: 'left', fontStyle: 'bold', fontSize: 10 } }
      ],
      [
        { content: "Notify and check on the equipment of the current customers", colSpan: 3, styles: { halign: 'left', fontStyle: 'bold', fontSize: 10 } },
        { content: " ", colSpan: 1 },
        { content: `Date: ${report.notifyEquipmetDate !== undefined ? report.notifyEquipmetDate : ''}`, colSpan: 1, styles: { halign: 'left', fontStyle: 'bold', fontSize: 10 } }
      ],
      [
        { content: "Recall", colSpan: 3, styles: { halign: 'left', fontStyle: 'bold', fontSize: 10 } },
        { content: " ", colSpan: 1 },
        { content: `Date: ${report.recallDate !== undefined ? report.recallDate : ''}`, colSpan: 1, styles: { halign: 'left', fontStyle: 'bold', fontSize: 10 } }
      ]
    ];
    //if the table has no space in the page
    if (y1 + Yoffset * 7 > doc.internal.pageSize.height) {
      doc.addPage();
      y1 = pageMargin;
      y2 = pageMargin;
    }

    doc.autoTable({
      body: USEUteamData,
      startY: y1,
      theme: 'grid',
      columnStyles: {
        0: { cellWidth: tableWidth * 0.20 },
        1: { cellWidth: tableWidth * 0.20 },
        2: { cellWidth: tableWidth * 0.20 },
        3: { cellWidth: tableWidth * 0.20 },
        4: { cellWidth: tableWidth * 0.20 },
      },
      styles: {
        font: 'NotoScansSC',
        cellPadding: 1, // Cell padding
        fontSize: 10, // Body text font size
        halign: 'left',
        valign: 'top',
        textColor: blackColorCode,
        lineColor: blackColorCode, // Body border color (black)
        overflow: 'linebreak', // Handle text overflow
      },
      margin: { top: 0, right: pageMargin, bottom: 0, left: pageMargin }
    });

    onSuccess(doc);
    //doc.save("non-conforming");  test 
  } catch (error) {
    onError(error);
  }
};
