import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import scienscopelogo from '../../images/scienscopeLogo.png';

// Function to add footer to the current page
function addFooter(doc, text, versionText) {
    const footerText = text;
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;
    const marginBottom = 10; // margin from the bottom
    doc.setFontSize(9);
    doc.text(footerText, pageWidth / 2 - doc.getTextWidth(text) / 2, pageHeight - marginBottom);
    doc.text(versionText, pageWidth - marginBottom - doc.getTextWidth(versionText), pageHeight - marginBottom);
}

    /*////////////////////////////////////////////////////////////
    //    Add text with a Underline and give it a fixed width  //
    //////////////////////////////////////////////////////////////*/
    const addTextWithUnderlines = (doc, text, x, y, maxWidth, pageMargin, contentMargin) => {

        let currentX = x;   //start x
        let currentY = y;   //start y

        const textHeight = doc.getTextDimensions(text).h;  //text height
        const pageHeight = doc.internal.pageSize.height;

        //return if text is empty
        if (!text || text === '') {
            return y + textHeight;
        }

        //split the text with a given width
        const splitText = doc.splitTextToSize(text, maxWidth - 2 * contentMargin);

        for (const line of splitText) {
            if (currentY + textHeight * 1.2 > pageHeight - pageMargin * 2) {
                doc.addPage();
                currentY = pageMargin;
                currentY += textHeight + 1;
            }
            doc.text(line, currentX, currentY);
            doc.line(currentX - contentMargin, currentY + 1, x + maxWidth - contentMargin, currentY + 1);
            currentY += textHeight + 1;
        }
        return currentY;

    };
const generateMachineHistoryLogPDF = (data,machineSN) => {

    const doc = new jsPDF('landscape');

    // page settings
    const pageMargin = 10;
    const pageWidth = doc.internal.pageSize.width;
    // const pageHeight = doc.internal.pageSize.height;
    let x1 = pageMargin;
    let y1 = pageMargin + 12;

    const tableWidth = pageWidth - 2 * pageMargin;
    const blackColorCode = [0, 0, 0];
    const whiteColorCode = [255, 255, 255];
    const greyColorCode = [200, 200, 200];
    // const yellowColorCode = [250, 240, 140];

    /*//////////////////////////////////////////////////     scienscope logo image      ////////////////////////////////////////////////////*/
    const imgData = new Image();
    imgData.src = scienscopelogo;
    const imageWidth = 50;
    const imageHeight = 13;
    doc.addImage(imgData, 'PNG', pageMargin + 10, pageMargin, imageWidth, imageHeight);
 
    const body = [];
    body.push(
        [
            { content: 'Date', colSpan: 1, styles: { halign: 'center', fillColor: greyColorCode } },
            { content: 'Job Description', colSpan: 3, styles: { halign: 'center', fillColor: greyColorCode } },
            { content: 'Part Number', colSpan: 2, styles: { halign: 'center', fillColor: greyColorCode } },
            { content: 'Note', colSpan: 5, styles: { halign: 'center', fillColor: greyColorCode } },
            { content: 'Time In', colSpan: 1, styles: { halign: 'center', fillColor: greyColorCode } },
            { content: 'Time Out', colSpan: 1, styles: { halign: 'center', fillColor: greyColorCode } },
            { content: 'Total Time', colSpan: 1, styles: { halign: 'center', fillColor: greyColorCode } },
            { content: 'Technician', colSpan: 2, styles: { halign: 'center', fillColor: greyColorCode } },
        ],
    );

    
    for (let i = 0; i < data.length; i++) {
        body.push(
            [
                { content: data[i].date, colSpan: 1, styles: { halign: 'center', fillColor: whiteColorCode } },
                { content: data[i].job, colSpan: 3, styles: { halign: 'left', fillColor: whiteColorCode } },
                { content: data[i].partnumber, colSpan: 2, styles: { halign: 'center', fillColor: whiteColorCode } },
                { content: data[i].notes, colSpan: 5, styles: { halign: 'left', fillColor: whiteColorCode } },
                { content: data[i].timein, colSpan: 1, styles: { halign: 'center', fillColor: whiteColorCode } },
                { content: data[i].timeout, colSpan: 1, styles: { halign: 'center', fillColor: whiteColorCode } },
                { content: data[i].totaltime, colSpan: 1, styles: { halign: 'center', fillColor: whiteColorCode } },
                { content: data[i].tech, colSpan: 2, styles: { halign: 'left', fillColor: whiteColorCode } },
            ],
        );

    }
    const Title = `Machine History Log (SN: ${machineSN} )`;
    const pageCenterX = (pageWidth - 2 * pageMargin) / 2;
    const title_x = pageCenterX - doc.getTextWidth(Title) / 2 + pageMargin;
    y1 = addTextWithUnderlines(doc, Title, title_x, y1, doc.getTextWidth(Title) + 2, pageMargin, 1);
    y1 += 2;

    const totalColumnWidth = 16 * (tableWidth / 16);
    x1 = (pageWidth - totalColumnWidth) / 2 - 5;
    // Generate the PDF with the autoTable
    doc.autoTable({
        body: body,
        columnStyles: {
            0: { cellWidth: tableWidth / 16 },
            1: { cellWidth: tableWidth / 16 },
            2: { cellWidth: tableWidth / 16 },
            3: { cellWidth: tableWidth / 16 },
            4: { cellWidth: tableWidth / 16 },
            5: { cellWidth: tableWidth / 16 },
            6: { cellWidth: tableWidth / 16 },
            7: { cellWidth: tableWidth / 16 },
            8: { cellWidth: tableWidth / 16 },
            9: { cellWidth: tableWidth / 16 },
            10: { cellWidth: tableWidth / 16 },
            11: { cellWidth: tableWidth / 16 },
            12: { cellWidth: tableWidth / 16 },
            13: { cellWidth: tableWidth / 16 },
            14: { cellWidth: tableWidth / 16 },
            15: { cellWidth: tableWidth / 16 },
        },
        theme: 'grid',
        margin: { top: 20 },
        startX: x1,
        startY: y1,
        styles: { fontSize: 8, textColor: blackColorCode, cellPadding: 1, lineHeight: 1.2 }, // Set global font size
        didDrawPage: () => {
            // Add footer on each page
            addFooter(doc, "Address: 5751 Schaefer Ave., Chino, CA 91765", "version 1.0");
        },
    });

    doc.save(`HistoryLog-${machineSN}`);

}

export default generateMachineHistoryLogPDF;