import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Avatar,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ErrorDialog from '../components/ErrorDialog';
import SuccessDialog from '../components/Navigation/SuccessDialog';
import {GetTextByRegion } from '../hooks/Translation';
import { useUser } from '../hooks/UserContext';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const ProfilePage = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const {selectedRegion, user:{username}, refreshUser } = useUser();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    refreshUser();
  }, [refreshUser]);

  const validate = () => {
    const newErrors = {};
    if (!currentPassword) newErrors.currentPassword = 'Current Password is required';
    if (!newPassword) newErrors.newPassword = 'New Password is required';
    if (!confirmPassword) newErrors.confirmPassword = 'Confirm Password is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCurrentPasswordChange = (e) => {
    setCurrentPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    if (!validate()){
      return;
    }
    if (newPassword !== confirmPassword) {
      setError('New password and confirm password do not match.');
      return;
    }
    e.preventDefault();

    try {
      await axios.post(
        backendString + '/users/change-password',
        {
          username,
          currentPassword,
          newPassword,
        },
        {
          headers: {
            'Authorization': sessionStorage.getItem('token')
          },
        }
      );

      setSuccess('Password updated successfully.');
      setCurrentPassword('');
      setConfirmPassword('');
      setNewPassword('');
      setError('');
    } catch (error) {
      setError(error.response?.data?.error || 'Error updating password.');
    }
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  const handleCloseSuccessDialog = () => {
    setSuccess('');
  };
  return (
    <>
      <Container component="main" maxWidth="xs">
        <Paper elevation={3} sx={{ padding: 2, marginTop: 4 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {GetTextByRegion("Profile",selectedRegion)}
            </Typography>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                disabled
                id="username"
                label={GetTextByRegion("Username",selectedRegion)}
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="currentPassword"
                label={GetTextByRegion("Current Password",selectedRegion)}
                type="password"
                id="currentPassword"
                autoComplete="current-password"
                value={currentPassword}
                onChange={handleCurrentPasswordChange}
                error={!!errors.currentPassword && !currentPassword}
                helperText={!currentPassword ? errors.currentPassword : ''}
              />
              
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label={GetTextByRegion("New Password",selectedRegion)}
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={handleNewPasswordChange}
                error={!!errors.newPassword && !newPassword}
                helperText={!newPassword ? errors.newPassword : ''}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label={GetTextByRegion("Confirm Password",selectedRegion)}
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                error={!!errors.confirmPassword && !confirmPassword}
                helperText={!confirmPassword ? errors.confirmPassword : ''}
              />
              <Button
                onClick={handleSubmit}
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
              >
                {GetTextByRegion("Update Profile",selectedRegion)}
              </Button>
            
          </Box>
        </Paper>
      </Container>
      {/* Error Dialog */}
      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
      {/* Success Dialog */}
      <SuccessDialog
        open={!!success}
        onClose={handleCloseSuccessDialog}
        successMessage={success}
      />
    </>
  );
};

export default ProfilePage;
