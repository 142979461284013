import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import { GetTextByRegion } from '../../hooks/Translation';

const ImportCSVButton = ({ importCSV , selectedRegion}) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click(); // Trigger file input click
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    importCSV(file); // Pass the file to the importCSV function
  };

  return (
    <>
      {/* Hidden file input */}
      <input
        type="file"
        accept=".csv"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileInputChange} // Handle CSV import when file is selected
        id="csv-upload"
      />
      
      {/* Combined button */}
      <Button
        color="primary"
        onClick={handleButtonClick} // Open file input dialog
        component="span"
      >
        {GetTextByRegion("Import CSV",selectedRegion)}
      </Button>
    </>
  );
};

export default ImportCSVButton;
