import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router-dom';

const redirectMessages = [
  "No token provided",
  "Unauthorized",
  "jwt expired"
];

const ErrorDialog = ({ open, onClose, errorMessage }) => {
  const navigate = useNavigate();

  const handleClose = () => {
    if (redirectMessages.includes(errorMessage)) {
      navigate('/login');
    }
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 350,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <ErrorOutlineIcon color="error" sx={{ fontSize: 50, mb: 2 }} />
        <Typography id="modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
          Error
        </Typography>
        <Typography id="modal-description" variant="body1" sx={{ mb: 3, textAlign: 'center' }}>
          {errorMessage}
        </Typography>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default ErrorDialog;
