import React, { useState, useEffect } from 'react';
import {
  TextField, Button, Select, MenuItem, FormControl, InputLabel,
  Checkbox, FormControlLabel, Grid, Typography, IconButton,
  Box, Card
} from '@mui/material';
import { Delete, Edit, ArrowUpward, ArrowDownward } from '@mui/icons-material';

const FormBuilder = () => {
  const [formTemplate, setFormTemplate] = useState([]);
  const [newField, setNewField] = useState({
    type: 'TextField', label: '', location: 'left', section: '', options: []
  });
  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState('');
  const [newSectionName, setNewSectionName] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [newOption, setNewOption] = useState(''); // For adding options to Select field
  const [templateName, setTemplateName] = useState(''); // Template name for saving
  const [templateType, setTemplateType] = useState(''); // Template name for saving
  const [savedTemplates, setSavedTemplates] = useState([]); // Store available templates

  const handleFieldChange = (e) => {
    setNewField({ ...newField, [e.target.name]: e.target.value });
  };

  const handleNewSectionNameChange = (e) => {
    setNewSectionName(e.target.value);
  };

  const addSection = () => {
    if (newSectionName && !sections.includes(newSectionName)) {
      setSections([...sections, newSectionName]);
      setNewSectionName('');
    }
  };

  const addField = () => {
    if (editIndex !== null) {
      const updatedTemplate = [...formTemplate];
      updatedTemplate[editIndex] = { ...newField, section: selectedSection }; // Ensure section is updated
      setFormTemplate(updatedTemplate);
      setEditIndex(null);
    } else {
      setFormTemplate([...formTemplate, { ...newField, section: selectedSection }]); // Ensure section is set
    }
    setNewField({ type: 'TextField', label: '', location: 'left', section: selectedSection, options: [] }); // Reset the newField object
    setNewOption('');
  };

  const editField = (index) => {
    setEditIndex(index);
    setNewField(formTemplate[index]);
  };

  const deleteField = (index) => {
    const updatedTemplate = [...formTemplate];
    updatedTemplate.splice(index, 1);
    setFormTemplate(updatedTemplate);
  };

  const moveField = (index, direction) => {
    const updatedTemplate = [...formTemplate];
    const fieldToMove = updatedTemplate.splice(index, 1)[0];
    updatedTemplate.splice(index + direction, 0, fieldToMove);
    setFormTemplate(updatedTemplate);
  };

  const addOption = () => {
    if (newOption) {
      setNewField({
        ...newField,
        options: [...newField.options, newOption]
      });
      setNewOption('');
    }
  };

  const deleteOption = (optionIndex) => {
    const updatedOptions = [...newField.options];
    updatedOptions.splice(optionIndex, 1);
    setNewField({ ...newField, options: updatedOptions });
  };

  const saveTemplate = () => {
    if (templateName) {
      localStorage.setItem(templateName, JSON.stringify({ formTemplate, sections }));
      setSavedTemplates([...savedTemplates, templateName]);
      setTemplateName(''); // Clear the input after saving
    }
  };

  const openTemplate = (templateName) => {
    const savedData = JSON.parse(localStorage.getItem(templateName));
    if (savedData) {
      setFormTemplate(savedData.formTemplate);
      setSections(savedData.sections);
      setSelectedSection(''); // Reset section selection
    }
  };

  const deleteTemplate = (templateName) => {
    localStorage.removeItem(templateName);
    setSavedTemplates(savedTemplates.filter(name => name !== templateName));
  };

  // Group fields by sections for display
  const groupedBySection = formTemplate.reduce((sections, field) => {
    const { section } = field;
    if (!sections[section]) {
      sections[section] = [];
    }
    sections[section].push(field);
    return sections;
  }, {});

  return (
    <div style={{margin: '30px'}}>
      <h3>Create Form</h3>

      {/* Section creation */}
      <TextField
        label="New Section Name"
        value={newSectionName}
        onChange={handleNewSectionNameChange}
        style={{ marginRight: '10px' }}
      />
      <Button onClick={addSection} variant="contained" color="secondary" style={{ marginBottom: '10px' }}>
        Add Section
      </Button>

      {/* Section selection */}
      {sections.length > 0 && (
        <div>
          <h4>Sections</h4>
          <div style={{ marginBottom: '20px' }}>
            <Button
              onClick={() => setSelectedSection('')}
              variant={!selectedSection ? 'contained' : 'outlined'}
              color="primary"
              style={{ marginRight: '10px' }}
            >
              No Section
            </Button>
            {sections.map((section, index) => (
              <Button
                key={index}
                onClick={() => setSelectedSection(section)}
                variant={selectedSection === section ? 'contained' : 'outlined'}
                color="primary"
                style={{ marginRight: '10px' }}
              >
                {section}
              </Button>
            ))}
          </div>
        </div>
      )}

      {/* Field creation */}
      <FormControl>
        <InputLabel>Field Type</InputLabel>
        <Select name="type" value={newField.type} onChange={handleFieldChange}>
          <MenuItem value="TextField">Text Field</MenuItem>
          <MenuItem value="Select">Select</MenuItem>
          <MenuItem value="Checkbox">Checkbox</MenuItem>
        </Select>
      </FormControl>

      <TextField
        name="label"
        label="Field Label"
        value={newField.label}
        onChange={handleFieldChange}
        style={{ marginLeft: '10px' }}
      />

      <FormControl style={{ marginLeft: '10px' }}>
        <InputLabel>Location</InputLabel>
        <Select name="location" value={newField.location} onChange={handleFieldChange}>
          <MenuItem value="left">Left</MenuItem>
          <MenuItem value="right">Right</MenuItem>
        </Select>
      </FormControl>

      {/* Conditional UI for adding options to Select field */}
      {newField.type === 'Select' && (
        <div>
          <TextField
            label="New Option"
            value={newOption}
            onChange={(e) => setNewOption(e.target.value)}
            style={{ marginLeft: '10px' }}
          />
          <Button onClick={addOption} variant="contained" color="primary" style={{ marginLeft: '10px' }}>
            Add Option
          </Button>
          <div>
            {newField.options.map((option, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography>{option}</Typography>
                <IconButton onClick={() => deleteOption(index)}>
                  <Delete color="secondary" />
                </IconButton>
              </div>
            ))}
          </div>
        </div>
      )}

      <Button onClick={addField} variant="contained" color="primary" style={{ marginLeft: '10px' }}>
        {editIndex !== null ? 'Update Field' : 'Add Field to ' + (selectedSection || 'No Section')}
      </Button>

      {/* Save Template */}
      <div style={{ marginTop: '20px' }}>
        <TextField
          label="Template Name"
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
          style={{ marginRight: '10px' }}
        />

        <FormControl style={{ marginRight: '10px' }}>
        <InputLabel>Type</InputLabel>
        <Select name="templateType" value={templateType} onChange={(e) => setTemplateType(e.target.value)}>
          <MenuItem value="QA/QC">QA/QC</MenuItem>
          <MenuItem value="Incoming Checklist">Incoming Checklist</MenuItem>
        </Select>
      </FormControl>

        <Button onClick={saveTemplate} variant="contained" color="primary">
          Save Template
        </Button>
      </div>

      {/* Open or Delete Template */}
      {savedTemplates.length > 0 && (
        <div style={{ marginTop: '20px' }}>
          <h4>Saved Templates</h4>
          {savedTemplates.map((template, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <Button onClick={() => openTemplate(template)} variant="contained" color="primary" style={{ marginRight: '10px' }}>
                {template}
              </Button>
              <IconButton onClick={() => deleteTemplate(template)}>
                <Delete color="secondary" />
              </IconButton>
            </div>
          ))}
        </div>
      )}

      {/* Display created form */}
      <div style={{ marginTop: '30px' }}>
        <h4>Form Preview</h4>
        {Object.keys(groupedBySection).map((section, sectionIndex) => (
          <div key={sectionIndex}>
            {section && <Typography variant="h6">{section}</Typography>}
            <Grid container spacing={2}>
              {groupedBySection[section].map((field, index) => (
                <Grid item xs={6} key={index}>
                  <Card>
                    <div style={{ padding: '10px' }}>
                      <Typography>{field.label}</Typography>
                      {field.type === 'TextField' && (
                        <TextField label={field.label} fullWidth />
                      )}
                      {field.type === 'Select' && (
                        <FormControl fullWidth>
                          <InputLabel>{field.label}</InputLabel>
                          <Select>
                            {field.options.map((option, i) => (
                              <MenuItem key={i} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                      {field.type === 'Checkbox' && (
                        <FormControlLabel control={<Checkbox />} label={field.label} />
                      )}
                      <IconButton onClick={() => deleteField(index)}>
                        <Delete color="secondary" />
                      </IconButton>
                      <IconButton onClick={() => editField(index)}>
                        <Edit color="primary" />
                      </IconButton>
                      {index > 0 && (
                        <IconButton onClick={() => moveField(index, -1)}>
                          <ArrowUpward color="primary" />
                        </IconButton>
                      )}
                      {index < formTemplate.length - 1 && (
                        <IconButton onClick={() => moveField(index, 1)}>
                          <ArrowDownward color="primary" />
                        </IconButton>
                      )}
                    </div>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FormBuilder;