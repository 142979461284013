import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';

const USStatusDropdown = ({ formData, handleChange, validationErrors, disabled=false }) => {
  return (
    <FormControl
      variant="outlined"
      fullWidth
      required
      disabled={disabled}
      error={!!validationErrors.status && !formData.status}
    >
      <InputLabel>Status</InputLabel>
      <Select
        label="Status"
        name="status"
        value={formData}
        onChange={handleChange}
      >
        <MenuItem value="On Order">On Order</MenuItem>
        <MenuItem value="In Crate">In Crate</MenuItem>
        <MenuItem value="On Floor">On Floor</MenuItem>
        <MenuItem value="On Floor: Assembling">On Floor: Assembling</MenuItem>
        <MenuItem value="On Floor: Assembled">On Floor: Assembled</MenuItem>
        <MenuItem value="On Floor: Testing">On Floor: Testing</MenuItem>
        <MenuItem value="On Floor: Software Testing">On Floor: Software Testing</MenuItem>
        <MenuItem value="On Floor: 1st Round">On Floor: 1st Round</MenuItem>
        <MenuItem value="On Floor: 2nd Round">On Floor: 2nd Round</MenuItem>
        <MenuItem value="On Floor: Scienscope Demo">On Floor: Scienscope Demo</MenuItem>
        <MenuItem value="Broken">Broken</MenuItem>
        <MenuItem value="Packing: No Documentation">Packing: No Documentation</MenuItem>
        <MenuItem value="Packing: Documentation Completed">Packing: Documentation Completed</MenuItem>
        <MenuItem value="Packed">Packed</MenuItem>
        <MenuItem value="Shipped">Shipped</MenuItem>
        <MenuItem value="Installed">Installed</MenuItem>
        <MenuItem value="Completed">Completed</MenuItem>
        <MenuItem value="Disposed">Disposed</MenuItem>
      </Select>
      {validationErrors.status && !formData.status && (
        <FormHelperText>{validationErrors.status}</FormHelperText>
      )}
    </FormControl>
  );
};

export default USStatusDropdown;
