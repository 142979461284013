import { IconButton, Tooltip } from '@mui/material';
import InventoryIcon from '@mui/icons-material/Inventory';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';
const IconDetailButton = ({ onClickEvent, params }) => {
    const { selectedRegion } = useUser();
    return (
        <Tooltip title={GetTextByRegion("Details",selectedRegion)} arrow>
             <IconButton aria-label="edit" color="default" onClick={() => onClickEvent(params)}>
              <InventoryIcon />
            </IconButton>
        </Tooltip>
    );
}
export default IconDetailButton;