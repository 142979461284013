import React from 'react';
import { Link } from 'react-router-dom';
import { MenuItem, ListItemText } from '@mui/material';
import { GetTextByRegion } from '../../hooks/Translation';
const ProfileMenu = ({ onClick, onLogout, isLoggedIn,selectedRegion }) => {

  return(
  <div>
    <MenuItem
      component={Link}
      onClick={onClick}
      to="/profile"
      sx={{
        textDecoration: 'none',
        color: 'inherit',
        padding: '14px 32px',
        '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)' },
      }}
    >
      <ListItemText >{GetTextByRegion("Profile",selectedRegion)} </ListItemText>
    </MenuItem>
    {isLoggedIn ? (
      <MenuItem
        onClick={onLogout}
        sx={{
          textDecoration: 'none',
          color: 'inherit',
          padding: '14px 32px',
          '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)' },
        }}
      >
        <ListItemText>{GetTextByRegion("Logout",selectedRegion)}</ListItemText>
      </MenuItem>
    ) : (
      <MenuItem
        component={Link}
        to="/login"
        sx={{
          textDecoration: 'none',
          color: 'inherit',
          padding: '14px 32px',
          '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)' },
        }}
      >
        <ListItemText primary="Login" />
      </MenuItem>
    )}
  </div>
  );
};

export default ProfileMenu;
