import { IconButton, Tooltip } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';
const IconDownloadPDFButton = ({ onClickEvent, params }) => {
    const { selectedRegion } = useUser();
    return (
        <Tooltip title={GetTextByRegion("Download PDF", selectedRegion)}>
            <IconButton
                color="success"
                onClick={() => onClickEvent(params)}
                aria-label="generate-pdf"
            >
                <FileDownloadIcon />
            </IconButton>
        </Tooltip>
    );
}
export default IconDownloadPDFButton;