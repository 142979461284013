import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box } from '@mui/material';
import ErrorDialog from '../ErrorDialog';
import Resizer from 'react-image-file-resizer';
import { styled } from '@mui/material/styles';
import placeholderImage from "../../images/placeholder-image.jpg";
import { GetTextByRegion, GetCurrencySymbol } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';
import roles from '../../hooks/Roles';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const Input = styled('input')({
  display: 'none',
});

const EditItemForm = ({ open, onClose, item, onChange, onSubmit }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const { selectedRegion, user: { userRights } } = useUser();

  useEffect(() => {
    if (item && item.image) {
      if (typeof item.image === 'string') {
        setImagePreview(backendString + `/items/images/${item.image}?selectedRegion=${selectedRegion}`);
      } else {
        setImagePreview(URL.createObjectURL(item.image));
      }
    } else {
      setImagePreview(null);
    }
  }, [item, selectedRegion]);

  const handleInputChange = (e) => {
    const { name, files } = e.target;

    if (name === 'image' && files && files.length > 0) {
      const file = files[0];
      Resizer.imageFileResizer(
        file,
        300,
        300,
        'JPEG',
        100,
        0,
        (uri) => {
          onChange({ target: { name: 'image', value: uri } });
          setImagePreview(URL.createObjectURL(file));
        },
        'blob'
      );
    } else {
      onChange(e);
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!item.partnumber) errors.partnumber = GetTextByRegion('Part Number is required', selectedRegion);
    if (!item.location) errors.location = GetTextByRegion('Location is required', selectedRegion);
    if (!('quantity' in item) || item.quantity === "" || item.quantity < 0) errors.quantity = GetTextByRegion('Invalid Quantity value', selectedRegion);
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleClose = () => {
    setValidationErrors({});
    onClose();
    setImagePreview(item?.image || null);
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }
    onSubmit();
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{GetTextByRegion("Edit Item", selectedRegion)}</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} width={300} mt={1}>
          <label htmlFor="upload-button-file">
            <Input
              accept="image/jpeg, image/png"
              id="upload-button-file"
              type="file"
              name="image"
              onChange={handleInputChange}
              disabled={(userRights < roles['MODERATOR'])}
            />
            <Button
              variant="contained"
              color="primary"
              component="span"
              disabled={(userRights < roles['MODERATOR'])}
            >
              {GetTextByRegion("Choose Image", selectedRegion)}
            </Button>
          </label>
          {imagePreview ? (
            <img
              src={imagePreview}
              alt="Preview"
              style={{ marginTop: '16px', maxHeight: '200px', objectFit: 'contain' }}
            />
          ) : (
            <img
              src={placeholderImage}
              alt="Default"
              style={{ marginTop: '16px', maxHeight: '200px', objectFit: 'contain' }}
            />
          )}
          <TextField
            label={GetTextByRegion("Part Number", selectedRegion)}
            name="partnumber"
            value={item?.partnumber || ''}
            onChange={onChange}
            required
            variant="outlined"
            error={!!validationErrors.partnumber && !item.partnumber}
            helperText={item?.partnumber ? '' : validationErrors.partnumber}
            disabled={(userRights < roles['MODERATOR'])}
          />
          <TextField
            label={GetTextByRegion("Name", selectedRegion)}
            name="name"
            value={item?.name || ''}
            onChange={onChange}
            variant="outlined"
            disabled={(userRights < roles['MODERATOR'])}
          />
          <TextField
            label={GetTextByRegion("Location", selectedRegion)}
            name="location"
            value={item?.location || ''}
            onChange={onChange}
            required
            variant="outlined"
            error={!!validationErrors.location && !item.location}
            helperText={item?.location ? '' : validationErrors.location}
            disabled={(userRights < roles['MODERATOR'])}
          />
          <TextField
            label={GetTextByRegion("Quantity", selectedRegion)}
            name="quantity"
            value={item?.quantity || ''}
            onChange={onChange}
            required
            variant="outlined"
            type="number"
            error={!!validationErrors.quantity && item.quantity < 0}
            helperText={item?.quantity >= 0 ? '' : validationErrors.quantity}
            onKeyPress={(e) => {
              const allowedKeys = /^[0-9]/;
              if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                e.preventDefault();
              }
            }}
            disabled={(userRights < roles['MODERATOR'])}
          />
          <TextField
            label={GetTextByRegion("Minimum Quantity", selectedRegion)}
            name="minQuantity"
            value={item?.minQuantity || ''}
            onChange={onChange}
            variant="outlined"
            type="number"
            onKeyPress={(e) => {
              const allowedKeys = /^[0-9]/;
              if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                e.preventDefault();
              }
            }}
            disabled={(userRights < roles['MODERATOR'])}
          />
          <TextField
            label={GetTextByRegion("Price", selectedRegion)}
            name="price"
            value={item.price ? GetCurrencySymbol(selectedRegion) + `${item.price}` : ''}
            onChange={onChange}
            variant="outlined"
            onKeyPress={(e) => {
              let allowedKeys;
              if (selectedRegion === "TAIWAN" || selectedRegion === "CHINA") {
                allowedKeys = /^[0-9]$/; 
              } else {
                allowedKeys = /^[0-9.]$/; 
              }
              if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                e.preventDefault();
              }
            }}
          />
          <TextField
            label={GetTextByRegion("Notes", selectedRegion)}
            name="notes"
            value={item?.notes || ''}
            onChange={onChange}
            variant="outlined"
            multiline
            rows={2}
            disabled={(userRights < roles['MODERATOR'])}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{GetTextByRegion("Cancel", selectedRegion)}</Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={!item.partnumber || item.partnumber === "" || item.location === "" || !item.quantity || item.quantity < 0 || item.quantity === ""}
        >{GetTextByRegion("Save", selectedRegion)}</Button>
      </DialogActions>

      {/* Error Dialog */}
      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </Dialog>
  );
};

export default EditItemForm;
