import React, { useState, useEffect } from 'react';
import { TextField, Button, Dialog, Grid, DialogTitle, MenuItem, DialogContent } from '@mui/material';
import axios from 'axios';
import ErrorDialog from '../ErrorDialog';
import ModelDropdown from '../ModelDropdown';
import { useUser } from '../../hooks/UserContext';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const RemoteServiceForm = ({ open, type, onClose, service, onFormSubmit}) => {
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const { user:{username}, selectedRegion } = useUser();
  const [formData, setFormData] = useState({
    startTime: '',
    endTime: '',
    issueType: '',
    technicians: username,
    customer: '',
    notes: '',
    model: '',
    submitted: false,
  });

  useEffect(() => {
    if (service) {
      // Populate form with selected service data
      setFormData({
        startTime: service.startTime || '',
        endTime: service.endTime || '',
        issueType: service.issueType || '',
        technicians: service.technicians || '',
        notes: service.notes || '',
        customer: service.customer || '',
        model: service.model || '',
      });
    }
  }, [service]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.issueType) errors.issueType = 'Issue type is required';
    if (!formData.model) errors.model = 'Model is required';
    if (!formData.technicians) errors.technicians = 'Technicians are required';
    if (!formData.customer) errors.customer = 'Customer is required';
  
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleClose = () => {
    setValidationErrors({});
    setFormData({
      startTime: '',
      endTime: '',
      issueType: '',
      technicians: username,
      customer: '',
      notes: '',
      model: '',
      submitted: false,
    });
    onClose();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValidationError = validateForm();
    if (!isValidationError) {
      return;
    }

    try {
      const submittedData = { ...formData, submitted: true };
      
      if(type === "new"){
        await axios.post(backendString + `/remoteservices` , submittedData, {
          headers: {
            'Authorization': sessionStorage.getItem('token')
          },
        });
      }
      else{
        await axios.put(backendString + `/remoteservices/${service._id}` , submittedData, {
          headers: {
            'Authorization': sessionStorage.getItem('token')
          },
        });
      }
      onFormSubmit();
      onClose(); // Close the dialog after successful submission
      setFormData({
        startTime: '',
        endTime: '',
        issueType: '',
        technicians: username,
        customer: '',
        notes: '',
        model: '',
        submitted: false,
      });
    } catch (error) {
      console.error('There was an error submitting the service!', error);
      setError(error.response?.data?.error);
    }
};

  const handleSave = async () => {
    try {
      if (type === "new"){
        await axios.post(`${backendString}/remoteservices`, formData, {
          headers: {
            'Authorization': sessionStorage.getItem('token')
          },
        });
      }
      else{
        await axios.put(`${backendString}/remoteservices/${service._id}`, formData, {
          headers: {
            'Authorization': sessionStorage.getItem('token')
          },
        });
      }
      onClose(); // Close the dialog after saving as draft
      setFormData({
        startTime: '',
        endTime: '',
        issueType: '',
        technicians: username,
        customer: '',
        notes: '',
        model: '',
        submitted: false,
      });
    } catch (error) {
      console.error('There was an error saving the draft!', error);
      setError(error.response?.data?.error);
    }
  };

  return (
    <>
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>{service ? 'Edit Remote Service' : 'Add Remote Service'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Start Time"
              name="startTime"
              type="datetime-local"
              value={formData.startTime}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="End Time"
              name="endTime"
              type="datetime-local"
              value={formData.endTime}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Issue Type"
              name="issueType"
              select
              required
              value={formData.issueType}
              onChange={handleChange}
              fullWidth
              error={!!validationErrors.issueType && !formData.issueType}
              helperText={formData.issueType ? '' : validationErrors.issueType}
            >
              <MenuItem value="Software: Bug">Software: Bug</MenuItem>
              <MenuItem value="Software: Integration">Software: Integration</MenuItem>
              <MenuItem value="Software: Update">Software: Update</MenuItem>
              <MenuItem value="Software: Printer">Software: Printer</MenuItem>
              <MenuItem value="Software: Calibration">Software: Calibration</MenuItem>
              <MenuItem value="Software: Other">Software: Other</MenuItem>
              <MenuItem value="Hardware: FPD">Hardware: FPD</MenuItem>
              <MenuItem value="Hardware: Power Supply">Hardware: Power Supply</MenuItem>
              <MenuItem value="Hardware: PC">Hardware: PC</MenuItem>
              <MenuItem value="Hardware: X-ray Tube">Hardware: X-ray Tube</MenuItem>
              <MenuItem value="Hardware: Printer">Hardware: Printer</MenuItem>
              <MenuItem value="Hardware: Calibration">Hardware: Calibration</MenuItem>
              <MenuItem value="Hardware: Damage">Hardware: Damage</MenuItem>
              <MenuItem value="Hardware: Other">Hardware: Other</MenuItem>
              
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Technicians"
              name="technicians"
              value={formData.technicians}
              onChange={handleChange}
              fullWidth
              required
              disabled
              error={!!validationErrors.technicians && !formData.technicians}
              helperText={formData.technicians ? '' : validationErrors.technicians}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Customer"
              name="customer"
              value={formData.customer}
              onChange={handleChange}
              fullWidth
              required
              error={!!validationErrors.customer && !formData.customer}
              helperText={formData.customer ? '' : validationErrors.customer}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ModelDropdown
              value={formData.model}
              name="model"
              onChange={handleChange}
              required
              selectedRegion={selectedRegion}
              errors={!!validationErrors.model && !formData.model}
              helperText={formData.model ? '' : validationErrors.model}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Notes"
              name="notes"
              multiline
              rows={4}
              value={formData.notes}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button onClick={handleClose} color="secondary">
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button color="primary" onClick={handleSave}>
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                  Submit
                </Button>
                
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>

    <ErrorDialog
      open={!!error}
      onClose={handleCloseErrorDialog}
      errorMessage={error}
   />
    </>
  );
};

export default RemoteServiceForm;