import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Assuming CheckCircleIcon is the correct icon
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';

const IconAcceptButton = ({ onClickEvent}) => {
    const { selectedRegion } = useUser();

    return (
        <Tooltip title={GetTextByRegion("Accept", selectedRegion)} arrow>
            <IconButton color="success" onClick={onClickEvent}>
                <CheckCircleIcon />
            </IconButton>
        </Tooltip>
    );
}

export default IconAcceptButton;
