import { IconButton, Tooltip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';
const IconCompleteButton = ({ onClickEvent, params }) => {
    const { selectedRegion } = useUser();
    return (
        <Tooltip title={GetTextByRegion("Complete", selectedRegion)} arrow>
            <IconButton color="success" onClick={() => onClickEvent(params)}>
                <CheckIcon />
            </IconButton>
        </Tooltip>
    );
}
export default IconCompleteButton;