import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const SuccessDialog = ({ open, onClose, successMessage }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 350,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <CheckCircleOutlineIcon color="success" sx={{ fontSize: 50, mb: 2 }} />
        <Typography id="modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
          Success
        </Typography>
        <Typography id="modal-description" variant="body1" sx={{ mb: 3, textAlign: 'center' }}>
          {successMessage}
        </Typography>
        <Button variant="contained" color="primary" onClick={onClose}>
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default SuccessDialog;
