import React from 'react';
import MyCalendar from '../components/Calendar/MyCalendar'; // Import your Calendar component
import { useUser , useValidateRegions} from '../hooks/UserContext';

const Calendar = () => {
  
  const validateSelectedRegion = ["USA"];
  const { selectedRegion } = useUser();
  useValidateRegions(selectedRegion,validateSelectedRegion);

  return (
    <div>
    <MyCalendar/>
  </div>
  );
};

export default Calendar;
