import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, Box, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import ErrorDialog from '../ErrorDialog';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const CustomerForm = ({ onAdd, onClose }) => {
  const [formData, setFormData] = useState({
    OrganizationName: '',
    Background: '',
    BillingAddressStreet: '',
    BillingAddressCity: '',
    BillingAddressState: '',
    BillingAddressPostalCode: '',
    BillingAddressCountry: '',
    ShippingAddressStreet: '',
    ShippingAddressCity: '',
    ShippingAddressState: '',
    ShippingAddressCountry: '',
    ShippingAddressPostalCode: '',
    Phone: '',
    Fax: '',
    Website: '',
    EmailDomain: '',
    Tag1: '',
    Tag2: '',
    Tag3: '',
    Tag4: '',
    Tag5: '',
    DateOfLastActivity: '',
    DateOfNextActivity: '',
    OrganisationOwner: '',
    OrganizationType: '',
    Rep: '',
  });
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.OrganizationName) errors.OrganizationName = 'Organization Name is required';
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const addCustomer = async () => {
    if (!validateForm()){
      return;
    }
    try {
      const response = await axios.post(`${backendString}/customers`, formData, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });
      onAdd(response.data);
      onClose();
    } catch (error) {
      console.error(error);
      setError(error.response?.data?.error || 'Failed to add customer');
    }
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  return (
    <div>
      <Dialog open={true} onClose={onClose}>
        <DialogTitle>Add Customer</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2} width={300} mt={1}>
            <TextField
              label="Organization Name"
              name="OrganizationName"
              value={formData.OrganizationName}
              onChange={handleInputChange}
              required
              variant="outlined"
              error={!!validationErrors.OrganizationName && !formData.OrganizationName}
              helperText={formData.OrganizationName ? '' : validationErrors.OrganizationName}
            />
            <TextField
              label="Background"
              name="Background"
              value={formData.Background}
              onChange={handleInputChange}
              variant="outlined"
            />
            <TextField
              label="Billing Street"
              name="BillingAddressStreet"
              value={formData.BillingAddressStreet}
              onChange={handleInputChange}
              variant="outlined"
            />
            <TextField
              label="Billing City"
              name="BillingAddressCity"
              value={formData.BillingAddressCity}
              onChange={handleInputChange}
              variant="outlined"
            />
            <TextField
              label="Billing State"
              name="BillingAddressState"
              value={formData.BillingAddressState}
              onChange={handleInputChange}
              variant="outlined"
            />
            <TextField
              label="Billing Postal Code"
              name="BillingAddressPostalCode"
              value={formData.BillingAddressPostalCode}
              onChange={handleInputChange}
              variant="outlined"
            />
            <TextField
              label="Billing Country"
              name="BillingAddressCountry"
              value={formData.BillingAddressCountry}
              onChange={handleInputChange}
              variant="outlined"
            />
            <TextField
              label="Shipping Street"
              name="ShippingAddressStreet"
              value={formData.ShippingAddressStreet}
              onChange={handleInputChange}
              variant="outlined"
            />
            <TextField
              label="Shipping City"
              name="ShippingAddressCity"
              value={formData.ShippingAddressCity}
              onChange={handleInputChange}
              variant="outlined"
            />
            <TextField
              label="Shipping State"
              name="ShippingAddressState"
              value={formData.ShippingAddressState}
              onChange={handleInputChange}
              variant="outlined"
            />
            <TextField
              label="Shipping Country"
              name="ShippingAddressCountry"
              value={formData.ShippingAddressCountry}
              onChange={handleInputChange}
              variant="outlined"
            />
            <TextField
              label="Shipping Postal Code"
              name="ShippingAddressPostalCode"
              value={formData.ShippingAddressPostalCode}
              onChange={handleInputChange}
              variant="outlined"
            />
            <TextField
              label="Phone"
              name="Phone"
              value={formData.Phone}
              onChange={handleInputChange}
              variant="outlined"
            />
            <TextField
              label="Fax"
              name="Fax"
              value={formData.Fax}
              onChange={handleInputChange}
              variant="outlined"
            />
            <TextField
              label="Website"
              name="Website"
              value={formData.Website}
              onChange={handleInputChange}
              variant="outlined"
            />
            <TextField
              label="Email Domain"
              name="EmailDomain"
              value={formData.EmailDomain}
              onChange={handleInputChange}
              variant="outlined"
            />
            <TextField
              label="Tag 1"
              name="Tag1"
              value={formData.Tag1}
              onChange={handleInputChange}
              variant="outlined"
            />
            <TextField
              label="Tag 2"
              name="Tag2"
              value={formData.Tag2}
              onChange={handleInputChange}
              variant="outlined"
            />
            <TextField
              label="Tag 3"
              name="Tag3"
              value={formData.Tag3}
              onChange={handleInputChange}
              variant="outlined"
            />
            <TextField
              label="Tag 4"
              name="Tag4"
              value={formData.Tag4}
              onChange={handleInputChange}
              variant="outlined"
            />
            <TextField
              label="Tag 5"
              name="Tag5"
              value={formData.Tag5}
              onChange={handleInputChange}
              variant="outlined"
            />
            <TextField
              label="Date of Last Activity"
              name="DateOfLastActivity"
              type="date"
              value={formData.DateOfLastActivity}
              onChange={handleInputChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Date of Next Activity"
              name="DateOfNextActivity"
              type="date"
              value={formData.DateOfNextActivity}
              onChange={handleInputChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Organisation Owner"
              name="OrganisationOwner"
              value={formData.OrganisationOwner}
              onChange={handleInputChange}
              variant="outlined"
            />
            <TextField
              label="Organization Type"
              name="OrganizationType"
              value={formData.OrganizationType}
              onChange={handleInputChange}
              variant="outlined"
            />
            <TextField
              label="Rep"
              name="Rep"
              value={formData.Rep}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={addCustomer} variant="contained" color="primary">
            Add Customer
          </Button>
        </DialogActions>
      </Dialog>

      {/* Render the error dialog */}
      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </div>
  );
};

export default CustomerForm;
