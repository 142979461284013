import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, TextField , Autocomplete,FormHelperText} from '@mui/material';

const AddServiceView = ({ open, onClose, setServices }) => {

    const [serviceSelectedOption, setServiceSelectedOption] = useState('');
    const [errorMessage,setErrorMesage]=useState("");

    const [service, setService] = useState({
        itemNumber: "",
        quantity: "",
        description: "",
        cost: "",
        total: "",
    }, []);
    const ServicePredefinedOptions = ['PM1', 'PM2', 'PM3'];

    const addService = () => {
        if (!service.itemNumber || service.quantity <= 0 || service.cost < 0) {
            return;
        }
        const total = service.cost * service.quantity;
        const newService = { ...service, total };
        setServices((prev) => [...prev, newService]);

        setService({
            itemNumber: "",
            quantity: "",
            description: "",
            cost: "",
            total: "",
        });
        onClose();
    };

    const handleChange = (field) => (event) => {
        setErrorMesage("");
        setService((prevService) => ({
            ...prevService,
            [field]: event.target.value,
        }));
    };
    const handleOptionChange = (event, newValue) => {
 
        setService({ ...service, itemNumber: event.target.value });
        setServiceSelectedOption(newValue);
        
    };

    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Add New Service</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        freeSolo
                        options={ServicePredefinedOptions}
                        value={serviceSelectedOption}
                        onChange={handleOptionChange}
                        onBlur={handleChange('itemNumber')}
                        renderInput={(params) => (
                            <TextField {...params} label="Item Number" variant="standard" margin="normal" />
                        )}
                    />
                    <TextField
                        label="Quantity"
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="number"
                        value={service.quantity}
                        onChange={handleChange('quantity')}
                        onKeyPress={(e) => {
                            const allowedKeys = /^[0-9]+$/;
                            if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                e.preventDefault();
                                setErrorMesage("Error: Invalid Input!");
                            }
                        }}
                    />
                    <TextField
                        label="Service Description"
                        fullWidth
                        variant="standard"
                        margin="normal"
                        value={service.description}
                        onChange={handleChange('description')}
                    />
                    <TextField
                        label="Cost"
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="normal"
                        value={service.cost ? `$${service.cost}` : ''}
                        onChange={(e) => {setService({ ...service, cost: e.target.value.replace('$', '') }); setErrorMesage("")}}
                        onKeyPress={(e) => {
                            const allowedKeys = /^[0-9.]+$/;
                            if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                e.preventDefault();
                                setErrorMesage("Error: Invalid Input!");
                            }
                        }}
                    />
                     {errorMessage && <FormHelperText error><h4>Please check your input and try again!<br/>{errorMessage}</h4></FormHelperText>}
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                        <Button
                            onClick={addService}
                            color="primary"  
                            variant="contained" 
                            disabled={!service.itemNumber || service.quantity <= 0 || service.cost < 0}
                        >
                            Add
                        </Button>
                        <Button onClick={onClose} color="primary" >
                            Close
                        </Button>
                    </div>
                   
                </DialogContent>
            </Dialog>

        </>
    );
};

export default AddServiceView;